/*
File Name: StudentListing.js
Author: Dhiraj Rajput
Modified On: 16/1/2024
Description: Student Details
API's used:
1) /get-student-list
*/
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import constants from '../../Constants/constants';
import { Spin, Card, Space, Input, Radio, Button, Select, Table, Modal, InputNumber, Form, Flex, Tooltip, Row, Col, Tag, Checkbox } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { fetchApi, fetchStaffApi } from '../../Services/Api';
import { CloseCircleOutlined } from '@ant-design/icons';
import { performSearch, HighlightedText, CustomSortIcon, getNextAndPreviousButtons,showTotalItems, handleTableChange, handleRowSelect } from '../../GlobalFunctions/GlobalFunctions';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import ProbationHistory from './ProbationHistory';
import { COOKIE, createCookie, deleteCookie, getCookie } from '../../Services/Cookie';
import Print from '../Component/PrintComponent/Print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;

const sorterTooltipStyle = {
	placement: 'top',
	align: { offset: [10, 5] },
}

const getTypeAbbr = (type) => {
	switch(type){
		case 'Full Time':
			return 'FT';
		case 'Part Time':
			return 'PT';
		case 'Undeclared':
			return 'UD';
		case 'Prerequisites':
			return 'PR';
		default:
			return ''
	}
}

const getYearInPrgAbbr = (year) => {
	switch(year) {
		case 'Freshman':
			return 'Fr';
		case 'Sophomore':
			return 'So';
		case 'Junior':
			return 'Jr';
		case 'Senior':
			return 'Sr';
		case 'Senior Year':
			return 'Sr';
		case 'Graduate':
			return 'Grad';
		case 'Part Time':
			return 'PT';
		default:
			return ''
	}
}

const filterOption = (input, option) => {
	return option.children.toLowerCase().includes(input.toLowerCase())
}

const filterSort = (optionA, optionB) => {
	return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
}

const StudentListing = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [tableData, setTableData] = useState([]);
	const [statusList, setStatusList] = useState(constants.StatusList);
	const [reload, setReload] = useState(false);
	const [listingPayload, setListingPayload] = useState({
		year_in_program: null,
		student_type: null,
		status: null,
		online: '0',
		semester: "0",
		order_last_name: "0",
		show_all: "0",
		is_active: "1",
		c_notes: "0"
	});
	const [sortName, setSortName] = useState(constants.FirstName);
	const [filterTableData, setFilterTableData] = useState([]);
	const [showTab, setShowTab] = useState(false);
	const [hasTwoSems, setHasTwoSems] = useState(false);
	const [semesterIds, setSemesterIds] = useState([]);
	const [tabKey, setTabKey] = useState("0");
	const [currentSemester, setCurrentSemester] = useState(null);
	const [futureSemester, setFutureSemester] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [gpaFilterValue, setGpaFilterValue] = useState("");
	const [showProbationModal, setShowProbationModal] = useState(false)
	const [probationBtn, setProbationBtn] = useState(false);
	const [loading, setLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [sortedTableData, setSortedTableData] = useState([])
	const [tableSorters, setTableSorters] = useState([])
	const [dynamicHeight, setDynamicHeight] = useState(380)
	const [hideViewArchivedButton,setHideViewArchivedButton]=useState(true);
	const facultyRoleId = getCookie(COOKIE.Role);
	const userId = getCookie(COOKIE.UserId);
	const isAdminOrSysAdmin=(facultyRoleId === "1" || facultyRoleId === "3") ? true : false;

	const getSorterName = (a, b) => {
		let sortOrder = sortName === constants.FirstName ? 'student_first_name' : 'student_last_name'
		return a?.[sortOrder]?.localeCompare(b?.[sortOrder])
	}
	
	const columns = [
		// {
		// 	title: <b>{constants.Photo}</b>,
		// 	columnName: constants.Photo,
		// 	dataIndex: 'student_image',
		// 	key: 'student_image',
		// 	width:'5px',
		// 	render:(student_image)=> student_image !== null ? <Flex align='center' justify='center'> <Avatar size={32} src={<img src={student_image} alt="avatar" />} /></Flex> : <Flex align='center' justify='center'> <Avatar icon={<UserOutlined />} size={32}/></Flex>
		// },
		{
			title: <b>{constants.Name}</b>,
			columnName: constants.Name,
			dataIndex: 'name',
			key: 'student',
			width:'9px',
			// fixed: 'left',
			sorter: (a, b) => getSorterName(a, b),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
			render:(_, record) => {
				return <Space>
					<HighlightedText text={record?.name} highlight={searchValue} />
					{record?.student_is_mentor === "1" ?
						<span className='relative'>
							<FontAwesomeIcon className="text-primary-pale text-xl" icon={faCertificate} />
							<span className='absolute text-white text-xs left-1 mt-0.5'>M</span>
						</span>
						: null
					}
				</Space>
			}
		},
		{
			title: <div style={{ textAlign: 'left' }}><b>{constants.Id}</b></div>,
			columnName: constants.Id,
			dataIndex: 'student_id_number',
			key: 'id',
			width:'5px',
			align: 'right',
			sorter: (a, b) => a?.id?.localeCompare(b?.id),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
			render:(id)=>id?<HighlightedText text={id} highlight={searchValue} /> : '-'
		},
		{
			title: <b>{constants.YrInPrg}</b>,
			columnName: constants.YrInPrg,
			dataIndex: 'student_year_in_program_text_abbr',
			key: 'student_year_in_program_text_abbr',
			width:'4px',
			sorter: (a, b) => a?.student_year_in_program_text_abbr?.localeCompare(b?.student_year_in_program_text_abbr),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
			render:(_, record) => (
				<Tooltip title={record?.year_in_program_text} placement='top'>
					<span>
						<HighlightedText text={record?.student_year_in_program_text_abbr} highlight={searchValue} />
					</span>
				</Tooltip>
			)
		},
		{
			title: <div style={{ textAlign: 'left' }}><b>{constants.Gpa}</b></div>,
			columnName: constants.Gpa,
			dataIndex: 'student_gpa',
			key: 'student_gpa',
			width:'4px',
			align: 'right',
			sorter: (a, b) => a?.student_gpa?.localeCompare(b?.student_gpa),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
			render:(_, record) => <HighlightedText text={record?.student_gpa ? record?.student_gpa : '-'} highlight={searchValue} />
		},
		{
			title: <b>{constants.Type}</b>,
			columnName: constants.Type,
			dataIndex: 'student_type_text',
			key: 'student_type_text',
			width:'4px',
			sorter: (a, b) => a?.student_type_text?.localeCompare(b?.student_type_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(_, record)=> {
				return(
					<Tooltip placement='top' title={record?.student_type_text}>
							<HighlightedText text={record?.student_type_text_abbr} highlight={searchValue} />
					</Tooltip>
				)
			}
		},
		{
			title: <b>{constants.Status}</b>,
			columnName: constants.Status,
			dataIndex: 'current_status_text',
			key: 'current_status_text',
			width:'5px',
			sorter: (a, b) => a?.current_status_text?.localeCompare(b?.current_status_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(_, record)=><HighlightedText text={record?.current_status_text} highlight={searchValue} />
		},
		{
			title: <b>{constants.MuEmail}</b>,
			columnName: constants.MuEmail,
			dataIndex: 'student_university_email',
			key: 'student_university_email',
			width:'14px',
			// sorter: (a, b) => a?.student_university_email?.localeCompare(b?.student_university_email),
			// sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(u_email)=><HighlightedText text={u_email?u_email:'-'} highlight={searchValue} />
		},
		{
			title: <b>{constants.Mentor}</b>,
			columnName: constants.Mentor,
			dataIndex: 'mentor',
			key: 'mentor',
			width:'9px',
			sorter: (a, b) => a?.mentor?.localeCompare(b?.mentor),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(_, record) => <HighlightedText text={record?.mentor ? record?.mentor : '-'} highlight={searchValue} />
		},
		{
			title: <b>{constants.Active}</b>,
			columnName: constants.Active,
			dataIndex: 'is_active_text',
			key: 'is_active',
			width:'4px',
			sorter: (a, b) => a?.is_active_text?.localeCompare(b?.is_active_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (is_active_text) => <b><HighlightedText text={is_active_text} highlight={searchValue} /></b>
		},
		{
			title: <b>{constants.OnProbation}</b>,
			columnName: constants.OnProbation,
			dataIndex: 'status_text',
			key: 'on_probation',
			width:'5px',
			sorter: (a, b) => a?.status_text?.localeCompare(b?.status_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(status_text)=> <b><HighlightedText text={status_text} highlight={searchValue} /></b>
		},
		{
			title: <b>{constants.Transfer}</b>,
			columnName: constants.Transfer,
			dataIndex: 'is_transfer',
			key: 'is_transfer',
			width:'5px',
			sorter: (a, b) => a?.is_transfer?.localeCompare(b?.is_transfer),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
			render:(is_transfer)=> <span><b>{is_transfer}</b></span>
		},
		{
			title: <div style={{ textAlign: 'left' }}><b>{constants.CellPhone}</b></div>,
			columnName: constants.CellPhone,
			dataIndex: 'student_mobile_number',
			key: isAdminOrSysAdmin?'student_mobile_number':'',
			width:'6px',
			align: 'right',
			render:(mb_number)=><HighlightedText text={mb_number?mb_number:'-'} highlight={searchValue} />
		},
		{
			title: <b>{constants.DOB}</b>,
			columnName: constants.DOB,
			dataIndex: 'student_dob',
			key: 'student_dob',
			width:'7px',
			sorter: (a, b) => new Date(a?.student_dob) - new Date(b?.student_dob),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(dob)=><HighlightedText text={dob?dob:'-'} highlight={searchValue} />
		},
		{
			title: <b>{constants.City}</b>,
			columnName: constants.City,
			dataIndex: 'student_permanent_city_text',
			key: isAdminOrSysAdmin?'student_permanent_city_text':'',
			width:'7px',
			sorter: (a, b) => a?.student_permanent_city_text?.localeCompare(b?.student_permanent_city_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(city)=><HighlightedText text={city?city:'-'} highlight={searchValue} />
		},
		{
			title: <b>{constants.State}</b>,
			columnName: constants.State,
			dataIndex: 'state_name',
			key: isAdminOrSysAdmin?'state_name':'',
			width:'7px',
			sorter: (a, b) => a?.state_name?.localeCompare(b?.state_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render:(state)=><HighlightedText text={state?state:'-'} highlight={searchValue} />
		},
	];

	//show/hide column dropdownvalue
	const dropdownValue = columns.filter((item) => item.key !== 'student'&&item.key!=='');
	//default columns checked
	const defaultCheckedColumn = columns.map((item) => item.key).filter((key) => key !== 'student'&&key!=='');
	const [checkedColumn, setCheckedColumn] = useState(defaultCheckedColumn);

	// Calculate scroll width based on the number of selected columns
	const scrollWidth = useMemo(() => {
		return checkedColumn.length <= 4 ? 768 : 1700;
	}, [checkedColumn.length]);

	useEffect(() => {
		getStudentListing();
		if(!isAdminOrSysAdmin) filterStatusList();
	}, [reload]);

	useEffect(() => {
		getSemesterData();
		deleteCookie(COOKIE.FacultyIdFromEdit, "/");
	}, [])
	
	const newColumns = columns.map((item) => ({
    ...item,
    hidden: item.key !== 'student' ? !checkedColumn.includes(item.key) : false,
  })).filter((item)=> item.hidden === false);

	const filterStatusList = () => {
		let statuses = [...statusList]
		setStatusList(statuses.filter((item) => item.value === '2' || item.value === '6' || item.value === '9'))
	}
	
	const getSemesterData = () => {
		fetchApi('/semester-data','get').then((res)=> {
			if (res?.code === "200") {
				let data = res?.data
				let semIds = []
				let findIndex = data?.findIndex((val) => val.semester_is_ended === "0");
				if(findIndex > -1) {
					semIds.push(data[findIndex]?.id);
					setCurrentSemester(data[findIndex]);
				}
				let secondIndex = data?.findIndex((val)=>val.semester_is_ended === "2");
				if(secondIndex > -1) {
					semIds.push(data[secondIndex]?.id);
					setFutureSemester(data[secondIndex]);
				} 
				setSemesterIds(semIds);
				
				if(data?.length > 1) {
					setHasTwoSems(true);
					setTableDynamicHeight(true)
				}
			}
		}).catch((err) => console.log(err));
	}
	const getStudentListing = () => {
		setLoading(true);
		setSearchValue("");
		const getGPA = () => {
			const { gpa_type, gpa_value } = form.getFieldValue()
			if(!gpa_value) return 0
			return `${gpa_type}${gpa_value}`
		}

		const getName = (item) => {
			let fullname = item?.student_middle_name ? `${item?.student_first_name} ${item?.student_middle_name} ${item?.student_last_name}` : `${item?.student_first_name} ${item?.student_last_name}`
			// if(sortName === constants.LastName)
				// fullname = item?.student_middle_name ? `${item?.student_last_name} ${item?.student_middle_name} ${item?.student_first_name}` : `${item?.student_last_name} ${item?.student_first_name}`
			return fullname
		}
		const modifyData = (data) => {
			return data?.map((item, index) => ({
				...item,
				key: index + 1,
				name: getName(item),
				student_id_number: item?.student_id_number || '',
				student_gpa: item?.student_othergpa ? parseFloat(item?.student_othergpa)?.toFixed(2) : item?.student_gpa ? parseFloat(item?.student_gpa)?.toFixed(2) : '',
				status_text: item?.status === "2" ? constants.Yes : constants.No,
				is_active_text: item?.is_active === "1" ? constants.Yes : item?.is_active === "2" ? constants.No : item?.is_active === "3" ? constants.Archived : constants.Pending,
				is_transfer: item?.is_transfer === "1" ? constants.Yes : constants.No,
				current_status_text: constants.StatusList?.find((stat) => stat.value === item.status)?.label,
				student_type_text_abbr: getTypeAbbr(item?.student_type_text),
				student_year_in_program_text_abbr: getYearInPrgAbbr(constants.YearList?.find((yr) => yr.value === item?.year_in_program)?.label || constants.PartTime),
				mentor: item?.mentor ? item?.mentor?.trim() : ''
			}))
		}
		let payload = {
			semester: listingPayload.semester,
			year_in_program: listingPayload.year_in_program,
			student_type: listingPayload.student_type,
			online: listingPayload.online,
			status: listingPayload.status,
			showall: listingPayload.show_all,
			is_active: listingPayload.is_active,
			confidential_notes: listingPayload.c_notes,
			gpa: getGPA(),
			order_last_name: listingPayload.order_last_name
		}
		fetchStaffApi('/get-student-list','POST',payload).then((res)=>{
			if (res?.code === "200"){
				const modifiedData = modifyData(res?.data);
				setTableData(modifiedData);
				setFilterTableData(modifiedData);
				onTableChange(currentPage, null, tableSorters, modifiedData, false)
			} else {
				setTableData([]);
				setFilterTableData([]);
				setSortedTableData([]);
			}
			setLoading(false);
		}).catch(() => setLoading(false));
	};

	const breadcrumbItems = [
		{
			label: constants.StudentListing,
			active: true
		}
	];

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll
		)
  };

	const rowSelection = {
		fixed: 'left',
		columnWidth: '2px',
    selectedRowKeys: rowKeys,
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? tableData : []
			onSelectChange(
				data?.map((item) => item.id),
				data,
				null,
				null,
				true
			)
		},
    onSelect: (record, selected, selectedRows) => onSelectChange(
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			tableData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
  };

	const getRowClassName = (record) => {
		const { is_active, show_all } = listingPayload
		if(is_active !== '1' || (is_active === '1' && show_all === '1'))
			return semesterIds?.includes(record.semester_details_id) && 'advisor-row';
	};

	//onchange function for type | year | status | name | showall
	const onChangeSelect = (e, selectType) => {
		clearSelectedRows();
		const handleSemCheck = (val) => {
			if(hasTwoSems && (val === '1' || val === '5')) {
				setTableDynamicHeight(true)
			} 
			else {
				setTabKey('0');
				setTableDynamicHeight(false)
			}
		}
		const handleArchiveCheck = (val) => {
			if(val === '3') setHideViewArchivedButton(false)
			else setHideViewArchivedButton(true) 
		}

		setReload(!reload);
		if(selectType === "type") {
			setListingPayload({
				...listingPayload,
				student_type: e ? e : 0,
				year_in_program: e === '2' ? null : listingPayload.year_in_program
			});
		} else if(selectType === "online") {
			setListingPayload({
				...listingPayload,
				online: e
			});
		} else if (selectType === "year") {
			setListingPayload({
				...listingPayload,
				year_in_program: e ? e : null
			});
		} else if(selectType === "status") {
			setListingPayload({
				...listingPayload,
				status: e ? e : null,
			});
			if(e==='2') {
				setProbationBtn(true)
			} else {
				setProbationBtn(false)
			}
		} else if (selectType === "name") {
			setSortName(e);
			setListingPayload({
				...listingPayload,
				order_last_name: e === constants.LastName ? '1' : '0'
			})
		}else if (selectType === "radio") {
			let val = e.target.value
			if (val === '6') {
				setTabKey('0');
				setTableDynamicHeight(false)
				setListingPayload({
					...listingPayload,
					semester: '0',
					show_all: "1",
					is_active: "1",
					online: '2',
					c_notes: "0"
				});
			} else {
				handleSemCheck(val)
				handleArchiveCheck(val)
				setListingPayload({
					...listingPayload,
					semester: val === '1' || val === '5' ? tabKey : '0',
					is_active: val === '5' ? '1' : val,
					c_notes: val === '5' ? '1' : '0',
					show_all: '0',
					online: listingPayload.online === '1' ? '1' : '0',
				});
			}
		}
	};
	//button disable function
	const handleDisable = (button) => {
		let is_disable;
		if (button === "print") {
			if (tableData?.length === 0) {
				is_disable = true;
			} else {
				is_disable = false;
			}
		}
		if (button === "edit" || button === "c_notes"|| button==="view") {
			if (rowKeys.length === 1) {
				is_disable = false;
			}
			else {
				is_disable = true;
			}
		}
		if (button === "email" || button === "notification") {
			if (rowKeys.length >= 1) {
				is_disable = false;
			}
			else {
				is_disable = true;
			}
		}
		return is_disable;
	};

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search);
		const searchFieldNames = [
			"name",
			'student_first_name',
			'student_last_name',
			'id',
			"student_personal_email",
			"mentor",
			"student_mobile_number",
			"student_gpa",
			"student_dob",
			"student_university_email",
			"state_name",
			"student_type_text_abbr",
			"year_in_program_text",
			"student_year_in_program_text_abbr",
			"student_permanent_city_text",
			"is_active_text",
			"current_status_text",
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setTableData(searchedValue);
		setSortedTableData(searchedValue)
	};

	const handleRedirects = (key, record = null) => {
		let row = record ? [record] : rowData
		switch (key) {
			case 'email':
				navigate("/send-email", { state: { emailState: row, from: "student" }});
				break;
			case 'notification':
				navigate("/notification-add", { state: { notificationState: row, from: "student" }});
				break;
			case 'confidential-notes':
				navigate("/confidential-notes", { 
					state: { 
						student_id: row?.[0]?.id,
						from: constants.StudentListing,
						from_route: "/student-listing",
						student_name: row?.[0]?.name,
						sem_id: row?.[0]?.semester_details_id,
						year_in_program: row?.[0]?.year_in_program,
						faculty_id: row?.[0]?.faculty_id,
					}
				});
				break;
			case 'edit':
				if(userId === row?.[0]?.faculty_id)
					createCookie("facultyId", row?.[0]?.faculty_id, null, "/");
				navigate("/student-information", {
					state: { 
						student_id: row?.[0]?.id,
						student_semester: showTab ? tabKey : "0",
						from: constants.StudentListing,
						from_route: "/student-listing",
						tabkey: "StudentDetails",
						tab_title: constants.StudentDetails,
						student_name: row?.[0]?.student_first_name + " " + row?.[0]?.student_last_name,
						isArchived: row?.[0]?.is_active === '3',
						year_in_program: row?.[0]?.year_in_program,
						sem_id: row?.[0]?.semester_details_id,
						is_not_logged_in: row?.[0]?.is_active === '0',
					}
				});
				break;
			case 'view':
				navigate("/student-information", {
				state: {
					student_id: row?.[0].id,
					student_semester: showTab ? tabKey : "0",
					from: constants.StudentListing,
					from_route: "/student-listing",
					tabkey: "StudentDetails",
					tab_title: constants.StudentDetails,
					student_name: row?.[0]?.student_first_name + " " + row?.[0]?.student_last_name,
					isArchived: true,
					isActive: row?.[0]?.is_active,
					sem_id: row?.[0]?.semester_details_id,
					is_not_logged_in: row?.[0]?.is_active === '0',
				}
			});
				break;
			default:
				break;
		}
	}
	//table and filters component.
	const tableAndFilters = (
		<>
			<div className='flex flex-wrap justify-between items-end gap-2 mt-2'>
				<Space wrap>
					{hideViewArchivedButton &&
						<Button 
							className="text-capitalize btn-info"
							onClick={() => handleRedirects('edit')} 
							disabled={handleDisable('edit')}
						>{isAdminOrSysAdmin ? constants.Edit : constants.ViewBtnLabel}
						</Button>
					}
					{(isAdminOrSysAdmin && !hideViewArchivedButton) && 
						<Button 
							className="text-capitalize btn-info" 
							onClick={() => handleRedirects('view')} 
							disabled={handleDisable("view")}
						>{constants.Edit}
						</Button>
					}
					<Button 
						className="text-capitalize btn-info" 
						onClick={() => handleRedirects('confidential-notes')} 
						disabled={handleDisable('c_notes')}
					>{constants.ConfidentialNotes}
					</Button>
					<Print
						columns={newColumns}
						currentPage={currentPage}
						data={sortedTableData}
						disabled={handleDisable('print')}
						type='json'
						headerTitle={constants.StudentListing}
						key='print' //OPTIONAL
					/>
					{isAdminOrSysAdmin &&
						<>
							<Button className="text-capitalize btn-info" disabled={handleDisable("email")} onClick={() => handleRedirects('email')}>{constants.Email}</Button>
							<Button className="text-capitalize btn-info" disabled={handleDisable("notification")} onClick={() => handleRedirects('notification')}>{constants.Notification}</Button>
						</>
					}
					<Button className="text-capitalize btn-info" onClick={() => setIsModalOpen(true)}>{constants.GpaFilter}</Button>
					{probationBtn ? <Button className="text-capitalize btn-info" onClick={()=>setShowProbationModal(true)}>{constants.ProbationHistory}</Button>:''}
					<Select
						mode="multiple"
						className='multi-select-btn'
						maxTagCount='responsive'
						placeholder={constants.ShowHideColumn}
						listHeight={400}
						value={checkedColumn}
						onChange={(val) => setCheckedColumn(val)}
					>
						{dropdownValue.map((item) => <Option key={item.key} value={item.key}>{item.title}</Option>)}
					</Select>
				</Space>
				<Input value={searchValue} className="w-48 h-fit" size="middle" onChange={onSearch} placeholder={constants.Search} />
			</div>
			<div className='mt-2'>
				<Spin tip={constants.PleaseWait} spinning={loading}>
					<Table
						onRow={(record, rowIndex) => {
							return {
								onClick: () => onSelectChange(null, null, record, rowIndex),
								onDoubleClick: () => handleRedirects('edit', record),
							}
						}}
						className="records-table data_table"
						rowSelection={rowSelection}
						columns={newColumns}
						dataSource={tableData}
						bordered
						size='small'
						scroll={{ x: scrollWidth , y: `calc(100vh - ${dynamicHeight}px)` }}
						pagination={{
							className:"!mb-0",
							current: currentPage,
							pageSize: constants.TablePageSize,
							showTotal: (total, range) => showTotalItems(total, range, tableData),
							itemRender: getNextAndPreviousButtons,
						}}
						rowKey='id'
						rowClassName={(record)=> getRowClassName(record)}
						onChange={({ current }, filters, sorter, { currentDataSource }) => onTableChange(current, filters, sorter, currentDataSource, true)}
					/>
				</Spin>
			</div>
		</>
	);

	const handleTab = (val) => {
		setReload(!reload);
		setTabKey(val);
		setListingPayload({
			...listingPayload,
			semester: val
		})
		clearSelectedRows();
	};

	const clearSelectedRows = () => {
		setRowKeys([]);
		setRowData([]);
	}

	const filterGpa = (val) => {
		const operator = constants.GpaFilterType.find((operator) => operator.value === val?.gpa_type)?.label || 'Not Found';
		setGpaFilterValue(operator+" "+val?.gpa_value);
		setIsModalOpen(false);
		getStudentListing();
	};
	
	const removeGpaFilter = () => {
		form.resetFields();
		setGpaFilterValue("");
		getStudentListing();
	};

	const radioFilters = () => {
		return (
			<>
				<Tooltip title={!hasTwoSems && constants.CurrentSem}>
					<Radio value={"1"}>{constants.ShowActive}</Radio>
				</Tooltip>
				<Radio value={"2"}>{constants.ShowInactive}</Radio>
				<Radio value={"3"}>{constants.ShowArchived}</Radio>
				<Radio value={"0"}>{constants.ShowNotLoggedIn}</Radio>
				<Tooltip title={!hasTwoSems && constants.CurrentSem}>
					<Radio value={"5"}>{constants.ShowWithConfiNotes}</Radio>
				</Tooltip>
				<Radio value={"6"}>{constants.ShowAll}</Radio>
			</>
		)
	}

	function onTableChange(current, filters, sorter, currentDataSource, fromTableChange) {	
		let result = handleTableChange(current, filters, sorter, currentDataSource, fromTableChange)
		setCurrentPage(result?.current)
		setTableSorters(result?.sorter)
		setSortedTableData(result?.data)
	}

	const setTableDynamicHeight = (twoSems = false) => { 
		if(twoSems) setDynamicHeight(450)
		else setDynamicHeight(380)
		setShowTab(twoSems)
	}

	const gpaFilterTag = () => {
		return (
			gpaFilterValue && 
			<Tag 
				className='gpa-close-tag h-fit' 
				closeIcon={<CloseCircleOutlined className='gpa-close-icon'/>} 
				onClose={removeGpaFilter}
			>{constants.GpaFilter} : {gpaFilterValue}
			</Tag>
		)
	}
	
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.StudentListing} breadcrumbItems={breadcrumbItems} />
			<div className="row">
				<div className="col">
					<Card className="container-border-custom">
						<div className='d-flex justify-content-between'>
							<Space wrap>
								<Select showSearch onChange={(e) => onChangeSelect(e, "type")} allowClear className="w-36" 
								  optionFilterProp="children"
									filterOption={(input, option) => filterOption(input, option)}
									filterSort={(optionA, optionB) => filterSort(optionA, optionB)} 
									placeholder={constants.SelectType}>
									{
										constants.StudentTypeList.map((record) =>
											<Option value={record.value} key={record.value}>{record.label}</Option>
										)
									}
								</Select>
								<Select 
									showSearch
									allowClear
									optionFilterProp="children"
									className="w-36"
									disabled={listingPayload.student_type === '2'} 
									onChange={(e) => onChangeSelect(e, "year")} 
								 	filterOption={(input, option) => filterOption(input, option)}
									placeholder={constants.SelectYear} 
									value={listingPayload.year_in_program}>
								{
									constants.YearList.map((record) =>
										<Option value={record.value} key={record.value}>{record.label}</Option>
									)
								}
								</Select>
								<Select 
								showSearch 
								allowClear 
								className="w-32" 
								onChange={(e) => onChangeSelect(e, "status")}
								filterOption={(input, option) => filterOption(input, option)}
								filterSort={(optionA, optionB) => filterSort(optionA, optionB)} 
								placeholder={constants.SelectStatus}>
								{
									statusList.map((record) =>
										<Option value={record.value} key={record.value}>{record.label}</Option>
									)
								}
								</Select>
								<Checkbox
									checked={listingPayload?.online === '1'}
									onChange={(e) => onChangeSelect(
										e.target.checked ? '1' : 
										listingPayload?.show_all === '1' ? '2' : '0', 
										'online')}
								>
									{constants.Online}
								</Checkbox>
								<Space>
									<label>{constants.SortBy}: </label>
									<Select 
										showSearch 
										className="w-36" 
										onChange={(e) => onChangeSelect(e, "name")} 
										value={sortName}
										filterOption={(input, option) => filterOption(input, option)}
										filterSort={(optionA, optionB) => filterSort(optionA, optionB)}
										placeholder={constants.SortBy}>
									{
										<>
											<Option value={constants.FirstName} key='first_name'>{constants.FirstName}</Option>
											<Option value={constants.LastName} key='last_name'>{constants.LastName}</Option>
										</>
									}
									</Select>
								</Space>
							</Space>
							{!isAdminOrSysAdmin && gpaFilterTag()}
						</div>
						{isAdminOrSysAdmin && 
							<div className='flex flex-wrap justify-between gap-2 mt-2'>
								<Radio.Group 
									onChange={(e) => onChangeSelect(e, "radio")} 
									defaultValue={"1"}
								>{radioFilters()}
								</Radio.Group>
								{gpaFilterTag()}
							</div>
						}
						{/* display tabs based on semester cont start*/}
						{
							showTab ?
								<Tabs
									activeKey={tabKey}
									onSelect={handleTab}
									type="card"
									className="card-tabs mt-3"
								>
									<Tab eventKey="0" title={<span className=" text-capitalize fs-7">current semester ({constants.SemesterTypeList[currentSemester?.semester_type]})</span>} className="py-2">
										{tableAndFilters}
									</Tab>
									<Tab eventKey="2" title={<span className=" text-capitalize fs-7">{constants.SemesterTypeList[futureSemester?.semester_type]} semester</span>} className="py-2">
										{tableAndFilters}
									</Tab>
								</Tabs> :
								tableAndFilters
						}
						{/* display tabs based on semester count ends*/}
						{/* gpa modal starts*/}
						<Modal
							title={constants.GpaFilter}
							open={isModalOpen}
							footer={null}
							onCancel={() => setIsModalOpen(false)}
						>
							<Form layout="vertical" form={form} onFinish={filterGpa}>
								<Row gutter={24}>
									<Col xl={12} lg={12} sm={12} md={12} xs={24}>
								<Form.Item
									label={constants.Type}
									name="gpa_type"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<Select className="w-100" placeholder={constants.Select}>
										{constants.GpaFilterType.map((type, index) => {
											return <Option value={type.value} key={index} >{type.label}</Option>
										})}
									</Select>
								</Form.Item>
								</Col>
								<Col xl={12} lg={12} sm={12} md={12} xs={24}>
								<Form.Item
									label={constants.Value}
									name="gpa_value"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<InputNumber placeholder='Value' className='w-100' min={0.1} max={10} step={0.01} />
								</Form.Item>
								</Col>
								</Row>
								<Flex justify='flex-end'>
									<Form.Item >
										<Button
											type='primary'
											size="middle"
											className="text-capitalize"
											htmlType="submit"
										>
											{constants.Filter}
										</Button>
									</Form.Item>
								</Flex>
							</Form>
						</Modal>
						{/* gpa modal ends*/}
					</Card>
				</div>
			</div>
			<ProbationHistory openModal={showProbationModal} closeModal={()=>setShowProbationModal(false)}
			 tabKey={showTab ? tabKey : "0"}	/>
		</div>
	)
};

export default StudentListing