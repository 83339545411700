import React,{useState,useEffect} from 'react';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import constants from '../../../Constants/constants';
import { Row, Form, Card, Select, Button, Space, Table,Tooltip, Modal, Input, message, Spin } from 'antd';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { FilePdfOutlined, FileWordOutlined, FileExcelOutlined } from '@ant-design/icons';
import { CustomSortIcon, HighlightedText, getNextAndPreviousButtons, handleNotificationViewed, performSearch, showTotalItems, tableHeaderTitle } from '../../../GlobalFunctions/GlobalFunctions';
import { fetchStaffApi, fetchStaffDownloadApi } from '../../../Services/Api';
import { COOKIE, getCookie } from '../../../Services/Cookie';

const SurveyResult = () => {
	const location = useLocation();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [, updateNotificationData] = useOutletContext();
	const [surveyType, setSurveyType] = useState(location?.state?.selectedRecord?.survey_type);
	const [sections, setSections] = useState([]);
	const [selectedRecord,setSelectedRecord] = useState(location?.state?.selectedRecord);
	const [response, setResponse] = useState(null);
	const [allSurveyData, setAllSurveyData] = useState([]);
	const [showNotSubmittedVisible,setShowNotSubmittedVisible]=useState(false);
	const [notSubmittedStudentList,setNotSubmittedStudentList]=useState([]);
	const [notSubmittedFilteredStudentList,setNotSubmittedFilteredStudentList]=useState([]);
	const [searchValue,setSearchValue]=useState(null);
	const [selectedSectionId,setSelectedSectionId]=useState(null);
	const [selectedFacultyId,setSelectedFacultyId]=useState(null);
	const [faculties, setFaculties] = useState([]);
	const [semesterId,setSemesterId]  = useState(null);
	const [facultyAssignVisible,setFacultyAssignVisible] = useState(false);
	const [previousSemester,setPreviousSemester] = useState([]);
	const [selectedPreviousSemester,setSelectedPreviousSemester] = useState(null);
	const [loading,setLoading] = useState(false);
	const [refresh,setRefresh] = useState(false);
	const [disableFaculty,setDisableFaculty] = useState(false);
	const [isCurrentSem,setIsCurrentSem] = useState(false);
	const [displayMessage,setDisplayMessage] = useState(false);
	const loggedInUserRole = getCookie(COOKIE?.Role);
	const breadcrumbItems = [
		{ label: location?.state?.locationData?.from,
			href: location.state?.locationData?.from_route
		},
		{
			label: constants.SurveyResult,
			active: true
		}
	];
	const tableColumns = [
		{
			title: tableHeaderTitle(constants.AnswerOptions),
			key:'1',
			dataIndex:'option',
			width:"60%",
		},
		{
			title: tableHeaderTitle(constants.ResponsePercent),
			key: '2',
			align: 'center',
			width:"20%",
			render:(record)=>{
				return	<><span>{record?.percent}</span><span className="ps-1">%</span></>
			}
		},
		{
			title: tableHeaderTitle(constants.ResponseCount),
			key: '3',
			dataIndex:'count',
			align: 'center',
			width:"20%",
		}
	]
	const tableColumnsInstructor = [
		{
			title: tableHeaderTitle(constants.StudentName),
			key:'1',
			render:(record)=>{
				return	<><b>{record?.student_criteria?.student_name}</b></>
			},
			width:"50%",
		},
		{
			title: tableHeaderTitle(constants.Criteria),
			key: '2',
			align: 'center',
			render:(record)=>{
				return	<><span>{record?.student_criteria?.comment}</span></>
			},
			width:"50%",
		},
	]

	useEffect(()=>{
		//getSemesterData();
		getPastSemesterData();
		getAllSurveys();
	},[location?.state?.selectedRecord?.id])

	const getPastSemesterData = (surveyId,courseId,semesterId) => {
		fetchStaffApi(`/get-archived-survey-semester-type/${selectedRecord?.id}`, 'get').then((res) => {
			if (res?.code === "200"&&res?.data?.length>0){
				setPreviousSemester(res.data);
				for (let index = 0; index < res?.data?.length; index++) {
					if(res?.data[index]?.semester_type?.includes("Current")){
						setDisableFaculty(false);//If survey type is course or instructor and when semester is selected other then current disable faculty dropdown.
						setSemesterId(res?.data[index]?.id);
						setIsCurrentSem(true);
						form.setFieldsValue({PastResults:res?.data[index]?.id});
						setRefresh(!refresh);
						if(surveyId) {
							getSurveyResult(surveyId,courseId,semesterId);
						} else {
							getSurveyResult(
								selectedRecord?.id,
								selectedRecord?.course_id,
								res?.data[index]?.id,
								selectedRecord?.section || null,
								null
							);
						}
						break;
					}else{
						setIsCurrentSem(false);
						setDisableFaculty(true);
					}
				}
			}else{
				setPreviousSemester([]);
			}
			setSelectedFacultyId(null);
			setSelectedSectionId(null);
			form.setFieldsValue({
				faculty:null,
				section:null
			});
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const getAllSurveys = () => {
		let payload={
			is_hidden:0,
		}
		fetchStaffApi(`/get-all-surveys`, 'POST', payload).then((res) => {
			if (res?.data?.length>0){
				setAllSurveyData(res?.data);
			} else {
				setAllSurveyData([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const getSurveyResult=(surveyId,courseId,semesterId,section,faculty)=>{
		setLoading(true);
		let payload= {
			survey_id: surveyId,
			course_id: courseId
		}
		if(semesterId){
			payload.semester_id = semesterId
		}
		if(section){
			payload.section_no = section;
		}
		if(faculty){
			payload.faculty_id = faculty;
		}
		if(selectedRecord?.faculty_id) {
			payload.notification_flag = selectedRecord?.faculty_id
		}
		fetchStaffApi(`/get-survey-results`, 'POST', payload).then((res) => {
			if(res?.code === '200'&&res?.data) {
				if(res?.data?.questions?.length>0) {
					setDisplayMessage(false);
					res?.data?.questions?.forEach((item) => {
						if(item?.options?.length>0){
							item?.options?.forEach((eachOption)=>{
								eachOption.addtionalDetail=[];
								item?.option_answers?.forEach((eachoptionanswer)=>{
									if(eachoptionanswer?.option_id===eachOption?.id){
										eachOption.addtionalDetail.push(eachoptionanswer?.details);
									}
								})
							})
							item.total=item?.options.splice(item?.options?.length-1,1)				
						}
					})
				}else{
					setDisplayMessage(true);
				}
				setResponse(res.data);
				if(selectedRecord?.section_no) {
					let facultyName = `${getCookie(COOKIE.FirstName)} ${getCookie(COOKIE.LastName)}`
					setSelectedSectionId(selectedRecord?.section_no);
					setSelectedFacultyId(facultyName);
					form.setFieldsValue({
						faculty: facultyName,
						section: selectedRecord?.section_no
					});
				} else {
					let options = res?.data?.sections?.map((item) => {
						return {
							...item,
							section_no: item?.section_no,
							section_name: constants.extraSections.find((it) => it.id === parseInt(item?.section_no))?.name || item?.section_no,
						}
					})
					setSections(options);
				} 
				setLoading(false);
				clearNotification(res?.data)
			} else {
				setResponse(null);
				setSections([]);
				setFaculties([]);
				setLoading(false);
				message.error(res?.message);
			}
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	const clearNotification = (result) => {
		//CALL SET VIEW NOTIFICATIONS API AND THEN CLEAR NOTIFICATION
		if(!result?.notifications) return
		let notif = result?.notifications?.find((it) => it?.notification_id === location?.state?.selectedRecord?.notif_id)
		if(!notif?.notification_id) return
		if(!notif?.dt_viewed) {
			handleNotificationViewed(notif?.notification_id, updateNotificationData)
			return
		}
	}
	const redirectToBackPage = () => {
		navigate(location?.state?.locationData?.from_route);
	}
	const downloadFile = (value = '', ext = 'application/pdf') => {	
		let payload = {
			course_id: selectedRecord?.course_id || null,
			survey_id: selectedRecord?.id,
			send_result: 1,
		}
		if(semesterId){
			payload.semester_id=semesterId;
		}
		if(selectedSectionId){
			payload.section_no = selectedSectionId;
		}
		if(selectedFacultyId){
			payload.faculty_id = selectedFacultyId;
		}
		if(value !== '') payload.show_comments = value
		fetchStaffDownloadApi(`/download-survey-result`, 'POST', payload).then((res) => {
			if(res) {
				if(ext === 'excel') {
					downloadCode(res,"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
					return
				} else {
					downloadCode(res,ext)
					return
				}
			}
			message.error(constants.ErrorMessage);
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const downloadCode = (res,type)=>{
		var downloadLink = window.document.createElement('a');
		downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: type}));
		downloadLink.download = selectedRecord?.survey_name;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	const showNotSubmittedList=()=>{
		setShowNotSubmittedVisible(true);
		let payload={
			course_id:parseInt(selectedRecord.course_id),
			survey_id:parseInt(selectedRecord.id),
			section:selectedSectionId,
			faculty_id:selectedFacultyId,
		}
		if(selectedPreviousSemester){
			payload.semester_id = selectedPreviousSemester;
		}else if(semesterId){
			payload.semester_id = semesterId;
		}
		fetchStaffApi(`/get-pending-course-student`, 'POST', payload).then((res) => {
			if (res?.code === '200'&&res?.data?.length>0) {
				res?.data?.forEach((item)=>{
					item.year_in_program_text=constants.YeartypeCourses[item?.year_in_program] || ''
				})
				setNotSubmittedStudentList(res.data);
				setNotSubmittedFilteredStudentList(res.data);
			} else {
				setNotSubmittedStudentList([]);
				setNotSubmittedFilteredStudentList([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const studentColumns = [{
		title: 'Name', 
		width:"60%",
		sorter: (a, b) => a?.student_name?.localeCompare(b?.student_name),
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		render: (_,record) => <HighlightedText text={record?.student} highlight={searchValue} />
	},
	{ 
		title: 'Year in Program', 
		width:"40%",
		sorter: (a, b) => a?.year_in_program_text?.localeCompare(b?.year_in_program_text),
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		render: (_,record) => <HighlightedText text={record?.year_in_program_text} highlight={searchValue} />
	}];
	const onSearch=(e)=>{
		const search = e.target.value;
		setSearchValue(e.target.value);
		const searchFieldNames = [
			"student",
			'year_in_program_text',
		];
		let searchedValue = performSearch(search, notSubmittedStudentList, searchFieldNames);
		setNotSubmittedFilteredStudentList(searchedValue);
	}
	const handleChangeSurvey=(value,record)=>{
		setSelectedPreviousSemester(null);
		setSelectedRecord(record?.record);
		setSurveyType(record?.record?.survey_type);
		setSelectedFacultyId(null);
		setSelectedSectionId(null);
		setFaculties([]);
		form.setFieldsValue({
			faculty:null,
			section:null
		});
		let semesterid = null;
		if(semesterId){
			semesterid = semesterId;
		}
		getPastSemesterData(record?.record?.id,record?.record?.course_id,semesterid);
	}
	const handleChangeSection=(value,children)=>{
		if(value){
			setSelectedSectionId(value);
			let facultyId = null;
			if(children?.item?.facultyList?.length>0){
				facultyId = children?.item?.facultyList[0]?.faculty_id;
				setFaculties(children?.item?.facultyList);
				setSelectedFacultyId(children?.item?.facultyList[0]?.faculty_id);
				setDisableFaculty(false);
				form.setFieldsValue({faculty:children?.item?.facultyList[0]?.faculty_id})
			}else{
				facultyId = null;
				if(isCurrentSem){
					setFacultyAssignVisible(true);
				}
				setFaculties([]);
				setSelectedFacultyId(null);
				form.setFieldsValue({faculty:null})
			}
			form.setFieldsValue({section:value});
			let semesterid = null;
			if(selectedPreviousSemester){
				semesterid = selectedPreviousSemester;
			}else if(semesterId){
				semesterid = semesterId;
			}
			getSurveyResult(selectedRecord?.id,selectedRecord?.course_id,semesterid, value,facultyId);
		}else{
			clearSection();
		}
	}
	const handleChangePreviousSemester = (value, option) =>{
		if(value){
			if(!option?.children?.includes("Current")){
				setIsCurrentSem(false);
				setDisableFaculty(true);//If survey type is course or instructor and when semester is selected other then current disable faculty dropdown.
			}else{
				setIsCurrentSem(true);
				setDisableFaculty(false);
			}
			setSelectedPreviousSemester(value);
			getSurveyResult(selectedRecord?.id,selectedRecord?.course_id, value, selectedSectionId, selectedFacultyId);
		}else{
			getPastSemesterData();
		}
		setSelectedSectionId(null);
		setSelectedFacultyId(null);
		setFaculties([]);
		form.setFieldsValue({
			faculty:null,
			section:null
		});
	}
	const clearSection = () =>{
		setSelectedSectionId(null);
		setSelectedFacultyId(null);
		setFaculties([]);
		form.setFieldsValue({
			faculty:null,
			section:null
		});
		let semesterid = null;
		if(selectedPreviousSemester){
			semesterid = selectedPreviousSemester;
		}else if(semesterId){
			semesterid = semesterId;
		}
		getSurveyResult(selectedRecord?.id,selectedRecord?.course_id,semesterid, null,null);
	}
	const handleChangeFaculty=(value)=>{
		setSelectedFacultyId(value);
		let semesterid = null;
		if(selectedPreviousSemester){
			semesterid = selectedPreviousSemester;
		}else if(semesterId){
			semesterid = semesterId;
		}
		if(value){
			setSelectedFacultyId(value);
			form.setFieldsValue({faculty:value});
			getSurveyResult(selectedRecord?.id,selectedRecord?.course_id,semesterid, selectedSectionId, value);
		}else{
			setSelectedFacultyId(null);
			form.setFieldsValue({faculty:null});
			getSurveyResult(selectedRecord?.id,selectedRecord?.course_id,semesterid, selectedSectionId, null);
		}
	}
	const redirectToDetailsPage=()=>{
		navigate(`/results-details`, { state: { selectedRecord: selectedRecord,questions:response?.questions,semesterId:semesterId,locationData:location?.state?.locationData} });
	}
	const mailToFaculty=()=>{
		let payload={
			course_id:parseInt(selectedRecord.course_id),
			survey_id:parseInt(selectedRecord.id),
			section:selectedSectionId,
			faculty_id:selectedFacultyId,
		}
		if(selectedPreviousSemester){
			payload.semester_id = selectedPreviousSemester;
		}else if(semesterId){
			payload.semester_id = semesterId;
		}
	fetchStaffApi(`/send-pending-faculty`, 'POST', payload).then((res) => {
		if (res?.code === '200') {
			setShowNotSubmittedVisible(false);
			message.success(res?.message);
		} else {
			message.error(res?.message);
		}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const redirectToAssignFaculty =()=>{
		setFacultyAssignVisible(false);
		navigate('/faculty-courses-assignment');
	}
	const sendToFaculty = () =>{
		let payload = {
			survey_id:selectedRecord?.id,
			course_id:selectedRecord?.course_id,
			semester_id:semesterId,
			section_no:selectedSectionId,
			faculty_id:selectedFacultyId
		}
		fetchStaffApi(`/send-survey-result-to-faculty`, 'POST', payload).then((res) => {
			if (res?.code === '200') {
				message.success(res?.message);
				let semesterid = null;
				if(selectedPreviousSemester){
					semesterid = selectedPreviousSemester;
				}else if(semesterId){
					semesterid = semesterId;
				}
				getSurveyResult(selectedRecord?.id,selectedRecord?.course_id, semesterid, selectedSectionId, selectedFacultyId);
			} else {
				message.error(res?.message);
			}
			}).catch(() => {
				message.error(constants.ErrorMessage);
			});
		 }
	return (
		<Spin tip={constants.PleaseWait} spinning={loading}>
			<BreadcrumbHeader pageTitle={constants.SurveyResult} breadcrumbItems={breadcrumbItems} />
				<Card className="container-border-custom">
					
					<Form form={form} layout='vertical'>
						<div className='flex flex-wrap justify-between'>
								<div className='flex flex-wrap gap-1.5 lg:gap-3'>
									<Form.Item
										label={constants.Survey}
										name='survey_type'
										className='!w-52'
									>
										<Select 
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
											disabled={location?.state?.selectedRecord?.section_no}
											placeholder='Type' maxTagCount='responsive'
											defaultValue={selectedRecord?.survey_name}
											onChange={handleChangeSurvey}
											>
											{allSurveyData?.map((option, index) =>
												<Select.Option
													key={index}
													className={option?.is_active==="1"?"!bg-blue-200":""}
													value={option?.survey_name}
													record={option}
												>
													{option?.survey_name}
												</Select.Option>
											)}
										</Select>
									</Form.Item>
									{surveyType === '2' || surveyType === '3' ?
										<>
											<Form.Item
												label={constants.Section}
												name='section'
												className='w-36 md:w-16 lg:!w-24'
											>
												<Select
													allowClear
													disabled={response?.questions?.length===0||location?.state?.selectedRecord?.section_no}
													placeholder={constants.Section} maxTagCount='responsive' onChange={(e,children)=>{handleChangeSection(e,children)}}
													showSearch
													optionFilterProp="children"
													>
													{sections?.map((option) => (
														<Select.Option key={option.section_name} value={option.section_no} item={option}>
															{option.section_name}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												label={constants.Faculty}
												name='faculty'
												className='w-52 md:!w-48'
											>
												<Select
													disabled={(faculties?.length === 0 || response?.questions?.length===0 || disableFaculty)}
													allowClear placeholder={constants.Faculty}
													maxTagCount='responsive'
													onChange={handleChangeFaculty}
													showSearch
													optionFilterProp="children"
													filterOption={(input, option) =>
														option?.children?.toLowerCase()?.includes(input?.toLowerCase())
													}
													>
													{faculties?.map((option) => (
														<Select.Option key={option?.faculty_id} value={option?.faculty_id}>
															{option?.faculty}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</>
										:
										null}
									<Space className='!items-end'>
										{response?.answered === true &&
											<Form.Item>
												<Space>
													<Tooltip title={constants.Download}><Button disabled={!isCurrentSem} type='primary' onClick={() => { downloadFile(1) }} icon={<FilePdfOutlined />} /></Tooltip>
													<Tooltip title={constants.DownloadWithoutComments}><Button disabled={!isCurrentSem} className='btn-info' onClick={() => { downloadFile(0) }} icon={<FileWordOutlined />} /></Tooltip>
													<Tooltip title={constants.DownloadExcel}><Button disabled={!isCurrentSem} className='btn-success !bg-green-600 text-white' onClick={() => { downloadFile('', 'excel') }} icon={<FileExcelOutlined />} /></Tooltip>
												</Space>
											</Form.Item>
										}
										{surveyType === '1' &&
											<Form.Item>
												<Button type='primary' disabled={!response?.answered&&!isCurrentSem} onClick={() => { redirectToDetailsPage() }}>{constants.Details}</Button>
											</Form.Item>
										}
										{
											selectedRecord?.survey_type === "2" && 
											selectedSectionId !== null && 
											loggedInUserRole === "3" &&
												<>
													<Form.Item>
														<Button type='primary' disabled={!response?.answered&&selectedRecord?.delete_check === 1&&!isCurrentSem} onClick={() => { showNotSubmittedList() }}>{constants.NotSubmitted}</Button>
													</Form.Item>
													<Form.Item>
														{
															response?.sent_result_to_faculty &&
															response?.sent_result_to_faculty !== 1 &&
															<Button
																type='primary'
																disabled={((selectedRecord?.survey_type&&selectedRecord?.survey_type==="2"&&!selectedSectionId?true:false)||response?.answered===false||selectedFacultyId===null||selectedRecord?.delete_check === 1||!isCurrentSem)}
																onClick={() => {sendToFaculty()}}
															>
																{constants.SendToFaculty}
															</Button>
														}
													</Form.Item>
												</>
										}
										<Form.Item>
											<Button className='backButton' /* size='large' */ onClick={redirectToBackPage}>{constants.Back}</Button>
										</Form.Item>
									</Space>
								</div>
								
									<Form.Item
										label={constants.PastResults}
										name='PastResults'
										className='!w-52'
									>
										<Select
											disabled={previousSemester?.length === 0 || location?.state?.selectedRecord?.section_no}
											placeholder={constants.Select}
											maxTagCount='responsive'
											onChange={handleChangePreviousSemester}
											value={semesterId}
										>
											{previousSemester?.map((option) => (
												<Select.Option key={option?.id} value={option?.id}>
													{option?.semester_type}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
						</div>
						<Row>
							<div className='font-bold mb-2'>
								{response?.sent_result_to_faculty===1&&
									<span>{constants.ResultSentToFaculty}</span>
								}
							</div>
						</Row>
						<div className='flex flex-col gap-y-3'>
							{response?.questions.length>0?response?.questions?.map((each, i) => {
								return (
									surveyType !== '3' ?
										<Card className="surveyResultCard" title={<span className='text-base font-normal whitespace-break-spaces'>{`${i + 1}. ${each.question_text}`}</span>} key={i}>
											{each?.question_type === "1" ? (
												<Table
													bordered
													rowKey={(record) => record?.id}
													expandable={
														each.options.some(record => record?.option_detail && record?.option_detail !== "") ? {
															expandedRowRender: (record) => (
																<div className='ml-11'>
																	<div>{record.option_detail}</div>
																	<ol className='mt-2'>
																		{record?.addtionalDetail?.map((list) => {
																			return list && <li key={list}>{list}</li>;
																		})}
																	</ol>
																</div>
															),
															rowExpandable: (record) => record?.option_detail && record?.option_detail !== "",
														} : undefined
													}
													dataSource={each.options}
													columns={tableColumns}
													size="small"
													pagination={false}
													summary={() => {
														const hasExpandableRows = each.options.some(record => record?.option_detail && record?.option_detail !== "");
														return (
															<Table.Summary.Row className="totalRow">
																<Table.Summary.Cell index={0} className='fw-bold' colSpan={hasExpandableRows ? 2 : 1}>{`${each?.total?each?.total[0]?.option:"Total"}`}</Table.Summary.Cell>
																<Table.Summary.Cell index={1} className='text-center fw-bold'>{`${each?.total?each?.total[0]?.percent:"0"}%`}</Table.Summary.Cell>
																<Table.Summary.Cell index={2} className='text-center fw-bold'>{`${each?.total?each?.total[0]?.count:"0"}`}</Table.Summary.Cell>
															</Table.Summary.Row>
														);
													}}
												/>
											) : (
												<ol className='mt-2'>
													{each?.ans_details?.map((list) => {
														return list?.details && <li key={list?.details}>{list?.details}</li>;
													})}
												</ol>
											)}

										</Card>
										:
										<Card className="surveyResultCard rounded-sm" title={<span className='whitespace-break-spaces'>{`${i+1}. ${each.question_text}`}</span>} key={i}>
											{each?.question_type === "3"?
												each?.ans_details?.length>0?
												<Table
													bordered
													dataSource={each?.ans_details}
													columns={tableColumnsInstructor}
													className='mt-2'
													size="small"
													pagination={false}
												/>:<span className='mt-2 px-8'>No students were selected</span>:
												<ol className='mt-2'>
													{each?.ans_details?.length>0?
													each?.ans_details?.map((list) => {
														return <li key={list.details}>{list.details}</li>
													}):<span className='mt-2'>No students were selected</span>
													}
												</ol>
											}
									</Card>
									)
								})
							:displayMessage&&<div className='text-center justify-between'>{constants.NoQuestions}</div>}
						</div>
					</Form>
				</Card>
			{showNotSubmittedVisible&&
				<Modal title="Selected Students" open={showNotSubmittedVisible} onCancel={()=>setShowNotSubmittedVisible(false)} width={600} footer={null}>
					<div className="flex justify-end mt-3">
						<Input placeholder={constants.Search} onChange={onSearch} value={searchValue} className="!w-48"></Input>
					</div>
					<div className='mt-3 mb-2'>
						<Table 
							columns={studentColumns} 
							dataSource={notSubmittedFilteredStudentList} 
							bordered size="small" 
							className="records-table data_table"
							pagination={{
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range,showNotSubmittedList ),
								itemRender: getNextAndPreviousButtons,
							}}
							scroll={{ y: '50vh' }}/>
					</div>
					<div className="flex justify-end mt-3">
						<Button 
							type="primary"
							className='mb-3'
							onClick={()=>{setShowNotSubmittedVisible(false)}}
							danger>
							{constants.Cancel}
						</Button>
						<Button
							htmlType='submit'
							type="primary"
							disabled={notSubmittedFilteredStudentList?.length>0?false:true}
							className='ms-2 text-sm mb-3'
							onClick={()=>{mailToFaculty()}}>
							{constants.MailToFaculty}
						</Button>
					</div>
				</Modal>
			}
			{facultyAssignVisible&&
				<Modal 
					title={constants.FacultyNotAssignedTitle} 
					open={facultyAssignVisible} 
					onCancel={()=>setFacultyAssignVisible(false)} 
					width={600} 
					footer={null}>
						{constants.FacultyNotAssignedText}
						<div className="flex justify-end mt-3">
						<Button 
							type="primary"
							className='mb-3'
							onClick={()=>{
								setFacultyAssignVisible(false);
								setSelectedSectionId(null);
								form.setFieldsValue({section:null})
							}}
							danger>
							{constants.Cancel}
						</Button>
						<Button 
							type="primary"
							className='ms-2 text-sm mb-3'
							onClick={()=>{redirectToAssignFaculty()}}>
							{constants.GoToFacultyAssign}
						</Button>
					</div>
				</Modal>
			}
		</Spin>
	)
}
export default SurveyResult