/* eslint-disable no-mixed-operators */
import React, { useEffect, useState, useCallback } from 'react'
import { Collapse, Button, Space, Table, Input, Select, message, Flex, Spin, Modal, Tooltip, DatePicker, InputNumber, Upload, Alert, Divider } from 'antd';
import { DownCircleFilled, FileOutlined, FileTextOutlined, DownloadOutlined, DislikeOutlined, LikeOutlined, LikeFilled, DislikeFilled, UploadOutlined, CheckOutlined } from '@ant-design/icons';
import { fetchApi, fetchDownloadApi, fetchStaffApi, fetchStaffDownloadApi, fetchStaffFormData } from '../../../Services/Api';
import constants from '../../../Constants/constants';
import { EditableRow, EditableCell } from './EditableRow';
import moment from 'moment';
import { COOKIE, getCookie } from '../../../Services/Cookie';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import FileUpload from '../../Component/FileUpload';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRegistered, faRetweet, faShareFromSquare, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { filterSelectOption } from '../../../GlobalFunctions/GlobalFunctions';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

function getAllSections(sections) {
	return Array.from({ length: sections }, (_, i) => {
		return {
			id: i + 1,
			name: i + 1
		}
	}).concat(constants.extraSections)
}

const Courses = ({ student_id = getCookie(COOKIE.UserId), student_name }) => {
	const role = getCookie(COOKIE.Role);
	const [collapseItemData, setCollapseItemData] = useState([]);
	const [courseAssigned, setCourseAssigned] = useState(null);
	const [apiData, setApiData] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);
	const [selectedRowData, setselectedRowData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [courseCurriculumList, setCourseCurriculumList] = useState([]);
	const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
	const [isRemoveCourseModalOpen, setIsRemoveCourseModalOpen] = useState(false);
	const [noteValue, setNoteValue] = useState("");
	const [noteTitle, setNoteTitle] = useState("");
	const [reload, setReload] = useState(false);
	const [noteIndex, setNoteIndex] = useState({
		corse_index: null,
		note_index: null
	});
	const [isDisableExportBtn, setIsDisableExportBtn] = useState(true);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [approvedValue, setApprovedValue] = useState({
		id: null,
		approved_type: null
	});
	const [isCourseNotApproved, setIsCourseNotApproved] = useState(false);
	const isStudent = role === '0'

	const [yearInProgramList, setYearInProgramList] = useState([])
	const [yearSemList, setYearSemList] = useState([])

	const defaultcoreCurriculumColumns = [
		{
			title: constants.Areas,
			dataIndex: 'area',
			key: 'area',
			width: collapseItemData[0]?.is_course_assigned === 0 ? "20%" : "10%",
			hidden: false,
			ellipsis: true,
			render: (area, value) => {
				if (value?.is_add) {
					return <Select
						showSearch
						allowClear
						className='w-100'
						placeholder={constants.Areas}
						onChange={(e) => handleChangeCourseCurriculumList(e, 0)}
						id={value?.id ? null : "scroll1"}
						filterOption={filterSelectOption}
					>
						{courseCurriculumList?.map((val) => <Option key={val?.id} value={val?.id}>{val?.area}</Option>)}
					</Select>
				} else {
					return <Tooltip title={area}><span>{area}</span></Tooltip>
				}
			},
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 2 : 1,
				}
			),
		},
		{
			title: constants.Courses,
			dataIndex: 'course_text',
			key: 'course',
			ellipsis: true,
			width: collapseItemData[0]?.is_course_assigned === 0 ? "30%" : "20%",
			hidden: false,
			editable: true,
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 0 : 1,
				}
			),
		},
		{
			title: constants.Credits,
			dataIndex: 'credits',
			key: 'credits',
			width: "10%",
			hidden: false,
			render: (_, value) => {
				if (value?.is_approved === "1") {
					return <Flex className="table-number-align numbers-alignment">{value?.course_credit}</Flex>
				} else {
					return <Select
						allowClear
						showSearch
						className='w-100'
						placeholder={constants.Credits}
						disabled={value?.is_add || value?.is_approved === "1" ? true : false}
						status={handleErrorStatus(value, value?.course_credit)}
						value={value?.course_credit ? String(value?.course_credit) : null}
						onChange={(e) => onChangeCoreCurriculumRow(e, value, "course_credit", 0)}
					>
						{constants.CreditValue.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
					</Select>
				}
			},
		},
		{
			title: constants.SemesterSchedule,
			dataIndex: 'semesterSchedule',
			width: collapseItemData[0]?.is_course_assigned === 0 ? "37%" : "35%",
			key: 'semester-schedule',
			hidden: false,
			children: [
				{
					title: 'Class',
					dataIndex: 'class',
					key: 'class',
					width: '10%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let yearIndex = constants.YearList.findIndex((value) => value.value === String(val?.course_year));
							if (yearIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.YearList[yearIndex].label}</span>
							}
						} else {
							const studentYearList = yearInProgramList/* constants.YearList;*/
							let yearIndex = studentYearList.findIndex((value) => value.value === String(val?.course_year));
							// const studentYearList = getStudentYearList(val?.year_in_program);
							// let yearIndex = constants.YearList.findIndex((value) => value.value === String(val?.course_year));
							const isValuePresent = studentYearList.some(item => item.value === String(val?.course_year));
							return <Select
								showSearch
								allowClear
								className='w-100'
								placeholder={'Class'}
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								status={handleErrorStatus(val, val?.course_year)}
								value={!isValuePresent ? studentYearList[yearIndex]?.label : val?.course_year ? String(val?.course_year) : null}
								// value={!isValuePresent ? constants.YearList[yearIndex]?.label : val?.course_year ? String(val?.course_year) : null}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "course_year", 0)}
								filterOption={filterSelectOption}
							>
								{studentYearList.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
							</Select>
						}
						//year_in_program=6->part time user.
						// if (val?.year_in_program === "6") {
						// 	return <span className='pl-2 whitespace-nowrap'>{constants.PartTime}</span>
						// } else {
							
						// }
					},
				},
				{
					title: 'Semester',
					dataIndex: 'semester',
					key: 'semester',
					width: collapseItemData[0]?.is_course_assigned === 0 ? "17%" : '15%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let semIndex = constants.StudentSemList.findIndex((value) => value.key === String(val?.course_semester));
							if (semIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.StudentSemList[semIndex].value}</span>
							}
						} else {
							const semList = val?.year_in_program === "6" ? constants.StudentSemList : getStudentSemList(val?.course_year, val?.year_in_program, val?.current_semester);
							return <Select
								showSearch
								allowClear
								className='w-100'
								placeholder={'Semester'}
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "course_semester", 0)}
								value={val?.course_semester ? String(val?.course_semester) : null}
								status={handleErrorStatus(val, val?.course_semester)}
								filterOption={filterSelectOption}
							>
								{val?.semester_dropdown?.map((it) => <Option key={it?.value} value={it?.key}>{it?.value}</Option>)}
							</Select>
						}
					},
				},
				{
					title: constants.Year,
					dataIndex: 'calendar_year_scheduled',
					key: 'year',
					width: '10%',
					render: (calendar_year_scheduled, value) => {
						if (value?.is_approved === "1") {
							return <span className='pl-2'>{calendar_year_scheduled}</span>
						} else {
							return <DatePicker
								value={calendar_year_scheduled ? dayjs(calendar_year_scheduled) : null}
								onChange={(_, dateString) => onChangeCoreCurriculumRow(dateString ? dayjs(dateString).format("YYYY") : null, value, "calendar_year_scheduled", 0)}
								format={"YYYY"}
								status={handleErrorStatus(value, value?.calendar_year_scheduled)}
								className='w-full'
								picker='year'
								disabled={value?.is_add || value?.is_approved === "1" ? true : false}
							/>
						}
					},
				},
			]
		},
		{
			title: constants.Notes,
			dataIndex: 'notes',
			key: 'notes',
			width: '5%',
			hidden: collapseItemData[0]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.notes) {
						return <div className='text-center'>
							<Button onClick={() => onClickNote(0, value?.notes, value)} type='link' icon={<FileTextOutlined />} />
						</div>
					}
				} else {
					return <div className='text-center'>
						{
							value?.is_add ? null :
								<Button onClick={() => onClickNote(0, value?.notes, value)} disabled={value?.is_add || value?.is_approved === "1" ? true : false} type='link' icon={value?.notes ? <FileTextOutlined /> : <FileOutlined />} />
						}
					</div>
				}
			}
		},
		{
			title: constants.Status,
			dataIndex: 'status',
			key: 'status',
			width: role === "0" ? '4%' : '10%',
			align: role === "0" ? 'center' : 'left',
			hidden: collapseItemData[0]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (value?.area !== "Core") {
					if (role === "0") {
						if (value?.status) {
							return getStudentStatusIcon(String(value?.status));
						}
					} else {
						if (value?.is_approved === "1") {
							let statusIndex = constants.SlpGraduteStatus.findIndex((val) => val.value === String(value?.status));
							if (statusIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.SlpGraduteStatus[statusIndex].label}</span>
							}
						} else {
							return <Select
								showSearch
								allowClear
								className='w-100'
								placeholder={constants.Status}
								disabled={value?.is_add || value?.is_approved === "1" ? true : false}
								onChange={(e) => onChangeCoreCurriculumRow(e, value, "status", 0)}
								value={value?.status ? String(value?.status) : null}
								status={handleErrorStatus(value, value?.status)}
								filterOption={filterSelectOption}
							>
								{constants.SlpGraduteStatus.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
							</Select>
						}
					}
				}
			},
		},
		{
			title: constants.Grade,
			dataIndex: 'grade',
			key: 'grade',
			width: '7%',
			hidden: collapseItemData[0]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (value?.area !== "Core") {
					if (value?.is_approved === "1" && value?.grade && !value?.is_edited) {
						let gradeIndex = constants.GradeDropDown.findIndex((val) => val.value === String(value?.grade));
						if (gradeIndex > -1) {
							return <span className='pl-2'>{constants.GradeDropDown[gradeIndex].label}</span>
						}
					} else {
						return <Select
							showSearch
							allowClear
							className='w-100'
							placeholder={constants.Grade}
							value={value?.grade ? value?.grade : null}
							disabled={value?.is_add ? true : false}
							onChange={(e) => onChangeCoreCurriculumRow(e, value, "grade", 0)}
							filterOption={filterSelectOption}
						>
							{constants.GradeDropDown.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
						</Select>
					}
				}
			},
		},
	].filter((val) => !val?.hidden);

	const coreCurriculumColumns = defaultcoreCurriculumColumns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record, rowIndex) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: (row) => onChangeCoreCurriculumRow(row?.course_text, row, col.dataIndex, 0),
				handleErrorStatus,
				colSpan: col.onCell(record, rowIndex).colSpan,
			}),
		};
	});

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const undergraduateColumns = [
		{
			title: 'Course Number',
			dataIndex: 'course_code',
			key: 'CourseNumber',
			width: "10%",
			hidden: false,
			ellipsis: true,
			render: (course_code, value) => {
				if (value?.is_add) {
					return <Select
						id={value?.id ? null : "scroll2"}
						allowClear
						showSearch
						className='w-100'
						placeholder={"Select"}
						value={value?.is_add === true ? null : value?.id ? value?.id : null}
						filterOption={filterSelectOption}
						onChange={(e) => handleChangeCourseCurriculumList(e, 1)}
					>
						{courseCurriculumList?.map((val) => <Option key={val?.id} value={val?.id}>{val?.course_name}</Option>)}
					</Select>
				} else {
					return <Tooltip title={course_code}><span>{course_code}</span></Tooltip>
				}
			},
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 2 : 1,
				}
			),
		},
		{
			title: 'Course',
			dataIndex: 'course_text',
			key: 'course',
			width: collapseItemData[1]?.is_course_assigned === 0 ? "30%" : "20%",
			hidden: false,
			ellipsis: true,
			onCell: (val, index) => ({
				colSpan: val?.is_add ? 0 : 1,
			}),
			render: (course_text) => {
				return <Tooltip title={course_text}><span>{course_text}</span></Tooltip>
			}
		},
		{
			title: constants.Section,
			dataIndex: 'number_of_sections',
			key: 'section',
			width: collapseItemData[1]?.is_course_assigned === 0 ? "10%" : "7%",
			hidden: false,
			render: (number_of_sections, val) => {
				if (val?.is_approved === "1") {
					return <Flex className="table-number-align numbers-alignment">{val?.section_number}</Flex>
				} else {
					let sections = getAllSections(number_of_sections)
					return (
						<Select
							showSearch
							allowClear
							className='w-100'
							placeholder={constants.Section}
							disabled={val?.is_add || val?.is_approved === "1"}
							status={handleErrorStatus(val, val?.section_number)}
							filterOption={(input, option) => option?.children?.toString()?.toLowerCase()?.includes(input?.toString()?.toLowerCase())}
							value={val?.section_number ? parseInt(val?.section_number) : null}
							onChange={(e) => onChangeCoreCurriculumRow(e, val, "section_number", 1)}
						>
							{sections?.length > 0 && sections.map((item) => (
								<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
							))}
						</Select>
					);
				}
			},
		},
		{
			title: 'Credits',
			dataIndex: 'course_credit',
			key: 'credits',
			width: collapseItemData[1]?.is_course_assigned === 0 ? "10%" : "8%",
			hidden: false,
			render: (course_credit) => <Flex className="table-number-align numbers-alignment">{course_credit}</Flex>
		},
		{
			title: constants.SemesterSchedule,
			dataIndex: 'semesterSchedule',
			key: 'semester-schedule',
			width: collapseItemData[1]?.is_course_assigned === 0 ? "37%" : "30%",
			hidden: false,
			children: [
				{
					title: 'Class',
					dataIndex: 'class',
					key: 'class',
					width: '10%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let yearIndex = constants.YearList.findIndex((value) => value.value === String(val?.year_scheduled));
							if (yearIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.YearList[yearIndex].label}</span>
							}
						} else {
							const studentYearList = yearInProgramList/* constants.YearList;*/
							// const studentYearList = getStudentYearList(val?.year_in_program);
							let yearIndex = studentYearList.findIndex((value) => value.value === String(val?.year_scheduled));
							const isValuePresent = studentYearList.some(item => item.value === String(val?.year_scheduled));
							return <Select
								showSearch
								allowClear
								className='w-100'
								placeholder={'Class'}
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								status={handleErrorStatus(val, val?.year_scheduled)}
								value={!isValuePresent ? constants.YearList[yearIndex]?.label : val?.year_scheduled ? String(val?.year_scheduled) : null}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "year_scheduled", 1)}
								filterOption={filterSelectOption}
							>{studentYearList.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}</Select>
						}
						//year_in_program=6->part time user.
						// if (val?.year_in_program === "6") {
						// 	return <span className='pl-2'>{constants.PartTime}</span>
						// } else {
							
						// }
					},
				},
				{
					title: 'Semester',
					dataIndex: 'semester',
					key: 'semester',
					width: collapseItemData[1]?.is_course_assigned === 0 ? '17%' : '10%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let semIndex = constants.StudentSemList.findIndex((value) => value.key === String(val?.semester_scheduled));
							if (semIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.StudentSemList[semIndex].value}</span>
							}
						} else {
							const semList = /*semesterList*/ constants.StudentSemList
							// const semList = val?.year_in_program === "6" ? constants.StudentSemList : getStudentSemList(val?.year_scheduled, val?.year_in_program, val?.current_semester);
							return <Select
								showSearch
								allowClear
								className='w-100'
								placeholder={'Semester'}
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								status={handleErrorStatus(val, val?.semester_scheduled)}
								value={val?.semester_scheduled ? String(val?.semester_scheduled) : null}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "semester_scheduled", 1)}
								filterOption={filterSelectOption}
							>
								{val?.semester_dropdown?.map((val) => <Option key={val?.value} value={val?.key}>{val?.value}</Option>)}
							</Select>
						}
					},
				},
				{
					title: constants.Year,
					dataIndex: 'calendar_year_scheduled',
					key: 'year',
					width: '10%',
					render: (calendar_year_scheduled, val) => {
						if (val?.is_approved === "1") {
							return <span className='pl-2'>{calendar_year_scheduled}</span>
						} else {
							return <DatePicker
								value={calendar_year_scheduled ? dayjs(calendar_year_scheduled) : null}
								onChange={(_, dateString) => onChangeCoreCurriculumRow(dateString ? dayjs(dateString).format("YYYY") : null, val, "calendar_year_scheduled", 1)}
								format={"YYYY"}
								status={handleErrorStatus(val, val?.calendar_year_scheduled)}
								className='w-full'
								picker='year'
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
							/>
						}
					},
				},
			]
		},
		{
			title: constants.Notes,
			dataIndex: 'notes',
			key: 'notes',
			width: '5%',
			hidden: collapseItemData[1]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.notes) {
						return <div className='text-center'>
							<Button onClick={() => onClickNote(1, value?.notes, value)} type='link' icon={<FileTextOutlined />} />
						</div>
					}
				} else {
					return <div className='text-center'>
						{
							value?.is_add ? null :
								<Button onClick={() => onClickNote(1, value?.notes, value)} disabled={value?.is_add || value?.is_approved === "1" ? true : false} type='link' icon={value?.notes ? <FileTextOutlined /> : <FileOutlined />} />
						}
					</div>
				}
			}
		},
		{
			title: constants.Status,
			dataIndex: 'status',
			key: 'status',
			width: role === "0" ? '4%' : '10%',
			align: role === "0" ? 'center' : 'left',
			hidden: collapseItemData[1]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.status) {
						return getStudentStatusIcon(String(value?.status));
					}
				} else {
					if (value?.is_approved === "1") {
						let statusIndex = constants.SlpGraduteStatus.findIndex((val) => val.value === String(value?.status));
						if (statusIndex > -1) {
							return <span className='pl-2 whitespace-nowrap'>{constants.SlpGraduteStatus[statusIndex].label}</span>
						}
					} else {
						return <Select
							showSearch
							allowClear
							className='w-100'
							placeholder={constants.Status}
							disabled={value?.is_add || value?.is_approved === "1" ? true : false}
							value={value?.status ? String(value?.status) : null}
							onChange={(e) => onChangeCoreCurriculumRow(e, value, "status", 1)}
							filterOption={filterSelectOption}
						>
							{constants.SlpGraduteStatus.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
						</Select>
					}
				}
			},
		},
		{
			title: constants.Grade,
			dataIndex: 'grade',
			key: 'grade',
			width: '7%',
			hidden: collapseItemData[1]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (value?.is_approved === "1" && value?.grade && !value?.is_edited) {
					let gradeIndex = constants.GradeDropDown.findIndex((val) => val.value === String(value?.grade));
					if (gradeIndex > -1) {
						return <span className='pl-2'>{constants.GradeDropDown[gradeIndex].label}</span>
					}
				} else {
					return <Select
						showSearch
						allowClear
						className='w-100'
						placeholder={constants.Grade}
						value={value?.grade ? value?.grade : null}
						onChange={(e) => onChangeCoreCurriculumRow(e, value, "grade", 1)}
						disabled={value?.is_add ? true : false}
						filterOption={filterSelectOption}
					>
						{constants.GradeDropDown.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
					</Select>
				}
			},
		}
	].filter((val) => !val?.hidden);

	const freeElectivesColumns = [
		{
			title: 'Course Number',
			dataIndex: 'course_code',
			key: 'CourseNumber',
			width: collapseItemData[2]?.is_course_assigned === 0 ? "20%" : "10%",
			hidden: false,
			ellipsis: true,
			render: (course_code, value) => {
				if (value?.is_add) {
					return <Select
						showSearch
						allowClear
						className='w-100'
						placeholder={"Select"}
						id={value?.id ? null : "scroll3"}
						status={handleErrorStatus(value, value?.course_code)}
						onChange={(e) => handleChangeCourseCurriculumList(parseInt(e), 2)}
						filterOption={filterSelectOption}
					>
						{courseCurriculumList?.map((val) => <Option key={val?.id} value={val?.id}>{val?.course_name}</Option>)}
					</Select>
				} else if (value?.is_add === false && value?.id === -1) {
					return (
						<Input 
							placeholder={constants.CourseCode} 
							onChange={(e) => onChangeCoreCurriculumRow(e.target.value, value, "course_code", 2)} 
							status={handleErrorStatus(value, value?.course_code)}
						/>
					)
				}
				else {
					return <Tooltip title={course_code}><span>{course_code}</span></Tooltip>
				}
			},
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 2 : 1,
				}
			),
		},
		{
			title: 'Course',
			dataIndex: 'course_text',
			key: 'course',
			width: collapseItemData[2]?.is_course_assigned === 0 ? "30%" : "20%",
			hidden: false,
			ellipsis: true,
			render: (course_text, value) => {		
				if (value?.is_add === false && value?.id === -1) {
					return (
						<Input 
							placeholder={constants.CourseName} 
							onChange={(e) => onChangeCoreCurriculumRow(e.target.value, value, "course_name", 2)} 
							status={handleErrorStatus(value, value?.course_name)}
						/>
					)
				} else {
					return <Tooltip title={course_text}><span>{course_text}</span></Tooltip>
				}
			},
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 0 : 1,
				}
			),
		},
		{
			title: constants.Section,
			dataIndex: 'number_of_sections',
			key: 'section',
			width: collapseItemData[2]?.is_course_assigned === 0 ? "10%" : "7%",
			hidden: false,
			render: (number_of_sections, val) => {
				if (val?.is_approved === "1") {
					return <Flex className="table-number-align numbers-alignment">{val?.section_number}</Flex>
				} if ((val?.is_add === false && val?.id === -1) || (val?.is_core_course && parseInt(val?.is_core_course) === 2)) {
					return (
						<Select className='w-100' disabled value={1}>
							<Option key={1}>1</Option>
						</Select>
					) 
				} else {
					let sections = getAllSections(number_of_sections)
					return (
						<Select
							showSearch
							allowClear
							className='w-100'
							placeholder={constants.Section}
							disabled={val?.is_add || val?.is_approved === "1"}
							status={handleErrorStatus(val, val?.section_number)}
							filterOption={(input, option) => option?.children?.toString()?.toLowerCase()?.includes(input?.toString()?.toLowerCase())}
							value={val?.section_number ? parseInt(val?.section_number) : null}
							onChange={(e) => onChangeCoreCurriculumRow(e, val, "section_number", 2)}
						>
							{sections?.length > 0 && sections.map((item) => (
								<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
							))}
						</Select>
					);
				}
			},
		},
		{
			title: 'Credits',
			dataIndex: 'course_credit',
			key: 'credits',
			width: collapseItemData[2]?.is_course_assigned === 0 ? "10%" : "7%",
			render: (credits, value) => {
				if (value?.is_approved === "1") {
					return <Flex className="table-number-align numbers-alignment">{credits}</Flex>
				} else {
					if (value?.is_add === false && value?.id === -1) {
						const options = [];
						if (value?.other_course_credit) {
							for (let i = 1; i <= value?.other_course_credit; i++) {
								options.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
							}
						}
						return <Select
							allowClear
							showSearch
							className='w-100'
							placeholder={constants.Credits}
							value={value?.course_credit ? value?.course_credit : null}
							status={handleErrorStatus(value, value?.course_credit)}
							onChange={(e) => onChangeCoreCurriculumRow(e, value, "course_credit", 2)}
						>
							{options}
						</Select>
					} else {
						return <Flex className="table-number-align numbers-alignment">{credits}</Flex>
					}
				}
			},
			align: 'left',
			hidden: false,
		},
		{
			title: constants.SemesterSchedule,
			dataIndex: 'semesterSchedule',
			key: 'semester-schedule',
			width: collapseItemData[2]?.is_course_assigned === 0 ? "37%" : "35%",
			hidden: false,
			children: [
				{
					title: 'Class',
					dataIndex: 'class',
					key: 'class',
					width: '10%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let yearIndex = constants.YearList.findIndex((value) => value.value === String(val?.course_year));
							if (yearIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.YearList[yearIndex].label}</span>
							}
						} else {
							const studentYearList = yearInProgramList/* constants.YearList;*/
							// const studentYearList = getStudentYearList(val?.year_in_program);
							let yearIndex = studentYearList.findIndex((value) => value.value === String(val?.course_year));
							const isValuePresent = studentYearList.some(item => item.value === String(val?.course_year));
							return <Select
								allowClear
								showSearch
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								className='w-100'
								placeholder={'Class'}
								status={handleErrorStatus(val, val?.course_year)}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "course_year", 2)}
								value={!isValuePresent ? constants.YearList[yearIndex]?.label : val?.course_year ? String(val?.course_year) : null}
								filterOption={filterSelectOption}
							>{studentYearList.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}</Select>
						}
						//year_in_program=6->part time user.
						// if (val?.year_in_program === "6") {
						// 	return <span className='pl-2'>{constants.PartTime}</span>
						// } else {
							
						// }
					},
				},
				{
					title: 'Semester',
					dataIndex: 'semester',
					key: 'semester',
					width: collapseItemData[2]?.is_course_assigned === 0 ? '17%' : '15%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let semIndex = constants.StudentSemList.findIndex((value) => value.key === String(val?.course_semester));
							if (semIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.StudentSemList[semIndex].value}</span>
							}
						} else {
							const semList = /*semesterList*/ constants.StudentSemList
							// const semList = val?.year_in_program === "6" ? constants.StudentSemList : getStudentSemList(val?.course_year, val?.year_in_program, val?.current_semester);
							return <Select
								allowClear
								showSearch
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								className='w-100'
								placeholder={'Semester'}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "course_semester", 2)}
								value={val?.course_semester ? String(val?.course_semester) : null}
								status={handleErrorStatus(val, val?.course_semester)}
								filterOption={filterSelectOption}
							>
								{val?.semester_dropdown?.map((val) => <Option key={val?.value} value={val?.key}>{val?.value}</Option>)}
							</Select>
						}
					},
				},
				{
					title: constants.Year,
					dataIndex: 'calendar_year_scheduled',
					key: 'year',
					width: '10%',
					render: (calendar_year_scheduled, val) => {
						if (val?.is_approved === "1") {
							return <span className='pl-2'>{calendar_year_scheduled}</span>
						} else {
							return <DatePicker
								value={calendar_year_scheduled ? dayjs(calendar_year_scheduled) : null}
								onChange={(_, dateString) => onChangeCoreCurriculumRow(dateString ? dayjs(dateString).format("YYYY") : null, val, "calendar_year_scheduled", 2)}
								format={"YYYY"}
								status={handleErrorStatus(val, val?.calendar_year_scheduled)}
								className='w-full'
								picker='year'
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
							/>
						}
					},
				},
			]
		},
		{
			title: constants.Notes,
			dataIndex: 'notes',
			key: 'notes',
			width: '5%',
			hidden: collapseItemData[2]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.notes) {
						return <div className='text-center'>
							<Button onClick={() => onClickNote(2, value?.notes, value)} type='link' icon={<FileTextOutlined />} />
						</div>
					}
				} else {
					return <div className='text-center'>
						{value?.is_add ? null :
							<Button onClick={() => onClickNote(2, value?.notes, value)} disabled={value?.is_add || value?.is_approved === "1" ? true : false} type='link' icon={value?.notes ? <FileTextOutlined /> : <FileOutlined />} />
						}
					</div>
				}
			}

		},
		{
			title: constants.Status,
			dataIndex: 'status',
			key: 'status',
			width: role === "0" ? '4%' : '13%',
			align: role === "0" ? 'center' : 'left',
			hidden: collapseItemData[2]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.status) {
						return getStudentStatusIcon(String(value?.status));
					}
				} else {
					if (value?.is_approved === "1") {
						let statusIndex = constants.SlpGraduteStatus.findIndex((val) => val.value === String(value?.status));
						if (statusIndex > -1) {
							return <span className='pl-2 whitespace-nowrap'>{constants.SlpGraduteStatus[statusIndex].label}</span>
						}
					} else {
						return <Select
							showSearch
							allowClear
							className='w-100'
							placeholder={constants.Status}
							disabled={value?.is_add || value?.is_approved === "1" ? true : false}
							onChange={(e) => onChangeCoreCurriculumRow(e, value, "status", 2)}
							value={value?.status ? String(value?.status) : null}
							filterOption={filterSelectOption}
						>
							{constants.SlpGraduteStatus.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
						</Select>
					}
				}
			},
		},
		{
			title: constants.Grade,
			dataIndex: 'grade',
			key: 'grade',
			width: '7%',
			hidden: collapseItemData[2]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (value?.is_approved === "1" && value?.grade && !value?.is_edited) {
					let gradeIndex = constants.GradeDropDown.findIndex((val) => val.value === String(value?.grade));
					if (gradeIndex > -1) {
						return <span className='pl-2'>{constants.GradeDropDown[gradeIndex].label}</span>
					}
				} else {
					return <Select
						allowClear
						showSearch
						className='w-100'
						placeholder={constants.Grade}
						value={value?.grade ? value?.grade : null}
						disabled={value?.is_add ? true : false}
						onChange={(e) => onChangeCoreCurriculumRow(e, value, "grade", 2)}
						filterOption={filterSelectOption}
					>
						{constants.GradeDropDown.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
					</Select>
				}
			},
		},
	].filter((val) => !val?.hidden);

	const graduateColumns = [
		{
			title: 'Course Number',
			dataIndex: 'course_code',
			key: 'CourseNumber',
			width: "10%",
			hidden: false,
			ellipsis: true,
			render: (course_code, value) => {
				if (value?.is_add) {
					return <Select
						showSearch
						className='w-100'
						placeholder={"Select"}
						value={value?.is_add === true ? null : value?.id ? value?.id : null}
						onChange={(e) => handleChangeCourseCurriculumList(e, 3)}
						allowClear
						id={value?.id ? null : "scroll4"}
						filterOption={filterSelectOption}
					>
						{courseCurriculumList?.map((val) => <Option key={val?.id} value={val?.id}>{val?.course_name}</Option>)}
					</Select>
				} else {
					return <Tooltip title={course_code}><span>{course_code}</span></Tooltip>
				}
			},
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 2 : 1,
				}
			),
		},
		{
			title: 'Course',
			dataIndex: 'course_text',
			key: 'course',
			width: collapseItemData[3]?.is_course_assigned === 0 ? "30%" : "20%",
			onCell: (val, index) => (
				{
					colSpan: val?.is_add ? 0 : 1,
				}
			),
			render: (course_text) => {
				return <Tooltip title={course_text}><span>{course_text}</span></Tooltip>
			}
		},
		{
			title: 'Section',
			dataIndex: 'number_of_sections',
			key: 'section',
			width: collapseItemData[3]?.is_course_assigned === 0 ? "10%" : "8%",
			hidden: false,
			render: (number_of_sections, val) => {
				if (val?.is_approved === "1") {
					return <Flex className="table-number-align numbers-alignment">{val?.section_number}</Flex>
				} else {
					let sections = getAllSections(number_of_sections)
					return (
						<Select
							className='w-100'
							allowClear
							showSearch
							placeholder={constants.Section}
							disabled={val?.is_add || val?.is_approved === "1"}
							value={val?.section_number ? parseInt(val?.section_number) : null}
							status={handleErrorStatus(val, val?.section_number)}
							filterOption={(input, option) => option?.children?.toString()?.toLowerCase()?.includes(input?.toString()?.toLowerCase())}
							onChange={(e) => onChangeCoreCurriculumRow(e, val, "section_number", 3)}
						>
							{sections?.length > 0 && sections.map((item) => (
								<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
							))}
						</Select>
					);
				}
			},
		},
		{
			title: 'Credits',
			dataIndex: 'course_credit',
			key: 'credits',
			width: collapseItemData[3]?.is_course_assigned === 0 ? "10%" : "7%                                        ",
			hidden: false,
			render: (credits) => <Flex className="table-number-align numbers-alignment">{credits}</Flex>
		},
		{
			title: constants.SemesterSchedule,
			dataIndex: 'semesterSchedule',
			key: 'semester-schedule',
			width: collapseItemData[3]?.is_course_assigned === 0 ? "37%" : "30%",
			hidden: false,
			children: [
				{
					title: 'Class',
					dataIndex: 'class',
					key: 'class',
					width: '10%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let yearIndex = constants.YearList.findIndex((value) => value.value === String(val?.year_scheduled));
							if (yearIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.YearList[yearIndex].label}</span>
							}
						} else {
							const studentYearList = yearInProgramList/* constants.YearList;*/
							// const studentYearList = getStudentYearList(val?.year_in_program);
							let yearIndex = studentYearList.findIndex((value) => value.value === String(val?.year_scheduled));
							const isValuePresent = studentYearList.some(item => item.value === String(val?.year_scheduled));
							return <Select
								className='w-100'
								showSearch
								allowClear
								placeholder={'Class'}
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								value={!isValuePresent ? constants.YearList[yearIndex]?.label : val?.year_scheduled ? String(val?.year_scheduled) : null}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "year_scheduled", 3)}
								status={handleErrorStatus(val, val?.year_scheduled)}
								filterOption={filterSelectOption}
							>{studentYearList.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}</Select>
						}
						//year_in_program=6->part time user.
						// if (val?.year_in_program === "6") {
						// 	return <span className='pl-2'>{constants.PartTime}</span>
						// } else {
							
						// }
					},
				},
				{
					title: 'Semester',
					dataIndex: 'semester',
					key: 'semester',
					width: collapseItemData[3]?.is_course_assigned === 0 ? '17%' : '10%',
					render: (_, val) => {
						if (val?.is_approved === "1") {
							let semIndex = constants.StudentSemList.findIndex((value) => value.key === String(val?.semester_scheduled));
							if (semIndex > -1) {
								return <span className='pl-2 whitespace-nowrap'>{constants.StudentSemList[semIndex].value}</span>
							}
						} else {
							const semList = /*semesterList*/ constants.StudentSemList
							// const semList = val?.year_in_program === "6" ? constants.StudentSemList : getStudentSemList(val?.year_scheduled, val?.year_in_program, val?.current_semester);
							return <Select
								className='w-100'
								allowClear
								showSearch
								placeholder={'Semester'}
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
								value={val?.semester_scheduled ? String(val?.semester_scheduled) : null}
								onChange={(e) => onChangeCoreCurriculumRow(e, val, "semester_scheduled", 3)}
								status={handleErrorStatus(val, val?.semester_scheduled)}
								filterOption={filterSelectOption}
							>
								{val?.semester_dropdown?.map((val) => <Option key={val?.value} value={val?.key}>{val?.value}</Option>)}
							</Select>
						}
					},
				},
				{
					title: constants.Year,
					dataIndex: 'calendar_year_scheduled',
					key: 'year',
					width: '10%',
					render: (calendar_year_scheduled, val) => {
						if (val?.is_approved === "1") {
							return <span className='pl-2'>{calendar_year_scheduled}</span>
						} else {
							return <DatePicker
								value={calendar_year_scheduled ? dayjs(calendar_year_scheduled) : null}
								onChange={(_, dateString) => onChangeCoreCurriculumRow(dateString ? dayjs(dateString).format("YYYY") : null, val, "calendar_year_scheduled", 3)}
								format={"YYYY"}
								status={handleErrorStatus(val, val?.calendar_year_scheduled)}
								className='w-full'
								picker='year'
								disabled={val?.is_add || val?.is_approved === "1" ? true : false}
							/>
						}
					},
				},
			]
		},
		{
			title: constants.Notes,
			dataIndex: 'notes',
			key: 'notes',
			width: '5%',
			hidden: collapseItemData[3]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.notes) {
						return <div className='text-center'>
							<Button onClick={() => onClickNote(3, value?.notes, value)} type='link' icon={<FileTextOutlined />} />
						</div>
					}
				} else {
					return <div className='text-center'>
						{
							value?.is_add ? null :
								<Button onClick={() => onClickNote(3, value?.notes, value)} disabled={value?.is_add || value?.is_approved === "1" ? true : false} type='link' icon={value?.notes ? <FileTextOutlined /> : <FileOutlined />} />
						}
					</div>
				}
			}
		},
		{
			title: constants.Status,
			dataIndex: 'status',
			key: 'status',
			width: role === "0" ? '4%' : '10%',
			align: role === "0" ? 'center' : 'left',
			hidden: collapseItemData[3]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (role === "0") {
					if (value?.status) {
						return getStudentStatusIcon(String(value?.status));
					}
				} else {
					if (value?.is_approved === "1") {
						let statusIndex = constants.SlpGraduteStatus.findIndex((val) => val.value === String(value?.status));
						if (statusIndex > -1) {
							return <span className='pl-2 whitespace-nowrap'>{constants.SlpGraduteStatus[statusIndex].label}</span>
						}
					} else {
						return <Select
							allowClear
							showSearch
							className='w-100'
							placeholder={constants.Status}
							disabled={value?.is_add || value?.is_approved === "1" ? true : false}
							value={value?.status ? String(value?.status) : null}
							onChange={(e) => onChangeCoreCurriculumRow(e, value, "status", 3)}
							status={handleErrorStatus(value, value?.status)}
							filterOption={filterSelectOption}
						>
							{constants.SlpGraduteStatus.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
						</Select>
					}
				}
			}
		},
		{
			title: constants.Grade,
			dataIndex: 'grade',
			key: 'grade',
			width: '7%',
			hidden: collapseItemData[3]?.is_course_assigned === 0 ? true : false,
			render: (_, value) => {
				if (value?.is_approved === "1" && value?.grade && !value?.is_edited) {
					let gradeIndex = constants.GradeDropDown.findIndex((val) => val.value === String(value?.grade));
					if (gradeIndex > -1) {
						return <span className='pl-2'>{constants.GradeDropDown[gradeIndex].label}</span>
					}
				} else {
					return <Select
						allowClear
						showSearch
						className='w-100'
						placeholder={constants.Grade}
						value={value?.grade ? value?.grade : null}
						onChange={(e) => onChangeCoreCurriculumRow(e, value, "grade", 3)}
						disabled={value?.is_add ? true : false}
						status={handleErrorStatus(value, value?.grade)}
						filterOption={filterSelectOption}
					>
						{constants.GradeDropDown.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
					</Select>
				}
			},
		}
	].filter((val) => !val?.hidden);

	const assessmentsColumns = [
		{
			title: "",
			dataIndex: 'approve/notapprove',
			key: 'approve/notapprove',
			width: "5%",
			align: "center",
			render: (_, val) => {
				if (role === "0") {
					if (val.is_approved === "1") {
						return <CheckOutlined className='green-text h5 mb-0 mt-1' />;
					}
				} else {
					let score = parseInt(val?.score);
					let passingScore = parseInt(val?.passing_score);
					if (score >= passingScore) {
						return likeDisLikeComponent(val);
					}
				}
			},
			hidden: collapseItemData[4]?.show_first_column ? false : true
		},
		{
			title: 'Assessment (Test Code #)',
			dataIndex: 'assessment',
			key: 'assessment',
			hidden: false,
			width: "30%"
		},
		{
			title: 'Date Taken',
			dataIndex: 'date_taken',
			key: 'date_taken',
			width: "20%",
			hidden: false,
			render: (date, val) => {
				if (date && !val?.is_edited && val?.is_approved !== "2") {
					return date ? <span className='ml-3'>{moment(date).format(constants.DateFormat)}</span> : "";
				} else {
					return <DatePicker
						value={date !== null ? dayjs(date) : null}
						disabledDate={disabledDate}
						onChange={(_, dateString) => onChangeCoreCurriculumRow(dateString ? dayjs(dateString).format(constants.DateFormat) : null, val, "date_taken", 4)}
						format={constants.DateFormat}
						className='w-full'
						status={handleErrorStatus(val, val?.date_taken)}
					/>
				}

			}
		},
		{
			title: 'Passing Score',
			dataIndex: 'passing_score',
			key: 'passing_score',
			width: "10%",
			hidden: false,
			render: (passing_score) => <Flex className="table-number-align numbers-alignment">{passing_score}</Flex>
		},
		{
			title: 'Score',
			dataIndex: 'score',
			key: 'Score',
			width: "15%",
			hidden: false,
			render: (score, val) => {
				if (score && !val?.is_edited && val?.is_approved !== "2") {
					return <Flex className="table-number-align numbers-alignment">{score}</Flex>
				} else {
					return <InputNumber status={handleErrorStatus(val, score)} value={score ? score : null} className='w-full' placeholder='Score' onChange={(e) => onChangeCoreCurriculumRow(e, val, "score", 4)} min={1} />
				}

			}
		},
		{
			// title: role === "0" ? "Upload Document (pdf)":'Download Document',
			title: 'Document (pdf)',
			dataIndex: 'document',
			key: 'doc',
			width: "20%",
			align: "center",
			hidden: false,
			render: (doc, val) => {
				if (doc && !val?.is_edited && val?.is_approved !== "2") {
					if (role === "0") {
						return <span>{doc}</span>
					}
					return <Tooltip title={doc}><DownloadOutlined disabled={loading} onClick={() => downloadPdf(`/download-assessment-document/${val?.id}`, "get", null, doc, "staff")} /></Tooltip>
				} else {
					let checkStatus = handleErrorStatus(val, val?.document);
					// //function to handle file's change.
					const handleFilesChange = (info) => {
						if (info?.fileList) {
							let checkBeforeUpload = beforeAssesmentFileUpload(info?.file, false);
							if (checkBeforeUpload) {
								onChangeCoreCurriculumRow(info?.fileList?.length > 0 ? info : null, val, "document", 4)
							}
						}
					};
					return <div className='upload-list-mb-0'><FileUpload
						renderContent={(val?.is_approved === "2" && !val?.document?.fileList) || (val?.is_approved === "2" && doc?.fileList?.length === 0) ? <Button danger>{constants.ChangeFile}</Button> : (val?.is_approved !== "2" && !val?.document?.fileList) ? <Button danger={checkStatus === "error" ? true : false} icon={<UploadOutlined />}>{constants.ChooseFile}</Button> : null}
						name='file'
						listType='text'
						className=''
						showUploadList={true}
						isMultiple={false}
						beforeUpload={beforeAssesmentFileUpload}
						fileCount={1}
						onChange={handleFilesChange}
						accept={"application/pdf"}
						defaultFileList={(val?.document?.fileList?.length > 0) /* || (doc) */ ? (doc?.fileList) /* || [{name:doc,id:val?.id}] */ : []}
					/>
						{(val?.is_approved === "2" && !val?.document?.fileList) || (val?.is_approved === "2" && doc === null) ? <Tooltip title={doc}><div className='w-72 truncate'>{doc}</div></Tooltip> : null}
					</div>
				}
			}
		}
	].filter((val) => !val?.hidden);

	const tableColumns = [coreCurriculumColumns, undergraduateColumns, freeElectivesColumns, graduateColumns, assessmentsColumns];

	const likeDisLikeComponent = (val) => {
		let approvedTooltipValue;
		let notApprovedTooltipValue;
		let approvedIcon;
		let notApprovedIcon;

		if (val?.is_approved === null) {
			approvedTooltipValue = constants.Approve;
			notApprovedTooltipValue = constants.NotApprove;
			approvedIcon = <LikeOutlined onClick={() => handleApproveNotApprove(val?.id, "1")} />;
			notApprovedIcon = <DislikeOutlined onClick={() => handleApproveNotApprove(val?.id, "2")} />;
		} else if (val?.is_approved === "1") {
			approvedTooltipValue = constants.Approved;
			notApprovedTooltipValue = constants.NotApprove;
			approvedIcon = <LikeFilled />;
			notApprovedIcon = <DislikeOutlined onClick={() => handleApproveNotApprove(val?.id, "2")} />;
		} else if (val?.is_approved === "2") {
			approvedTooltipValue = constants.Approve;
			notApprovedTooltipValue = constants.NotApproved;
			approvedIcon = <LikeOutlined onClick={() => handleApproveNotApprove(val?.id, "1")} />;
			notApprovedIcon = <DislikeFilled />;
		}
		return (<Space>
			<Tooltip title={approvedTooltipValue}>
				<span className='green-text mb-0 mt-1'>
					{approvedIcon}
				</span>
			</Tooltip>
			<Tooltip title={notApprovedTooltipValue}>
				<span className='text-red-600 mb-0 mt-1'>
					{notApprovedIcon}
				</span>
			</Tooltip>
		</Space>)
	};

	const handleApproveNotApprove = (id, is_approved) => {
		setIsApproveModalOpen(!isApproveModalOpen);
		setApprovedValue({
			id: id,
			approved_type: is_approved
		})
	};

	const handleApproveNotApproveOk = () => {
		callApproveNotApproveApi(approvedValue.id, approvedValue.approved_type, true);
	};

	const callApproveNotApproveApi = (id, is_approved, showMsg) => {
		let payload = {
			id: id,
			is_approved: is_approved,
			approved_by: getCookie(COOKIE.UserId)
		}
		fetchStaffApi(`/approve-assessment`, 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setLoading(false);
				getStudentCourses();
				setIsApproveModalOpen(false);
				if (showMsg) {
					message.success(res?.message);
				}
			} else {
				setIsApproveModalOpen(false);
				message.error(res?.message);
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		['scroll1', 'scroll2', 'scroll3', 'scroll4'].forEach(id =>
			document.getElementById(id)?.scrollIntoView({ behavior: "smooth" })
		);
	}, [reload]);

	const beforeAssesmentFileUpload = (file, showMessage = true) => {
		const isPDF = file.type === 'application/pdf';
		if (!isPDF) {
			message.error(`${file.name} is not a pdf file`);
			return false;
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			if (showMessage) {
				message.error(constants.MaxFileSizeMessage);
			}
			return false;
		}
		return true || Upload.LIST_IGNORE;
	};

	const disabledDate = (current) => {
		// Can not select days after today
		return current && current > dayjs().endOf('day');
	};

	const getStudentSemList = (courseYear, current_year_in_program, current_sem) => {
		let studentSemList;
		if (String(courseYear) === String(current_year_in_program)) {
			studentSemList = constants.StudentSemList.filter((val) => val?.key >= current_sem);
		} else {
			studentSemList = constants.StudentSemList;
		}
		return studentSemList;
	};
	
	const onChangeCoreCurriculumRow = useCallback((input_val, value, column_name, panelIndex) => {
		setCollapseItemData(prevData => {
			const updatedData = [...prevData];
			if (updatedData[panelIndex]?.course_data?.length > 0) {
				const rowIndex = panelIndex === 0 && !value.id
					? updatedData[panelIndex]?.course_data?.findIndex(val => val?.uniqueid === value?.uniqueid) :
					//getting assesment table row index based on assesment id. 
					panelIndex === 4 && value.assessment && value.id === null && !value.uniqueid ?
						updatedData[panelIndex]?.course_data?.findIndex(val => val?.assessment_id === value?.assessment_id) :
						panelIndex === 4 && value.assessment && value.id ?
							updatedData[panelIndex]?.course_data?.findIndex(val => val?.id === value?.id)
							: panelIndex === 4 && value.assessment && value.uniqueid ?
								updatedData[panelIndex]?.course_data?.findIndex(val => val?.uniqueid === value?.uniqueid)
								: updatedData[panelIndex]?.course_data?.findIndex(val => val?.id === value?.id);
				if (rowIndex !== -1) {
					if(column_name === 'course_year' || column_name === 'year_scheduled') {
						let year = updatedData[panelIndex].course_data[rowIndex]?.course_year
						|| updatedData[panelIndex].course_data[rowIndex]?.year_scheduled
						if(parseInt(year) === parseInt(input_val)) return
						
						let courseType = parseInt(updatedData[panelIndex].course_data[rowIndex].course_type)
						if(courseType === 0 || courseType === 2) {
							updatedData[panelIndex].course_data[rowIndex].course_semester = null
						} else {
							updatedData[panelIndex].course_data[rowIndex].semester_scheduled = null
						}
						updatedData[panelIndex].course_data[rowIndex].semester_dropdown = getSemesterDropdown(input_val, yearSemList)						
					}
					updatedData[panelIndex].course_data[rowIndex][column_name] = typeof input_val === 'string' && input_val?.charAt(0) === ' ' ? '' : input_val;
					updatedData[panelIndex].course_data[rowIndex].is_edited = true;
				}
			}
			return updatedData;
		});
	}, [setCollapseItemData, yearSemList]);

	const onSelectChange = (newSelectedRowKey, rowData) => {
		setSelectedRowKey(newSelectedRowKey);
		setselectedRowData(rowData);
	};

	const rowSelection = {
		type: 'checkbox',
		hideSelectAll: true,
		columnWidth: '3%',
		onChange: onSelectChange,
		selectedRowKeys: selectedRowKey,
		getCheckboxProps: (record) => ({
			style: {
				display: isDisplayCheckBox(record)  // Hide checkbox if area exists, otherwise show
			}
		}),
		renderCell(_, record, index, node) {
			if (record.is_approved === "1") {
				return <CheckOutlined className='green-text h5 mb-0 mt-1' />;
			}
			return node;
		},
	};

	const isDisplayCheckBox = (record) => {
		if (record.id && record?.is_approved !== "1") {
			return 'inline-flex';
		} else {
			return 'none';
		}
	};
	//By using deepClone, you ensure collapseItemData and apiData do not share references. This prevents unintended side effects.
	const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
	const getStudentCourses = (is_core_area = null) => {
		setLoading(true);
		let payload = {
			student_id: student_id,
			user_type: role === "0" ? "student" : "staff"
		}
		fetchApi(`/get-student-courses`, 'POST', payload).then((res) => {
			if (res?.code === "200") {
				let yearList = []
				let yearSemList = res?.data?.[0]?.year_sem_list
				setYearSemList(yearSemList)
				if(yearSemList?.length > 0) {
					yearSemList?.forEach((item) => {
						let yearObj = constants.YearList?.find((i) => parseInt(i.value) === item?.year_in_program)
						if(yearObj) yearList.push(yearObj)
					})
					setYearInProgramList(yearList)
				}

				let filteredData = handleTableData(res?.data, yearSemList);
				setCollapseItemData(filteredData);
				// setApiData(filteredData);
				setCollapseItemData(deepClone(filteredData)); // Set collapseItemData with a deep clone
				setApiData(deepClone(filteredData));
				if (res?.message === "Approve Semester") {
					setIsCourseNotApproved(true);
					setIsDisableExportBtn(true);
				}
				if (is_core_area) {
					handleAddRow(0, "Core Curriculum", filteredData);
				}
				let checkIsExportBtnDisable = res?.data?.filter((val) => val?.course_title !== "Assessments").some((val) => val?.is_course_assigned === 1);
				if (!checkIsExportBtnDisable) {
					setIsDisableExportBtn(true);
				} else {
					setIsDisableExportBtn(false);
				}
			} else {
				setCollapseItemData([]);
				setIsDisableExportBtn(true);
				message.error(res?.message);
			}
			setLoading(false);
		}).catch((err) => {
			setLoading(false);
		});
	};

	// const viewSemCourses = () => {
	// 	navigate(`/student-information`, {
	// 		state: {
	// 			student_id: student_id,
	// 			tabkey: "semester-courses",
	// 			tab_title: constants.SemesterCourses,
	// 			from: "Student Course List",
	// 			from_route: "/student-course-list",
	// 			student_name: student_name,
	// 			sem_id: sem_id
	// 		}
	// 	});
	// };
	useEffect(() => {
		getStudentCourses();
	}, []);

	const getSemesterDropdown = (year, yearSemList) => {
		let semesterFindList = yearSemList?.find((i) => parseInt(i.year_in_program) === parseInt(year))?.semester
		let semesterList = semesterFindList?.map((i) => {
			return {
				key: i?.toString(),
				value: constants.StudentSemList?.find((it) => parseInt(it.key) === parseInt(i))?.value
			}
		})
		if(semesterList?.length > 0) return semesterList
		return []
	}

	const handleTableData = (data, yearSemList) => {
		const newData = data?.map(({ course_title, course_data, ...rest }) => {
			if (course_title === "Core Curriculum" && course_data !== null) {
				const newCourseData = course_data?.flatMap(course => {
					course.semester_dropdown = getSemesterDropdown(course.course_year, yearSemList)
					if (course.is_processed) {
						return [course]; // Return the course as it is if already processed
					}
					// Mark the course as processed
					course.is_processed = true;
					const copies = [course]; // Add the original object first
					// Add additional copies based on total_no_courses
					if (course?.area !== "Core" && course.total_no_courses > 1) {
						for (let i = 1; i < course.total_no_courses; i++) {
							const copy = { ...course }; // Create a shallow copy of the original object
							let asha_required_courses = parseInt(copy?.asha_required_courses);
							let obj = {
								asha_course_name: copy?.asha_course_name,
								is_hidden: copy?.is_hidden,
								dt_created: copy?.dt_created,
								dt_updated: copy?.dt_updated,
								is_approved: copy?.is_approved,
								course_text: "",
								credits: "",
								course_year: "",
								course_semester: "",
								calendar_year_scheduled: null,
								status: null,
								grade: "",
								uniqueid: `${copy.id}_${i}`,
								original_id: copy?.id,
								area_name: copy?.area,
								year_in_program: copy?.year_in_program,
								current_semester: copy?.current_semester,
								is_processed: true // Mark the copy as processed
							}
							//passing asha_required_courses to display placeholder.
							if (i < asha_required_courses) {
								obj.asha_required_courses = copy?.asha_required_courses
							}
							copies.push(obj);
						}
					}
					return copies;
				});
				return {	
					course_title, 
					course_data: newCourseData, 
					...rest 
				};
			}
			else if (course_title === "Assessments" && course_data !== null) {
				let courseData = course_data
				//to add a object if score is less than passing score
				const groupedData = courseData?.reduce((acc, obj) => {
					const key = obj.assessment_id;
					if (!acc[key]) {
						acc[key] = [];
					}
					acc[key].push(obj);
					return acc;
				}, {});

				const additionalObjects = [];
				Object?.values(groupedData).forEach((objs, index) => {
					// Sort objs in descending order based on the 'attempts' property
					objs.sort((a, b) => b.attempts - a.attempts);

					// Taking the object with the max attempt in the group
					const selectedObj = objs[0];
					// Check if the score is less than the passing score
					if (parseFloat(selectedObj.score) < parseFloat(selectedObj.passing_score)) {
						// Add new object
						additionalObjects.push({
							id: null,
							is_approved: null,
							assessment_id: selectedObj.assessment_id,
							assessment: selectedObj.assessment,
							scheduled_for: selectedObj.scheduled_for,
							date_taken: null,
							passing_score: selectedObj.passing_score,
							score: null,
							document: "",
							approved_by: null,
							approved_by_name: "",
							attempts: selectedObj.attempts + 1,
							uniqueid: `${selectedObj?.assessment_id}_${index}`
						});
					}
				});

				// Combine the original data with the additional objects
				additionalObjects?.sort((a, b) => a?.assessment.localeCompare(b?.assessment));
				const updatedCourseData = courseData.concat(additionalObjects);
				//to display 1st column for assesment table student and faculty side.
				let showFirstColumn;
				if (role === "0") {
					const showColumn = courseData.some((val) => val.is_approved === "1");
					showFirstColumn = showColumn
				} else {
					const showColumn = courseData.some((val) => parseInt(val?.score) >= parseInt(val?.passing_score));
					showFirstColumn = showColumn
				}
				return { course_title, course_data: updatedCourseData, show_first_column: showFirstColumn, ...rest };
			}
			else {
				return { 
					course_data: course_data?.map((it) => {
						let year = null
						if(it.course_type === 0 || it.course_type === 2) {
							year = it.course_year
						} else {
							year = it.year_scheduled
						}
						return {
							...it,
							semester_dropdown: getSemesterDropdown(year, yearSemList)
						}
					}),
					course_title, 
					...rest 
				};
			}
		});
		return newData;
	};

	const handleAddRow = (panelIndex, title, filteredData = null) => {
		if (title === "Core Curriculum") {
			const newData = {
				id: "",
				course_name: "",
				course_text: "",
				course_credit: "",
				course_year: "",
				course_semester: "",
				is_approved: null,
				calendar_year_scheduled: null,
				status: null,
				section_number: null,
				course_type: panelIndex,
				notes: "",
				grade: "",
				current_year: "",
				area: "",
				is_add: true,
				asha_required_courses: "",
				total_no_courses: "",
				year_in_program: "",
				current_semester: ""
			};
			handleUpdatedRowData(panelIndex, newData, filteredData);
		}
		else if (title === "SLP Undergraduate Requirement") {
			const newData = {
				id: "",
				course_code: "",
				asha_course: "",
				course_text: "",
				course_name: "",
				course_credit: "",
				course_type: panelIndex,
				number_of_sections: "",
				is_add: true,
				status: null,
				grade: "",
				year_in_program: "",
				current_semester: ""
			}
			handleUpdatedRowData(panelIndex, newData);
		}
		else if (title === "Free Electives") {
			const newData = {
				id: "",
				course_code: "",
				asha_course: "",
				course_text: "",
				course_name: "",
				course_credit: "",
				course_type: panelIndex,
				number_of_sections: "",
				is_add: true,
				other_course_credit: 4,
				status: null,
				grade: "",
				year_in_program: "",
				current_semester: ""
			}
			handleUpdatedRowData(panelIndex, newData);
		}
		else if (title === "SLP Graduate Requirement") {
			const newData = {
				id: "",
				course_code: "",
				course_text: "",
				course_name: "",
				course_credit: "",
				course_type: panelIndex,
				number_of_sections: "",
				is_add: true,
				status: null,
				grade: "",
				year_in_program: "",
				current_semester: ""
			}
			handleUpdatedRowData(panelIndex, newData);
		}
		setReload(!reload);
	};

	const handleUpdatedRowData = (panelIndex, newData, filteredData = null) => {
		const updatedData = filteredData !== null ? [...filteredData] : [...collapseItemData];
		// Create a shallow copy of course_table_data array before modifying it
		updatedData[panelIndex].course_data = [
			...updatedData[panelIndex].course_data,
			newData,
		];
		// Update the state with the new courseData
		setCollapseItemData(updatedData);
		getSelectOptionCourseType(panelIndex, updatedData[panelIndex].is_course_assigned);
	};

	//to get the select options based on course type.
	const getSelectOptionCourseType = (course_type, isCourseAssigned) => {
		setCourseAssigned(isCourseAssigned)
		let payload = {
			student_id: /* role==="0"?studentId: */student_id,
			course_type: course_type
		}
		setLoading(true);
		fetchApi(`/get-course-curriculum-areas`, 'POST', payload).then((res) => {
			if (res?.code === "200") {
				// Extracting valid IDs from the first array, ignoring empty strings
				const idsToRemove = collapseItemData[course_type]?.course_data
					?.filter(obj => obj.id !== "")
					?.map(obj => obj.id);
				// Filtering the second array
				let filteredArray = res?.data?.filter(obj => !idsToRemove.includes(obj.id));
				//handle free elective course
				if (course_type === 2 && isCourseAssigned === 0) {
					//removing all course except other only when it is 1st time.
					let filterData = filteredArray?.filter((val) => val?.id === -1);
					setCourseCurriculumList(filterData);
				} else {
					setCourseCurriculumList(filteredArray);
				};
				setLoading(false);
			} else {
				setCourseCurriculumList([]);
				setLoading(false);
				message.error(res?.message);
			}
		});
	};

	const handleChangeCourseCurriculumList = (id, panelIndex) => {
		// Create a shallow copy of course_table_data
		const updatedData = [...collapseItemData];
		//last added value index
		let c_data_index = updatedData?.[panelIndex]?.course_data?.length - 1;
		if (c_data_index > 0) {
			let courseIndex = courseCurriculumList.findIndex((val) => val?.id === id);	
			if (courseIndex > -1) {
				//core curriculum corses
				if (panelIndex === 0) {
					updatedData[panelIndex].course_data[c_data_index].is_add = false;
					updatedData[panelIndex].course_data[c_data_index].area = courseCurriculumList[courseIndex]?.area;
					updatedData[panelIndex].course_data[c_data_index].asha_required_courses = courseCurriculumList[courseIndex]?.asha_required_courses;
					updatedData[panelIndex].course_data[c_data_index].total_no_courses = courseCurriculumList[courseIndex]?.total_no_courses;
					updatedData[panelIndex].course_data[c_data_index].id = courseCurriculumList[courseIndex]?.id;
					updatedData[panelIndex].course_data[c_data_index].year_in_program = courseCurriculumList[courseIndex]?.year_in_program;
					updatedData[panelIndex].course_data[c_data_index].current_semester = courseCurriculumList[courseIndex]?.current_semester;
				}
				//SLP Undergradutes corses
				else if (panelIndex === 1) {
					updatedData[panelIndex].course_data[c_data_index].is_add = false;
					updatedData[panelIndex].course_data[c_data_index].course_code = courseCurriculumList[courseIndex]?.course_code;
					updatedData[panelIndex].course_data[c_data_index].course_text = courseCurriculumList[courseIndex]?.course_text;
					updatedData[panelIndex].course_data[c_data_index].number_of_sections = courseCurriculumList[courseIndex]?.number_of_sections;
					updatedData[panelIndex].course_data[c_data_index].course_credit = courseCurriculumList[courseIndex]?.course_credit;
					updatedData[panelIndex].course_data[c_data_index].id = courseCurriculumList[courseIndex]?.id;
					updatedData[panelIndex].course_data[c_data_index].year_in_program = courseCurriculumList[courseIndex]?.year_in_program;
					updatedData[panelIndex].course_data[c_data_index].current_semester = courseCurriculumList[courseIndex]?.current_semester;
				}
				//Free Elective corses
				else if (panelIndex === 2) {
					updatedData[panelIndex].course_data[c_data_index].is_add = false;
					updatedData[panelIndex].course_data[c_data_index].course_code = courseCurriculumList[courseIndex]?.course_code;
					updatedData[panelIndex].course_data[c_data_index].course_text = courseCurriculumList[courseIndex]?.course_text;
					// updatedData[panelIndex].course_data[c_data_index].number_of_sections = courseCurriculumList[courseIndex]?.number_of_sections;
					updatedData[panelIndex].course_data[c_data_index].course_credit = courseCurriculumList[courseIndex]?.course_credit;
					updatedData[panelIndex].course_data[c_data_index].id = courseCurriculumList[courseIndex]?.id;
					updatedData[panelIndex].course_data[c_data_index].year_in_program = courseCurriculumList[courseIndex]?.year_in_program;
					updatedData[panelIndex].course_data[c_data_index].current_semester = courseCurriculumList[courseIndex]?.current_semester;
					updatedData[panelIndex].course_data[c_data_index].number_of_sections = courseCurriculumList[courseIndex]?.number_of_sections;
				}
				//SLP Graduate Requirement corses
				else if (panelIndex === 3) {
					updatedData[panelIndex].course_data[c_data_index].is_add = false;
					updatedData[panelIndex].course_data[c_data_index].course_code = courseCurriculumList[courseIndex]?.course_code;
					updatedData[panelIndex].course_data[c_data_index].course_text = courseCurriculumList[courseIndex]?.course_text;
					updatedData[panelIndex].course_data[c_data_index].number_of_sections = courseCurriculumList[courseIndex]?.number_of_sections;
					updatedData[panelIndex].course_data[c_data_index].course_credit = courseCurriculumList[courseIndex]?.course_credit;
					updatedData[panelIndex].course_data[c_data_index].id = courseCurriculumList[courseIndex]?.id;
					updatedData[panelIndex].course_data[c_data_index].year_in_program = courseCurriculumList[courseIndex]?.year_in_program;
					updatedData[panelIndex].course_data[c_data_index].current_semester = courseCurriculumList[courseIndex]?.current_semester;
				}
			}
			const updatedCourseCurriculumList = courseCurriculumList.filter(item => item.id !== id);
			setCourseCurriculumList(updatedCourseCurriculumList);
			let filteredData = handleTableData(updatedData, yearSemList);
			setCollapseItemData(filteredData);
		}
	};

	const onClickNote = (index, noteValue, value) => {
		setIsNoteModalOpen(true);
		let noteIndex = collapseItemData[index]?.course_data?.findIndex((val) => val?.id === value?.id);
		if (noteIndex > -1) {
			setNoteIndex({
				note_index: noteIndex,
				corse_index: index
			});
		};
		if (noteValue) {
			if (role === "0") {
				setNoteTitle("View Note");
			} else {
				setNoteTitle("Edit Note");
			}
			setNoteValue(noteValue);
		} else {
			setNoteTitle("Add Note");
			setNoteValue("");
		}
	};

	const handleNote = () => {
		const updatedData = [...collapseItemData];
		updatedData[noteIndex.corse_index].course_data[noteIndex.note_index].notes = noteValue;
		updatedData[noteIndex.corse_index].course_data[noteIndex.note_index].is_edited = true;
		setCollapseItemData(updatedData);
		setIsNoteModalOpen(false);
	};

	const handleCancel = (type) => {
		if (type === "note") {
			setNoteValue(null);
			setIsNoteModalOpen(false);
			setNoteIndex({
				note_index: null,
				corse_index: null
			});
		} else if (type === "coursemodel") {
			setIsRemoveCourseModalOpen(false);
		}
		else {
			setApprovedValue({
				id: null,
				approved_type: null
			})
			setIsApproveModalOpen(false);
		}
	};

	const onChangeNote = (e) => {
		setNoteValue(e.target.value);
	};

	const handleIsDisableAddBtn = (is_course_assigned, course_title, course_data) => {
		if (course_title === "Core Curriculum" || course_title === "SLP Undergraduate Requirement" || course_title === "Free Electives" || course_title === "SLP Graduate Requirement") {
			let courseIndex = course_data?.findIndex((val) => val?.is_add === true);
			if (is_course_assigned === 0) {
				//to unable add button for Free Electives course to add other course.
				if (course_title === "Free Electives" && courseIndex === -1) {
					return false;
				} else {
					return true;
				}
			} else if (courseIndex > -1) {
				return true;
			} else {
				return false;
			}
		}
	};

	const handleIsDisableRemoveBtn = (courseAssigned, courseTitle) => {
		if (selectedRowKey.length >= 1 && courseAssigned !== 0) {
			return false;
		} else if (courseAssigned === 0) {
			if (courseTitle === "Free Electives" && selectedRowData[0]?.other_course_credit) {
				return false;
			} else {
				return true;
			}
		} else {
			if (selectedRowKey.length >= 1) {
				return false;
			} else {
				return true;
			}
		}
	};

	const handleSaveCourse = (panelValue, panelIndex) => {
		let someFieldsFilled = false;
		let courseData = panelValue?.course_data?.filter((val) => val?.grade !== null || val?.is_approved !== "1");
		courseData?.forEach(obj => {
			//check if these fields have data.
			let fields;
			if (panelIndex === 0) {
				fields = ["course_text", "course_credit", "course_semester", "calendar_year_scheduled"];
				if (obj?.year_in_program !== "6") {
					fields.push("course_year");
				}
			} else if (panelIndex === 1) {
				fields = ["section_number", "semester_scheduled", "calendar_year_scheduled"];
				if (obj?.year_in_program !== "6") {
					fields.push("year_scheduled");
				}
			} else if (panelIndex === 2) {
				fields = [
					"course_semester", 
					"calendar_year_scheduled", 
				];

				if(obj?.id === -1) {
					fields.push("course_code", "course_name", "course_credit");
				} else {
					fields.push("section_number");
				}

				if (obj?.year_in_program !== "6") {
					fields.push("course_year");
				}
			} else if (panelIndex === 3) {
				fields = ["section_number", "semester_scheduled", "calendar_year_scheduled"];
				if (obj?.year_in_program !== "6") {
					fields.push("year_scheduled");
				}
			} else if (panelIndex === 4) {
				fields = ["date_taken", "score", "document"];
			}

			let allFilled = fields.every(field => obj[field]);
			let someFilled = fields.some(field => obj[field]);
			
			if (someFilled && !allFilled) {
				someFieldsFilled = true;
			}
			if (someFilled) {
				obj.not_filled = !allFilled;
			}
		});
		if (someFieldsFilled === false) {
			let userCourseData;
			if (panelIndex === 0) {
				userCourseData = courseData
					?.filter(obj => {
						if (obj?.year_in_program === "6") {
							//filtering for part time user.
							return obj.course_text && obj.course_semester && obj.calendar_year_scheduled && obj.course_credit && obj.is_edited;
						}
						return obj.course_text && obj.course_year && obj.course_semester && obj.calendar_year_scheduled && obj.course_credit && obj.is_edited;
					})
					?.map(obj => ({
						area_id: obj?.id && obj?.area_id ? obj?.area_id : obj?.original_id ? obj?.original_id : obj?.id,
						id: obj?.id && obj?.area_id ? obj?.id : null,//this will be not null when you edit.
						course_name: parseInt(obj?.asha_required_courses) > 0 ? `${obj.course_text} (ASHA)` : obj.course_text,
						course_year: /*obj?.year_in_program === "6" ? obj.year_in_program : */obj?.course_year,
						area: obj?.area ? obj?.area : obj?.area_name,
						course_semester: obj.course_semester,
						calendar_year_scheduled: obj.calendar_year_scheduled,
						course_credit: obj.course_credit,
						status: obj?.status ? obj?.status : null,
						course_note: obj?.notes ? obj?.notes : null,
						grade: obj?.grade ? obj?.grade : null,
					}));
			} else if (panelIndex === 1) {
				userCourseData = courseData
					?.filter(obj => {
						if (obj?.year_in_program === "6") {
							return obj?.section_number && obj?.semester_scheduled && obj?.calendar_year_scheduled && obj.is_edited
						}
						return obj?.section_number && obj?.year_scheduled && obj?.semester_scheduled && obj?.calendar_year_scheduled && obj.is_edited
					})
					?.map(obj => ({
						id: obj?.id && obj?.course_id ? obj?.id : null,
						year_scheduled: /*obj?.year_in_program === "6" ? obj?.year_in_program : */obj?.year_scheduled,
						semester_scheduled: obj?.semester_scheduled,
						section_number: obj?.section_number,
						course_id: obj?.id && obj?.course_id ? obj?.course_id : obj?.id,
						calendar_year_scheduled: obj?.calendar_year_scheduled,
						status: obj?.status ? obj?.status : null,
						course_note: obj?.notes ? obj?.notes : null,
						grade: obj?.grade ? obj?.grade : null
					}));
			} else if (panelIndex === 2) {
				userCourseData = courseData
					?.filter(obj => {
						if (obj?.year_in_program === "6") {
							return obj.course_semester && obj.calendar_year_scheduled && obj.course_credit && obj.is_edited
						}
						return obj.course_year && obj.course_semester && obj.calendar_year_scheduled && obj.course_credit && obj.is_edited
					})
					?.map(obj => ({
						// course_id:obj?.id && obj?.course_id ?obj?.course_id : obj?.id,
						id: obj?.id && (obj?.course_id || obj?.is_core_course === 2) ? obj?.id : null,//this will be not null when you edit.
						course_year: /*obj?.year_in_program === "6" ? obj.year_in_program : */obj?.course_year,
						course_semester: obj.course_semester,
						calendar_year_scheduled: obj.calendar_year_scheduled,
						course_credit: obj.course_credit,
						status: obj?.status ? obj?.status : null,
						section_number: obj?.section_number,
						course_note: obj?.notes ? obj?.notes : null,
						grade: obj?.grade ? obj?.grade : null,
						course_code: obj?.course_code,
						course_name: obj?.course_text ? obj?.course_text : obj?.course_name,
						...(obj.id !== -1 ? { 
							course_id: obj?.is_core_course === 2 ? null : obj?.id && obj?.course_id ? obj?.course_id : obj?.id,
							is_core_course: obj?.is_core_course === 2 ? obj?.is_core_course : null,
						} : {}),
						...(obj.id === -1 ? { is_core_course: 2 } : {})
					}));
			} else if (panelIndex === 3) {
				userCourseData = courseData
					?.filter(obj => {
						if (obj?.year_in_program === "6") {
							return obj?.section_number && obj?.semester_scheduled && obj?.calendar_year_scheduled && obj.is_edited;
						}
						return obj?.section_number && obj?.year_scheduled && obj?.semester_scheduled && obj?.calendar_year_scheduled && obj.is_edited;
					})
					?.map(obj => ({
						id: obj?.id && obj?.course_id ? obj?.id : null,
						year_scheduled: /*obj?.year_in_program === "6" ? obj?.year_in_program : */obj?.year_scheduled,
						semester_scheduled: obj?.semester_scheduled,
						section_number: obj?.section_number,
						course_id: obj?.id && obj?.course_id ? obj?.course_id : obj?.id,
						calendar_year_scheduled: obj?.calendar_year_scheduled,
						status: obj?.status ? obj?.status : null,
						course_note: obj?.notes ? obj?.notes : null,
						grade: obj?.grade ? obj?.grade : null,
					}));
			} else if (panelIndex === 4) {
				setLoading(true);
				let formdata = createFormData(courseData, student_id);
				fetchStaffFormData(`/save-student-assessment`, 'POST', formdata, "common").then((res) => {
					if (res.code === "200") {
						setLoading(false);
						getStudentCourses();
						message.success(res?.message);
					} else {
						setLoading(false);
						message.error(res?.message);
					}
				});
			}
			if (userCourseData?.length > 0) {
				setLoading(true);
				let payload = {
					course_data: userCourseData,
					course_type: panelIndex,
					is_saved: 2,
					student_id: student_id,
				}
				// apicall
				fetchApi(`/save-course-details`, 'POST', payload).then((res) => {
					if (res?.code === "200") {
						getStudentCourses();
						message.success(res?.message);
					} else {
						message.error(res?.message);
					}
					setLoading(false);
				}).catch((err) => {
					setLoading(false);
				});
			}
		} else {
			const updatedData = [...collapseItemData];
			// Update the specific panel's course_data
			updatedData[panelIndex] = {
				...updatedData[panelIndex],
				course_data: panelValue?.course_data
			};
			// Set the new state
			setCollapseItemData(updatedData);
			//error message
			message.error("Kindly fill the highlighted fields");
		}
	};

	//creating form data for student passing assesment data.
	const createFormData = (assessments, studentId) => {
		const formData = new FormData();
		formData.append('student_id', studentId);
		formData.append('is_saved', 2); // Assuming `is_saved` is a constant value you need to set
		assessments.forEach((assessment, index) => {
			if (assessment.date_taken && assessment.score && assessment.document && assessment?.is_edited) {
				formData.append(`assessment_id[]`, assessment?.assessment_id);
				formData.append(`score[]`, assessment?.score);
				formData.append(`date_taken[]`, assessment?.date_taken);
				formData.append(`attempts[]`, assessment?.attempts);
				formData.append(`student_assessment_id[]`, assessment?.id !== null ? assessment?.id : "");
				callApproveNotApproveApi(assessment?.id, null, false)
				// Append file from the document
				if (assessment?.document?.file?.originFileObj) {
					formData.append(`assessment_file[]`, assessment?.document?.file?.originFileObj);
				}
			}
		});

		return formData;
	};

	const handleErrorStatus = (value, field_value) => {
		if (value?.not_filled === true) {
			if (field_value) {
				return ''
			} else {
				return 'error'
			}
		} else {
			return ''
		}
	};


	const handleDisableSave = (course_data, index) => {
		const array = course_data;
		// Key to be removed
		const keyToRemove = 'is_edited';
		// Function to remove the specified key from each object
		const removeKeyFromObject = (obj, key) => {
			const { [key]: _, ...rest } = obj;
			return rest;
		};
		// Create a new array with the specified key removed from each object
		const filteredArray = array.map(item => removeKeyFromObject(item, keyToRemove));

		let rowEditedIndex = course_data?.findIndex((val) => val?.is_edited === true);
		if (JSON.stringify(filteredArray) === JSON.stringify(apiData[index]?.course_data)) {
			return true;
		} else {
			if (rowEditedIndex > -1) {
				return false;
			} else if (loading) {
				return true;
			} else {
				return true;
			}
		}
	};

	const deleteStudentCourse = (course_type, course_ids, is_core_area = null) => {
		setLoading(true);
		let payload = {
			course_type: course_type,
			user_type: role === "0" ? "student" : "staff"
		}
		if (course_type === 2) {
			payload.course_data = course_ids
		} else {
			payload.id = course_ids
		}
		fetchApi(`/delete-student-course`, 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setLoading(false);
				getStudentCourses(is_core_area);
				setSelectedRowKey([]);
				getSelectOptionCourseType(course_type, courseAssigned)
				message.success(res?.message);
			} else {
				message.error(res?.message);
				setLoading(false);
			}
			setIsRemoveCourseModalOpen(false);
		});
	};

	const handleRemoveCourse = () => {
		if (selectedRowData.length > 0) {
			const result = selectedRowData?.reduce(
				(acc, val) => {
					if (val?.hasOwnProperty('is_add')) {
						acc.courseNotApprovedIds.push({ id: val.id, courseType: val.course_type });
					}
					else {
						//free elective course
						if (val?.course_type === 2) {
							acc.courseApprovedIds.push({ id: val.id, courseType: val.course_type, is_core_course: val?.hasOwnProperty("is_core_course") ? val?.is_core_course : 1 });
						}
						//core curriculum course
						else if (val?.course_type === 0) {
							acc.courseApprovedIds.push({ id: val.id, courseType: val.course_type, area: val?.area });
						}
						//other courses
						else {
							acc.courseApprovedIds.push({ id: val.id, courseType: val.course_type });
						}
					}
					return acc;
				},
				{ courseNotApprovedIds: [], courseApprovedIds: [] }
			);
			//notApproved ids will be filtering.
			const notApprovedIds = result.courseNotApprovedIds.map(item => item.id);
			const courseType = result.courseNotApprovedIds.map(item => item.courseType);
			//approved ids will be deleting from db.
			const approvedIds = result.courseApprovedIds.map(item => item.id);
			const apporvedCourseType = result.courseApprovedIds.map(item => item.courseType);
			if (notApprovedIds.length > 0) {
				let updatedData = [...collapseItemData];
				const filteredArray = updatedData[courseType[0]].course_data.filter(item => {
					// Check if the item.id or item.original_id is not in notApprovedIds array
					return !notApprovedIds.includes(item.id) && item?.hasOwnProperty('id')
						|| !notApprovedIds.includes(item.original_id) && item?.hasOwnProperty('original_id');
				});
				// Update the specific panel's course_data
				updatedData[courseType[0]] = {
					...updatedData[courseType[0]],
					course_data: filteredArray
				};
				// Set the new state
				setCollapseItemData(updatedData);
				setSelectedRowKey([]);
				setIsRemoveCourseModalOpen(false);
			}
			if (approvedIds.length > 0) {
				//api call
				if (apporvedCourseType[0] === 2) {
					//sending different payload for free elective course.
					let coursData = result?.courseApprovedIds?.map(({ courseType, ...rest }) => rest);
					deleteStudentCourse(apporvedCourseType[0], coursData);
				} else if (apporvedCourseType[0] === 0) {
					let is_core_area = result?.courseApprovedIds.some((val) => val?.area === "Core");
					deleteStudentCourse(apporvedCourseType[0], approvedIds, is_core_area);
				}
				else {
					deleteStudentCourse(apporvedCourseType[0], approvedIds);
				}
			}
		}
	};

	const downloadPdf = (url, method, payload, doc_name, type) => {
		setLoading(true);
		if (type === "staff") {
			fetchStaffDownloadApi(url, method, payload).then(
				(res) => {
					if (res) {
						setLoading(false);
						let url, a;
						url = window.URL.createObjectURL(
							new Blob([res], { type: "application/pdf" })
						);
						a = document.createElement("a");
						a.download = doc_name;
						a.href = url;
						a.dispatchEvent(new MouseEvent("click"));
					} else {
						setLoading(false);
						message.error("Failed to download");
					}
				}
			);
		} else {
			fetchDownloadApi(url, method, payload).then(
				(res) => {
					if (res) {
						setLoading(false);
						let url, a;
						url = window.URL.createObjectURL(
							new Blob([res], { type: "application/pdf" })
						);
						a = document.createElement("a");
						a.download = doc_name;
						a.href = url;
						a.dispatchEvent(new MouseEvent("click"));
					} else {
						setLoading(false);
						message.error("Failed to download");
					}
				}
			);
		}
	};

	const getRowClassName = (record) => {
		return record?.current_year === "1" ? 'advisor-row' : '';
	};

	const getAssementRowClassName = (record) => {
		const { score, passing_score, is_edited, is_approved } = record;

		if (is_approved === "2") {
			return 'border-red-500 bg-red-300';
		}

		if (is_approved === null && Number(score) > Number(passing_score)) {
			return 'bg-white';
		}

		if (score && !is_edited && is_approved !== "2" && Number(score) < Number(passing_score)) {
			return 'bg-red-100';
		}

		return '';
	};

	const getStudentStatusIcon = (status) => {
		let icon;
		switch (status) {
			case "1":
				icon = <Tooltip title={constants.Waived}><FontAwesomeIcon className='mb-0 mt-1 text-[#F7EED1] text-2xl stroke-black stroke-[15px]' icon={faRetweet} /></Tooltip>
				break;
			case "2":
				icon = <Tooltip title={constants.Transfer}><FontAwesomeIcon className='mb-0 mt-1 text-[#C8F3E0] text-2xl stroke-black stroke-[15px]' icon={faShareFromSquare} /></Tooltip>
				break;
			case "3":
				icon = <Tooltip title={constants.Recommended}><FontAwesomeIcon className='mb-0 mt-1 text-[#EBC2BD] text-2xl stroke-black stroke-[15px]' icon={faRegistered} /></Tooltip>
				break;
			default:
				icon = null
		}
		return icon
	};

	const breadcrumbItems = [{ label: constants.Courses, active: true },];

	const onChangeCollapseItem = (e) => {
		let index = e[0];
		if (index) {
			let updatedData = [...collapseItemData];
			const filteredArray = updatedData[index].course_data.filter(course => course.id !== "");
			// Update the specific panel's course_data
			updatedData[index] = {
				...updatedData[index],
				course_data: filteredArray
			};
			// Set the new state
			setCollapseItemData(updatedData);
		}
	};

	return (
		<Spin tip={constants.PleaseWait} spinning={loading}>
			<div>
				{
					role !== "0" ?
						<div className='d-flex justify-content-end'>
							<Button className='btn-info text-white' disabled={isDisableExportBtn} onClick={() => downloadPdf("/export-pdf-courses", "post", { student_id: student_id }, student_name + " " + constants.Courses, "public")}>Export PDF</Button>
						</div> :
						<BreadcrumbHeader pageTitle={constants.Courses} breadcrumbItems={breadcrumbItems} />
				}
				<div className='mt-3'>
					{/* {isCourseNotApproved && 
					<Alert 
						message={isStudent ? constants.CourseNotApprovedMessageStudent : constants.CourseNotApprovedMessageAdvisor} 
						type="error" 
						className='w-fit py-1.5 mt-2 mb-2 text-red-500 text-base' 
						showIcon 
					/>} */}
					<Collapse
						expandIconPosition="right"
						className='collapse-custom'
						size='small'
						accordion
						onChange={onChangeCollapseItem}
						defaultActiveKey={[0]}
						expandIcon={({ isActive }) => <DownCircleFilled className='text-white' rotate={isActive ? 180 : 0} />}
					>
						{
							collapseItemData.length > 0 ?
								collapseItemData?.map((item, index) => {
									return <Panel header={item?.course_title} key={index} className='collapse-custom mb-2'>
										{
											item?.course_data?.length > 0 ?
												<div>
												{/* <div className={isCourseNotApproved ? "read-only-mode" : ""}> */}
													{role === "0" && index === 0 ? <Alert message={constants.CoreCourseWarningMessage} type="warning" className='w-fit py-1.5 mt-2 mb-2 text-[#b97c03] text-base' showIcon /> : null}
													{
														item?.course_title !== "Assessments" && role === "0" ?
															<div className='flex justify-between items-center'>
																<strong className='whitespace-nowrap'>{constants.SelectStatus} : </strong>
																<Space className='w-full' size={[0, 8]} wrap split={<Divider className='border-slate-300' type="vertical" />}>
																	<Space className='ml-3'>
																		<FontAwesomeIcon className='mb-0 mt-1 text-[#EBC2BD] text-lg stroke-black stroke-[15px]' icon={faRegistered} />
																		{constants.Recommended}
																	</Space>
																	<Space className='ml-3'>
																		<FontAwesomeIcon className='mb-0 mt-1 text-[#C8F3E0] text-lg stroke-black stroke-[15px]' icon={faShareFromSquare} />
																		{constants.Transfer}
																	</Space>
																	<Space className='ml-3'>
																		<FontAwesomeIcon className='mb-0 mt-1 text-[#F7EED1] text-lg stroke-black stroke-[15px]' icon={faSquareArrowUpRight} />
																		{constants.Waived}
																	</Space>
																</Space>
																<Space>
																	<Button 
																		className='btn-info' 
																		disabled={item?.add_disabled || handleIsDisableAddBtn(item?.is_course_assigned, item?.course_title, item?.course_data)} 
																		onClick={() => handleAddRow(index, item?.course_title)}
																	>{constants.Add}
																	</Button>
																	<Button disabled={handleIsDisableRemoveBtn(item?.is_course_assigned, item?.course_title)} type='primary' danger onClick={() => setIsRemoveCourseModalOpen(!isRemoveCourseModalOpen)}>{constants.Remove}</Button>
																</Space>
															</div> :
															item?.course_title !== "Assessments" && (
																<Space className='d-flex justify-end'>
																	<Button 
																		className='btn-info' 
																		disabled={item?.add_disabled || handleIsDisableAddBtn(item?.is_course_assigned, item?.course_title, item?.course_data)} 
																		onClick={() => handleAddRow(index, item?.course_title)}
																		>{constants.Add}
																	</Button>
																	<Button disabled={handleIsDisableRemoveBtn(item?.is_course_assigned, item?.course_title)} type='primary' danger onClick={() => setIsRemoveCourseModalOpen(!isRemoveCourseModalOpen)}>{constants.Remove}</Button>
																</Space>
															)
													}
													{
														item?.course_data !== null ?
															item?.course_title === "Assessments" ?
																<div className='mt-3'>
																	<Table
																		className="records-table"
																		size="small"
																		bordered
																		dataSource={item?.course_data}
																		columns={tableColumns[index]}
																		pagination={false}
																		scroll={{ /* y: '50vh', */ x: 768 }}
																		rowClassName={(record) => getAssementRowClassName(record)}
																	/>
																	<div className='d-flex justify-content-end mt-3'>
																		<Button
																			className='btn-info'
																			disabled={loading || handleDisableSave(item?.course_data, index)}
																			onClick={() => handleSaveCourse(item, index)}>
																			{constants.Save}
																		</Button>
																	</div>
																</div> :
																<div className='mt-3'>
																	<Table
																		className="records-table"
																		size="small"
																		dataSource={item?.course_data}
																		columns={tableColumns[index]}
																		rowSelection={rowSelection}
																		pagination={false}
																		scroll={{ /* y: '50vh', */ x: 768 }}
																		rowKey={"id"}
																		components={components}
																		bordered
																		rowClassName={(record) => getRowClassName(record)}
																	/>
																</div> :
															null
													}
													{
														item?.course_title !== "Assessments" /*&& !isCourseNotApproved*/ ?
															<div className='d-flex justify-content-end mt-3'>
																<Button className='btn-info' disabled={loading || handleDisableSave(item?.course_data, index)} onClick={() => handleSaveCourse(item, index)}>{constants.Save}</Button>
															</div> :
															null
													}
												</div>
												: null
										}
									</Panel>
								}) :
								constants.StudentCourse.map((course, index) => <Panel header={course.corse_title} key={index} className='collapse-custom mb-2'></Panel>)
						}
					</Collapse>
					{/* <Button onClick={viewSemCourses}>route to summester course</Button> */}
				</div>
				{/* note model */}
				<Modal
					title={noteTitle}
					open={isNoteModalOpen}
					onCancel={() => handleCancel("note")}
					footer={<>
						<Button type='primary' danger onClick={() => handleCancel("note")}>{constants.Cancel}</Button>
						{role !== "0" && <Button type='primary' onClick={handleNote}>Done</Button>}
					</>}
				>
					<TextArea readOnly={role === "0" ? true : false} onChange={onChangeNote} value={noteValue} placeholder={constants.NotePlaceHolder} rows={5} />
				</Modal>
				{/* remove course model */}
				<Modal
					title={constants.Warning}
					open={isRemoveCourseModalOpen}
					onOk={handleRemoveCourse}
					onCancel={() => handleCancel("coursemodel")}
					cancelButtonProps={{
						danger: true,
						type: "primary"
					}}
					okText={"Remove"}
					cancelText={constants.Cancel}
				>
					{constants.UploadFileDeletePopupMessg}
				</Modal>
				{/* approve not apprive model */}
				<Modal
					title={constants.Warning}
					open={isApproveModalOpen}
					onOk={handleApproveNotApproveOk}
					onCancel={() => handleCancel("isapprove")}
					cancelButtonProps={{
						danger: true,
						type: "primary"
					}}
					okText={constants.Yes}
					cancelText={constants.Cancel}
				>
					{approvedValue?.approved_type === "1" ? constants.ApprovelConfirmation : constants.NotApprovelConfirmation}
				</Modal>
			</div>
		</Spin>
	)
}

export default Courses