import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,Navigate
} from "react-router-dom";
import Layouts from "../Views/Common/Layout";
import StudentLayout from "../Views/StudentSection/Common/Layout";
import StudentDashboard from '../Views/StudentSection/StudentDashboard';
import Dashboard from '../Views/Dashboard';
import Login from '../Views/Common/Login';
import constants from '../Constants/constants';
import AssignAdvisors from '../Views/Advising/AssignAdvisors';
import AddSchedule from '../Views/Advising/AddSchedule';
import NotificationListing from '../Views/Notifications/Listing';
import AddNotifications from '../Views/Notifications/AddNotificatons';
import ActivityLogs from '../Views/ActivityLogs';
import FacultyListing from '../Views/Faculty/FacultyListing';
import FacultyRegistration from '../Views/Faculty/FacultyRegistration';
import FacultyEdit from '../Views/Faculty/FacultyEdit';
import SendEmail from '../Views/Mails/SendEmail';
import EmailReports from '../Views/Mails/EmailReports';
import StudentListing from '../Views/Students/StudentListing';
import AdvisingDocs from '../Views/Advising/AdvisingDocs';
import EditStudent from '../Views/Students/EditStudent';
import AcademicSpecification from '../Views/GPA/AcademicSpecification';
import StudentGpa from '../Views/GPA/StudentGpa';
import DissmissedList from '../Views/GPA/DissmissedList';
import GpaHistory from '../Views/GPA/GpaHistory';
import AssigningMentor from '../Views/AssigningMentors/AssigningMentor';
import UserRole from '../Views/UserRoles/UserRole';
import StudentTabs from '../Views/Students/StudentTabs';
import ManageSurveys from '../Views/Surveys/ManageSurveys/ManageSurveys';
import ChangePassword from '../Views/Common/ChangePassword';
import ForgotPassword from '../Views/Common/ForgotPassword';
import SurveyTypes from '../Views/Surveys/SurveyTypes/SurveyTypes';
import Registration from '../Views/Students/Registration';
import Questions from '../Views/Surveys/ManageSurveys/Questions';
import SurveyResult from '../Views/Surveys/ManageSurveys/SurveyResult';
import SurveyReport from '../Views/Surveys/ManageSurveys/SurveyReport';
import AreaCourses from '../Views/Courses/ManageCourses/AreaCourses'
import SLPCourses from '../Views/Courses/ManageCourses/SLPCourses';
import FreeElectives from '../Views/Courses/ManageCourses/FreeElectives';
import Assessments from '../Views/Courses/ManageCourses/Assessments';
import FacultyCoursesAssignment from '../Views/Courses/FacultyCoursesAssignment';
import StudentCourseList from '../Views/Courses/StudentCourseList';
import Courses from '../Views/Courses/Courses';
import ResetPassword from '../Views/Common/ResetPassword';
import ConfidentialNotes from '../Views/Students/ConfidentialNotes';
import StudentImport from '../Views/StudentImports/StudentImport';
import FailedImport from '../Views/StudentImports/FailedImport';
import AdvisorSignup from '../Views/Advising/AdvisorSignup';
import StudentAdvisorSignup from '../Views/StudentSection/StudentAdvisorSignup';
import StudentProfile from '../Views/StudentSection/StudentProfile.js/StudentProfile';
import CreateSemester from '../Views/CreateSemester';
import StudentNotes from '../Views/StudentSection/StudentNotes';
import Notifications from '../Views/Notifications/Notifications';
import MyCourses from '../Views/Courses/MyCourses';
import CoursePlanNotes from '../Views/Students/CoursePlanNotes';
import Clearance from '../Views/Clinical/Clearance';
import StudentRelatedNotes from '../Views/Advising/StudentRelatedNotes';
import StudentCourses from '../Views/Students/StudentCourses/Courses';
import LinkExpired from '../Views/Common/LinkExpired';
import StudentCheckList from '../Views/Students/Checklist';
import StudentSemesterCourses from '../Views/StudentSection/StudentSemesterCourses';
import StudentStudyPlan from '../Views/StudentSection/StudentStudyPlan';
import SurveyList from '../Views/Surveys/Common/SurveyList';
import AnswerSurvey from '../Views/Surveys/Common/AnswerSurvey';
import LearningOutcomesList from '../Views/Surveys/Common/LearningOutcomesList';
import MentorDetailsResults from '../Views/Surveys/ManageSurveys/MentorResultsDetails';
import { AdminRoute, StudentRoute, RoleProtectedRoute } from './helper';
import PageNotFound from '../Views/PageNotFound';
import ActiveCourseList from '../Views/Courses/ActiveCourseList';
import ConditionalLayout from './layoutHandler';

  //'ROLES', [1 => 'Admin', 2 => 'Faculty', 3 => 'System Administrator', 4 => 'Adjunct faculty', 5 => 'Clinical Supervisor']);
  const routerBrowser = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* Public Routes */}
        <Route path="/" element={<Navigate to="/student-login" />} />
        <Route path="/login" element={<Navigate to="/student-login" />} />
        <Route path="/student" element={<Navigate to="/student-login" />} />
        <Route path="/student-login" element={<Login type="student" />} />
        <Route path="/staff" element={<Navigate to="/staff-login" />} />
        <Route path="/staff-login" element={<Login type="staff" />} />
        <Route exact path="/staff-request-password-reset" element={<ForgotPassword />} />
        <Route exact path="/student-request-password-reset" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword/>} />
        <Route exact path='/link-expired' element={<LinkExpired/>}/>
        
        {/*Routes which are needed for both for all roles with respective layout to be entered here*/}
        <Route path="/change-password" element={<ConditionalLayout />}>
          <Route index element={<ChangePassword />} />
        </Route>
        <Route path="/course-plan-notes" element={<ConditionalLayout />}>
          <Route index element={<CoursePlanNotes/>} />
        </Route>

        {/* Protected Routes for Students */}
        <Route element={<StudentRoute><StudentLayout /></StudentRoute>}>
          {/* Add more student-specific routes here */}
          <Route exact path="/student-change-password" element={<ChangePassword />} />
          <Route exact path="/student-course-plan-notes" element={<CoursePlanNotes />} />
          <Route exact path="/student-dashboard" element={<StudentDashboard />} />
          <Route exact path="/student-advisor-signup" element={<StudentAdvisorSignup/>}/>
          <Route exact path='student-profile' element={<StudentProfile/>}/>
          <Route exact path="/student-notifications" element={<Notifications/>}/>
          <Route exact path="/student-courses" element={<StudentCourses />}/>
          <Route exact path="/student-checklist" element={<StudentCheckList />}/>
          <Route exact path="/semester-courses" element={<StudentSemesterCourses/>}/>
          <Route exact path="/plan-of-study" element={<StudentStudyPlan/>}/>
          <Route exact path="/all-surveys" element={<SurveyList type={constants.Student} />}/>
          <Route exact path="/answer-survey-student" element={<AnswerSurvey />}/>
          <Route exact path="/student-notes" element={<StudentNotes/>}/>
        </Route>
        {/* Protected Routes for Admins with Admin Layout */}
        <Route element={<AdminRoute><Layouts /></AdminRoute>}>
          {/* Add more admin-specific routes here */}
          <Route path="/dashboard" element={<Dashboard />}/>
          <Route exact path="/create-semester" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <CreateSemester/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/assigning-advisors" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <AssignAdvisors/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/advising-documents" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","5"]}>
              <AdvisingDocs/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/add-schedule" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <AddSchedule />
            </RoleProtectedRoute>}
          />
          <Route exact path="/list-schedule" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","5"]}>
              <AddSchedule />
            </RoleProtectedRoute>}
          />
          <Route exact path="/notification-list" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <NotificationListing />
            </RoleProtectedRoute>}
          />
          <Route exact path="/notification-add" element={
            <RoleProtectedRoute allowedRoles={["1","2","3"]}>
              <AddNotifications />
            </RoleProtectedRoute>}
          />
          <Route exact path="/activity-logs" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <ActivityLogs/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/staff-list" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <FacultyListing />
            </RoleProtectedRoute>}
          />
          <Route exact path="/staff-registration" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <FacultyRegistration/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/student-listing" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <StudentListing />
            </RoleProtectedRoute>}
          />
          <Route exact path="/course-plan-notes" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5","6"]}>
              <CoursePlanNotes />
            </RoleProtectedRoute>}
          />
          <Route exact path="/change-password" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5","6"]}>
              <ChangePassword />
            </RoleProtectedRoute>}
          />
          <Route exact path="/staff-edit" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <FacultyEdit/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/send-email" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","5"]}>
              <SendEmail/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/email-reports" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <EmailReports/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/edit-student-details" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <EditStudent />
            </RoleProtectedRoute>}
          />
          <Route exact path="/academic-specification" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <AcademicSpecification />
            </RoleProtectedRoute>}
          />
          <Route exact path="/student-gpa" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <StudentGpa />
            </RoleProtectedRoute>}
          />
          <Route exact path="/dismissed-students" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <DissmissedList />
            </RoleProtectedRoute>}
          />
          <Route exact path="/gpa-history" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <GpaHistory />
            </RoleProtectedRoute>}
          />
          <Route exact path="/assign-mentors" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <AssigningMentor />
            </RoleProtectedRoute>}
          />
          <Route exact path="/user-roles" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <UserRole />
            </RoleProtectedRoute>}
          />
          <Route exact path="/student-information" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <StudentTabs />
            </RoleProtectedRoute>}
          />
          <Route exact path="/manage-surveys" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <ManageSurveys />
            </RoleProtectedRoute>}
          />
          <Route exact path="/survey-types" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <SurveyTypes />
            </RoleProtectedRoute>}
          />
          <Route exact path="/student-registration" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <Registration />
            </RoleProtectedRoute>}
          />
          <Route exact path="/questions" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <Questions />
            </RoleProtectedRoute>}
          />
          <Route exact path="/survey-result" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <SurveyResult />
            </RoleProtectedRoute>}
          />
          <Route exact path="/surveys-report" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <SurveyReport />
            </RoleProtectedRoute>}
          />
          <Route exact path="/results-details" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <MentorDetailsResults />
            </RoleProtectedRoute>}
          />
          <Route exact path="/student-course-list" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","5"]}>
              <StudentCourseList/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/courses" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <Courses/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/faculty-courses-assignment" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <FacultyCoursesAssignment/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/faculty-courses-assignment-history" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <FacultyCoursesAssignment/>
              </RoleProtectedRoute>}
          />
          <Route exact path="/areas-courses" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <AreaCourses/>
              </RoleProtectedRoute>}
          />
          <Route exact path="/slp-courses" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <SLPCourses/>
              </RoleProtectedRoute>}
          />
          <Route exact path="/free-electives" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <FreeElectives/>
              </RoleProtectedRoute>}
          />
          <Route exact path="/assessments" element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <Assessments/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/confidential-notes" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <ConfidentialNotes/>
            </RoleProtectedRoute>}
          />
          <Route exact path='student-import' element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <StudentImport/>
            </RoleProtectedRoute>}
          />
          <Route exact path='failed-import' element={
            <RoleProtectedRoute allowedRoles={["1","3"]}>
              <FailedImport/>
            </RoleProtectedRoute>}
            />
          <Route exact path="/advisor-signup" element={
            <RoleProtectedRoute allowedRoles={["1","2","3"]}>
              <AdvisorSignup/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/profile" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <FacultyEdit/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/notifications" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <Notifications/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/my-courses" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <MyCourses/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/clearances" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <Clearance/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/student-related-notes" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <StudentRelatedNotes/>
            </RoleProtectedRoute>}
          />
          <Route exact path="/my-survey" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <SurveyList type={constants.Faculty} />
            </RoleProtectedRoute>}
          />
          <Route exact path="/learning-outcomes" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <LearningOutcomesList type={constants.Faculty} />
              </RoleProtectedRoute>}
            />
          <Route exact path="/answer-survey" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <AnswerSurvey />
            </RoleProtectedRoute>}
          />
          <Route exact path="/active-course-list" element={
            <RoleProtectedRoute allowedRoles={["1","2","3","4","5"]}>
              <ActiveCourseList />
            </RoleProtectedRoute>}
          />
        </Route>
        <Route exact path="*" element={<PageNotFound />}/>
    </>
    ),
    {
      basename: constants.BaseUrl,
    }
  );
export default routerBrowser;
