import React, { useContext, useEffect, useState } from 'react';
import {
	DashboardOutlined, MailFilled, MenuOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faSquarePlus, faUsers, faBell, faBook, faHandshake, faAddressCard, faGraduationCap, faPaste, faDownload, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Layout, Menu, Drawer, Button, Badge, Space } from 'antd';
import { UserContext } from '../../Context';
import { Link } from "react-router-dom";
import LogoLg from '../../Assets/header-logo4.png';
import LogoSm from '../../Assets/header-logo6.png';
import constants from '../../Constants/constants';
import { COOKIE, getCookie } from '../../Services/Cookie';
import { fetchApi } from '../../Services/Api';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidemenu = ({ count }) => {
	const collapsed = useContext(UserContext);
	const ID = parseInt(getCookie(COOKIE.UserId))
	const userRoleId = getCookie(COOKIE.Role);
	const isAdvisor = getCookie(COOKIE.IsAdvisor);
	const [openKeys, setOpenKeys] = useState([]);
	const [hasLearningOutcomes, setHasLearningOutcomes] = useState(false);
	const isFaculty = userRoleId === '2' || userRoleId === '4'

	useEffect(() => {
		if (isFaculty) fetchLearningOutcomes()
	}, [])

	const fetchLearningOutcomes = () => {
		fetchApi(`/staff/get-shared-learning-outcomes-results/${ID}`, 'get').then((res) => {
			setHasLearningOutcomes(res?.data?.length > 0)
		}).catch((err) => console.log(err))
	}

	const handleOpenChange = (keys) => {
		const latestOpenKey = keys[keys.length - 1];
		const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub7', 'sub8', 'sub9', 'sub10'];
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			// If the latest opened key is a root submenu, keep it in openKeys
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	return (
		<>
			{/* Button for Mobile Menu */}
			<Button
				className="md:hidden absolute top-2 left-3.5 border border-solid border-white text-white"
				type="text"
				icon={<MenuOutlined />}
				onClick={showDrawer}
			>
				Menu
			</Button>
			<Drawer
				title={<img src={LogoLg} width={'80%'} alt='logo' />}
				placement="left"
				width={320}
				onClose={onClose}
				open={open}
				closeIcon={false}
				extra={<Button type='text' icon={<CloseCircleOutlined className="text-red-300 !text-2xl" onClick={onClose} />} />}
				className="md:hidden mobile-menu"
			>
				<Menu
					theme="dark"
					mode="inline"
					defaultSelectedKeys={['1']}
					openKeys={openKeys}
					onOpenChange={handleOpenChange}
				>
					<Menu.Item key="1" icon={<DashboardOutlined className='!block' />}>
						<Link to="/dashboard">
							{constants.Dashboard}
						</Link>
					</Menu.Item>
					{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="2" icon={<FontAwesomeIcon icon={faSquarePlus} />} ><Link to="/create-semester">Create Semester</Link></Menu.Item> : null}

					<SubMenu icon={<FontAwesomeIcon icon={faUsers} />}
						key="sub1"
						title="Students"
					>
						<Menu.Item key="3">
							<Link to="/student-listing">
								{constants.Listing}
							</Link>
						</Menu.Item>
						{(userRoleId === '1' || userRoleId === '3') ?
							<Menu.Item key="4">
								<Link to="/student-registration">
									{constants.Registration}
								</Link>
							</Menu.Item>
							: null}
					</SubMenu>
					{((userRoleId !== '2' && userRoleId !== '4' && userRoleId !== '5') || isAdvisor === '1') ?
						<SubMenu icon={<FontAwesomeIcon icon={faCircleUser} />}
							key="sub2"
							title="Advising"
						>
							{(userRoleId === '1' || userRoleId === '3') ?
								<Menu.Item key="5">
									<Link to="/assigning-advisors">
										Assigning Advisors
									</Link>
								</Menu.Item>
								: null}
							{(userRoleId === '1' || userRoleId === '3' || isAdvisor === '1') ? <Menu.Item key="6"><Link to="/advising-documents">Advising Documents</Link></Menu.Item> : null}
							{isAdvisor === '1' ? <Menu.Item key="7"><Link to="/advisor-signup">{constants.AdvisorSignup}</Link></Menu.Item> : ''}
							{isAdvisor === '1' ? <Menu.Item key="24"><Link to="/list-schedule" state={{ faculty_id: ID }}>{constants.AdvisorSchedule}</Link></Menu.Item> : ''}
						</SubMenu>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<Menu.Item key="assign-mentors" icon={<FontAwesomeIcon icon={faHandshake} />}>
							<Link to='/assign-mentors'>
								{constants.AssigningMentors}
							</Link>
						</Menu.Item>
						: null}
					{((userRoleId !== '2' && userRoleId !== '4') || isAdvisor === '1') ?
						<SubMenu icon={<MailFilled />}
							key="sub3"
							title="Emails"
						>
							{(userRoleId === '1' || userRoleId === '3' || isAdvisor === '1') ? <Menu.Item key="8"><Link to="/send-email">Send Emails</Link></Menu.Item> : null}
							{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="9"><Link to="/email-reports">Email Reports</Link></Menu.Item> : null}
						</SubMenu>
						: null}
					{
						isFaculty && !hasLearningOutcomes ?
							<Menu.Item key="my-survey" icon={<FontAwesomeIcon icon={faPaste} />}>
								<Link to="/my-survey">
									{constants.MySurveys}
								</Link>
							</Menu.Item> :
							<SubMenu icon={<FontAwesomeIcon icon={faPaste} />}
								key='sub4'
								title="Surveys"
							>
								{(userRoleId === '1' || userRoleId === '3') ?
									<Menu.Item key="manage-surveys"><Link to="/manage-surveys">{constants.ManageSurveys}</Link></Menu.Item>
									: null}
								{(userRoleId === '1' || userRoleId === '3') ?
									<Menu.Item key="survey-types"><Link to="/survey-types">{constants.SurveysType}</Link></Menu.Item>
									: null}
								{/* Sys admin only based if learning outcomes exist. */}
								{(userRoleId !== '1') ?
									<Menu.Item key="25"><Link to="/learning-outcomes">{constants.LearningOut}</Link></Menu.Item>
									: null}
								{/* Sys admin only based if surveys exist. */}
								<Menu.Item key="27"><Link to="/my-survey">{constants.MySurveys}</Link></Menu.Item>

							</SubMenu>
					}
					{((userRoleId === '2' || userRoleId === '4') && isAdvisor !== '1') ? <Menu.Item key="30" icon={<FontAwesomeIcon icon={faGraduationCap} />}><Link to="/my-courses">My Courses</Link></Menu.Item> : ''} {/* based on a flag */}
					{((userRoleId !== '2' && userRoleId !== '4') || isAdvisor === '1') ?
						<SubMenu icon={<FontAwesomeIcon icon={faGraduationCap} />}
							key="sub5"
							title="Courses"
						>
							{(userRoleId === '1' || userRoleId === '3' || isAdvisor === '1') ? <Menu.Item key="10"><Link to="/student-course-list">Student Course List</Link></Menu.Item> : null}
							{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="11"><Link to="/faculty-courses-assignment">Faculty Courses Assignment</Link></Menu.Item> : null}
							{((userRoleId !== '1' && userRoleId !== '3') || isAdvisor === '1') ? <Menu.Item key="26"><Link to="/my-courses">My Courses</Link></Menu.Item> : null} {/* based on a flag */}
							{(userRoleId === '1' || userRoleId === '3') ?
								<SubMenu
									key="sub6"
									title="Manage Courses"
								>
									<Menu.Item key="12"><Link to="/areas-courses">Areas - Courses</Link></Menu.Item>
									<Menu.Item key="13"><Link to="/slp-courses">SLP Courses</Link></Menu.Item>
									<Menu.Item key="14"><Link to="/free-electives">Free Electives</Link></Menu.Item>
									<Menu.Item key="15"><Link to="/assessments">Assessments</Link></Menu.Item>
								</SubMenu>
								: null}
						</SubMenu>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<SubMenu icon={<FontAwesomeIcon icon={faBook} />}
							key='sub7'
							title="GPA"
						>
							<Menu.Item key="academic-specification"><Link to="/academic-specification">{constants.AcademicSpecification}</Link></Menu.Item>
							<Menu.Item key="student-gpa"><Link to="/student-gpa">{constants.StudentGPA}</Link></Menu.Item>
							<Menu.Item key="dismissed-students"><Link to="/dismissed-students">{constants.DissmissedList}</Link></Menu.Item>
							<Menu.Item key="16"><Link to="/gpa-history">{constants.GpaHistory}</Link></Menu.Item>
						</SubMenu>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<SubMenu icon={<FontAwesomeIcon icon={faUsers} />}
							key="sub8"
							title="Staff"
						>
							<Menu.Item key="17"><Link to="/staff-list">Listing</Link></Menu.Item>
							<Menu.Item key="18"><Link to="/staff-registration">Registration</Link></Menu.Item>
						</SubMenu>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<Menu.Item key="19" icon={<FontAwesomeIcon icon={faAddressCard} />}>
							<Link to='/user-roles'>
								{constants.UserRoles}
							</Link>
						</Menu.Item>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<Menu.Item key="20" icon={<FontAwesomeIcon icon={faBell} />}>
							<Link to='/notification-list'>
								<Space>
									{constants.Notifications}
									{count > 0 && <Badge className="badge-style" count={count} size="small" showZero={true} />}
								</Space>
							</Link>
						</Menu.Item> : null}
					{(userRoleId !== '1' && userRoleId !== '3') ?
						<Menu.Item key="28" icon={<FontAwesomeIcon icon={faBell} />}>
							<Link to='/notifications'>
								<Space>
									{constants.Notifications}
									{count > 0 && <Badge className="badge-style" count={count} size="small" showZero={true} />}
								</Space>
							</Link>
						</Menu.Item>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<Menu.Item key="21" icon={<FontAwesomeIcon icon={faDownload} />}>
							<Link to="/student-import">
								{constants.StudentImports}
							</Link>
						</Menu.Item>
						: null}
					{(userRoleId === '1' || userRoleId === '3') ?
						<Menu.Item key="23" icon={<FontAwesomeIcon icon={faClockRotateLeft} />}>
							<Link to="/activity-logs">
								{constants.ActivityLogs}
							</Link>
						</Menu.Item>
						: null
					}
				</Menu>
			</Drawer>
			{/* Sidemenu for Desktop */}
			<Sider trigger={null} collapsible collapsed={collapsed.collapsed} width={230} className="hidden md:block">
				<div className={` ${collapsed.collapsed ? '' : 'fixed w-[230px]'}`}>
					<div className='logo-area flex items-center justify-center'>
						<img src={collapsed.collapsed ? LogoSm : LogoLg} width={collapsed.collapsed ? '60%' : '80%'} alt='logo' />
					</div>
					<div className='overflow-y-auto h-[calc(100vh-50px)]'>
						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={['1']}
							openKeys={openKeys}
							onOpenChange={handleOpenChange}
						>
							<Menu.Item key="1" icon={<DashboardOutlined className='!block' />}>
								<Link to="/dashboard">
									{constants.Dashboard}
								</Link>
							</Menu.Item>
							{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="2" icon={<FontAwesomeIcon icon={faSquarePlus} />} ><Link to="/create-semester">Create Semester</Link></Menu.Item> : null}

							<SubMenu icon={<FontAwesomeIcon icon={faUsers} />}
								key="sub1"
								title="Students"
							>
								<Menu.Item key="3">
									<Link to="/student-listing">
										{constants.Listing}
									</Link>
								</Menu.Item>
								{(userRoleId === '1' || userRoleId === '3') ?
									<Menu.Item key="4">
										<Link to="/student-registration">
											{constants.Registration}
										</Link>
									</Menu.Item>
									: null}
							</SubMenu>
							{((userRoleId !== '2' && userRoleId !== '4' && userRoleId !== '5') || isAdvisor === '1') ?
								<SubMenu icon={<FontAwesomeIcon icon={faCircleUser} />}
									key="sub2"
									title="Advising"
								>
									{(userRoleId === '1' || userRoleId === '3') ?
										<Menu.Item key="5">
											<Link to="/assigning-advisors">
												Assigning Advisors
											</Link>
										</Menu.Item>
										: null}
									{(userRoleId === '1' || userRoleId === '3' || isAdvisor === '1') ? <Menu.Item key="6"><Link to="/advising-documents">Advising Documents</Link></Menu.Item> : null}
									{isAdvisor === '1' ? <Menu.Item key="7"><Link to="/advisor-signup">{constants.AdvisorSignup}</Link></Menu.Item> : ''}
									{isAdvisor === '1' ? <Menu.Item key="24"><Link to="/list-schedule" state={{ faculty_id: ID }}>{constants.AdvisorSchedule}</Link></Menu.Item> : ''}
								</SubMenu>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<Menu.Item key="assign-mentors" icon={<FontAwesomeIcon icon={faHandshake} />}>
									<Link to='/assign-mentors'>
										{constants.AssigningMentors}
									</Link>
								</Menu.Item>
								: null}
							{((userRoleId !== '2' && userRoleId !== '4' && userRoleId !== '5') || isAdvisor === '1') ?
								<SubMenu icon={<MailFilled />}
									key="sub3"
									title="Emails"
								>
									{(userRoleId === '1' || userRoleId === '3' || isAdvisor === '1') ? <Menu.Item key="8"><Link to="/send-email">Send Emails</Link></Menu.Item> : null}
									{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="9"><Link to="/email-reports">Email Reports</Link></Menu.Item> : null}
								</SubMenu>
								: null}
							{
								isFaculty && !hasLearningOutcomes ?
									<Menu.Item key="my-survey" icon={<FontAwesomeIcon icon={faPaste} />}>
										<Link to="/my-survey">
											{constants.MySurveys}
										</Link>
									</Menu.Item> :
									<SubMenu icon={<FontAwesomeIcon icon={faPaste} />}
										key='sub4'
										title="Surveys"
									>
										{(userRoleId === '1' || userRoleId === '3') ?
											<Menu.Item key="manage-surveys"><Link to="/manage-surveys">{constants.ManageSurveys}</Link></Menu.Item>
											: null}
										{(userRoleId === '1' || userRoleId === '3') ?
											<Menu.Item key="survey-types"><Link to="/survey-types">{constants.SurveysType}</Link></Menu.Item>
											: null}
										{/* Sys admin only based if learning outcomes exist. */}
										{(userRoleId !== '1') ?
											<Menu.Item key="25"><Link to="/learning-outcomes">{constants.LearningOut}</Link></Menu.Item>
											: null}
										{/* Sys admin only based if surveys exist. */}
										<Menu.Item key="27"><Link to="/my-survey">{constants.MySurveys}</Link></Menu.Item>

									</SubMenu>
							}
							{((userRoleId === '2' || userRoleId === '4') && isAdvisor !== '1') ? <Menu.Item key="30" icon={<FontAwesomeIcon icon={faGraduationCap} />}><Link to="/my-courses">My Courses</Link></Menu.Item> : ''} {/* based on a flag */}
							{((userRoleId !== '2' && userRoleId !== '4') || isAdvisor === '1') ?
								<SubMenu icon={<FontAwesomeIcon icon={faGraduationCap} />}
									key="sub5"
									title="Courses"
								>
									{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="22"><Link to="/active-course-list">Active Courses</Link></Menu.Item> : null}
									{(userRoleId === '1' || userRoleId === '3' || isAdvisor === '1') ? <Menu.Item key="10"><Link to="/student-course-list">Student Course List</Link></Menu.Item> : null}
									{(userRoleId === '1' || userRoleId === '3') ? <Menu.Item key="11"><Link to="/faculty-courses-assignment">Faculty Courses Assignment</Link></Menu.Item> : null}
									{((userRoleId === '2' || userRoleId === '3') || isAdvisor === '1') ? <Menu.Item key="26"><Link to="/my-courses">My Courses</Link></Menu.Item> : null} {/* based on a flag */}
									{(userRoleId === '1' || userRoleId === '3') ?
										<SubMenu
											key="sub6"
											title="Manage Courses"
										>
											<Menu.Item key="12"><Link to="/areas-courses">Areas - Courses</Link></Menu.Item>
											<Menu.Item key="13"><Link to="/slp-courses">SLP Courses</Link></Menu.Item>
											<Menu.Item key="14"><Link to="/free-electives">Free Electives</Link></Menu.Item>
											<Menu.Item key="15"><Link to="/assessments">Assessments</Link></Menu.Item>
										</SubMenu>
										: null}
								</SubMenu>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<SubMenu icon={<FontAwesomeIcon icon={faBook} />}
									key='sub7'
									title="GPA"
								>
									<Menu.Item key="academic-specification"><Link to="/academic-specification">{constants.AcademicSpecification}</Link></Menu.Item>
									<Menu.Item key="student-gpa"><Link to="/student-gpa">{constants.StudentGPA}</Link></Menu.Item>
									<Menu.Item key="dismissed-students"><Link to="/dismissed-students">{constants.DissmissedList}</Link></Menu.Item>
									<Menu.Item key="16"><Link to="/gpa-history">{constants.GpaHistory}</Link></Menu.Item>
								</SubMenu>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<SubMenu icon={<FontAwesomeIcon icon={faUsers} />}
									key="sub8"
									title="Staff"
								>
									<Menu.Item key="17"><Link to="/staff-list">Listing</Link></Menu.Item>
									<Menu.Item key="18"><Link to="/staff-registration">Registration</Link></Menu.Item>
								</SubMenu>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<Menu.Item key="19" icon={<FontAwesomeIcon icon={faAddressCard} />}>
									<Link to='/user-roles'>
										{constants.UserRoles}
									</Link>
								</Menu.Item>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<Menu.Item key="20" icon={<FontAwesomeIcon icon={faBell} />}>
									<Link to='/notification-list'>
										<Space>
											{constants.Notifications}
											{count > 0 && <Badge className="badge-style" count={count} size="small" showZero={true} />}
										</Space>
									</Link>
								</Menu.Item> : null}
							{(userRoleId !== '1' && userRoleId !== '3') ?
								<Menu.Item key="28" icon={<FontAwesomeIcon icon={faBell} />}>
									<Link to='/notifications'>
										<Space>
											{constants.Notifications}
											{count > 0 && <Badge className="badge-style" count={count} size="small" showZero={true} />}
										</Space>
									</Link>
								</Menu.Item>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<Menu.Item key="21" icon={<FontAwesomeIcon icon={faDownload} />}>
									<Link to="/student-import">
										{constants.StudentImports}
									</Link>
								</Menu.Item>
								: null}
							{(userRoleId === '1' || userRoleId === '3') ?
								<Menu.Item key="23" icon={<FontAwesomeIcon icon={faClockRotateLeft} />}>
									<Link to="/activity-logs">
										{constants.ActivityLogs}
									</Link>
								</Menu.Item>
								: null
							}
						</Menu>
					</div>
				</div>
			</Sider>
		</>
	);
};

export default Sidemenu;
