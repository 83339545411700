import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../../Assets/header-logo4.png'
import constants from "../../Constants/constants";
import Glyphicon from '@strongdm/glyphicon';
import { fetchApi } from '../../Services/Api'
import { COOKIE, createCookie, getCookie } from "../../Services/Cookie";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { message } from 'antd'
import {EyeOutlined,EyeInvisibleOutlined} from '@ant-design/icons';
import { decode } from "js-base64";
import { UserContext } from "../../Context";

const Login = () => {
	const { setProfileImage } = useContext(UserContext)
	const navigate = useNavigate();
	const location = useLocation();
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	// const [loginMessage, setLoginMessage] = useState("");
	// const [alertVariant, setAlertVariant] = useState(null);
	const [loginType, setLoginType] = useState('student-login');
	const [userData, setUserData] = useState({})
	const [showPassword,setShowPassword] = useState(false)
	const encodedUrl = localStorage.getItem('surveyUrl') || ''

	useEffect(() => {
		let user = getCookie(COOKIE.UserId);
		if (user) {
			navigate("/dashboard");
			return;
		};
		if (location.pathname === '/staff-login') {
			setLoginType('staff-login');
		} else {
			setLoginType('student-login');
		}
	}, [navigate, location.pathname]);

	const onChangeUserDetails = (e, input_type) => {
		if (input_type === "Username") {
			setUserName(e.target.value);
		} else {
			setPassword(e.target.value);
		}
	};

	const handleCookie = (UserId, UserName, FirstName, MiddleName, LastName, Token, RoleId, IsActive, IsAdvisor, IsMentor) => {
		createCookie(COOKIE.UserId, UserId, null, "/");
		createCookie(COOKIE.UserName, UserName, null, "/");
		createCookie(COOKIE.FirstName, FirstName, null, "/");
		createCookie(COOKIE.MiddleName, MiddleName, null, "/");
		createCookie(COOKIE.LastName, LastName, null, "/");
		createCookie(COOKIE.Token, Token, null, "/");
		createCookie(COOKIE.Role, RoleId, null, "/");
		createCookie(COOKIE.IsActive, IsActive, null, "/");
		createCookie(COOKIE.IsAdvisor, IsAdvisor, null, '/');
		createCookie(COOKIE.IsMentor, IsMentor, null, '/');
	};


	const onChangeLoginType = (key) => {
		setValidated(false);
		setUserName("");
		setPassword("");
		setLoginType(key);
		setShowPassword(false)
		let url = ''
		if (key === 'student-login') {
			url = '/student-login';
		} else if (key === 'staff-login') {
			url = '/staff-login';
		}
		if(encodedUrl) url+=`${encodedUrl}`
		navigate(url)
	};

	const handleLogin = (e) => {
		e.preventDefault();
		setValidated(true);
		if (userName && password) {
			setValidated(false);
			setLoading(true);
			let payload = null;
			payload = {
				username: userName,
				password: password,
			};
			if (loginType === "staff-login") {
				payload.is_staff = "1";
			};
			fetchApi("/login", "post", payload)
				.then((res) => {
					setLoading(false);
					if (res?.code === '200' && res?.message === 'Login successful') {
						if (loginType === "student-login") {
							handleCookie(
								res?.data?.id,
								res?.data?.student_username,
								res?.data?.student_first_name,
								res?.data?.student_middle_name,
								res?.data?.student_last_name,
								res?.token,
								"0",
								res?.data?.is_active,
								res?.data?.is_advisor || 0,
								res?.data?.is_mentor || 0,
							);
							sessionStorage.setItem("profile_image", res?.data?.student_image);
							localStorage.setItem("profile_image", res?.data?.student_image);					
							setProfileImage(res?.data?.student_image)
							checkBeforeNavigate(`/student-dashboard`, { state: { loginType: "student" } })
						} else {
							handleCookie(
								res?.data?.id,
								res?.data?.faculty_username,
								res?.data?.faculty_first_name,
								res?.data?.faculty_middle_name,
								res?.data?.faculty_last_name,
								res?.token,
								res?.data?.faculty_roles,
								res?.data?.is_active,
								res?.data?.is_advisor || 0,
								res?.data?.is_mentor || 0,
							);
							localStorage.setItem("profile_image", res?.data?.faculty_image);
							sessionStorage.setItem("profile_image", res?.data?.faculty_image);
							setProfileImage(res?.data?.faculty_image)
							checkBeforeNavigate(`/dashboard`, { state: { loginType: "staff" } })
						};
						// setLoginMessage("Please wait until we redirect");
						// setAlertVariant('success');
					} else if (res.code === 400 ) {
						// setLoginMessage(<p>{"Error! Incorrect username or password."}<br></br>{'Contact the Administrator.'}</p>);
						// setAlertVariant('danger');
						message.error(res?.message)
						setPassword("");
					} else {
						setPassword("");
						message.error("Error! Incorrect username or password");
					}
				}).catch((error)=>{
					setPassword("");
					message.error(constants.ErrorMessage)
				})
		};
	};

	const getUserData = (e) => {
		e.preventDefault()
		if(!userName) {
			message.error(constants.EnterUserMu)
			return
		}

		let payload = {
			username: userName
		}
		if (loginType === "staff-login") payload.is_staff = "1";

		fetchApi(`/check-username`, 'POST', payload).then((res) => {
			if (res?.code === "200" && res?.message === 1) {
				setLoading(true)
				let email
				let userType
				let emailType
				let facultyRole
				if (loginType === "staff-login") {
					email = res.data?.faculty_university_email ? res.data?.faculty_university_email : res.data?.faculty_personal_email
					userType = 'staff'
					emailType = res.data?.email_type
					facultyRole = res.data?.faculty_roles
					if (emailType === '1') {
						email = res.data?.faculty_personal_email
					}
				} else {
					email = res.data?.student_university_email ? res.data?.student_university_email : res.data?.student_personal_email
					userType = 'stud'
				}
				setUserData({ username: userName, email: email, type: userType, emailType, facultyRole });
			} else if(res?.code === 400) {
				message.error(res.message)
			} else {
				message.error(constants.ForgotError)
			}
		}).catch((error) => {})
	}

	const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

	const checkBeforeNavigate = (current, state) => {
		const decodedUrl = decode(encodedUrl)
		const urlParams = new URLSearchParams(decodedUrl);
    const fromEmail = urlParams.get('login') === '0'
		let url = loginType === 'student-login' ? 
		`/answer-survey-student${encodedUrl}` :
		`/answer-survey${encodedUrl}`
		//CHECK IF URL CAME FROM EMAIL REDIRECT AND SEND USER TO SURVEY ELSE NORMAL REDIRECT
		if(fromEmail)
			navigate(url)
		else
			navigate(current, state)
	}

	useEffect(() => {
		if (userData && Object.keys(userData).length !== 0) {
			if (userData.userType === 'staff') {
				setTimeout(() => {
					setLoading(false)
					navigate('/staff-request-password-reset', { state: { userInfo: userData } });
				}, 2000);
			} else {
				setTimeout(() => {
					setLoading(false)
					navigate('/student-request-password-reset', { state: { userInfo: userData } });
				}, 2000);
			}
		}
	}, [userData, navigate])


	return (
		<div className="mx-auto min-vh-100 d-flex justify-content-center align-items-center p-3 p-md-0">
			{
				loading && <Spinner animation="border" role="status" className="d-flex justify-content-center align-items-center position-absolute z-3"></Spinner>
			}
			<div className="card shadow-lg login-card mb-5 z-2 rounded-0">
				<img src={logo} className="card-img-top rounded-0" alt="Logo" />
				<Tabs
					activeKey={loginType}
					id="uncontrolled-tab-example"
					variant="underline"
					className="justify-content-center h6 student-staff-tabs"
					onSelect={onChangeLoginType}
				>
					<Tab eventKey="student-login" title={<span className="text-uppercase">Student</span>}>
						<Form noValidate onSubmit={handleLogin} validated={validated}>
							<div className="card-body pt-0">
								<div className="px-0 px-md-4 pt-3">
									<Form.Group md="4" controlId="validationCustomUsername">
										<div className="input-group mb-3">
											<span className="input-group-text" id="basic-addon1">
												<Glyphicon glyph='user' />
											</span>
											<Form.Control
												type="text"
												placeholder={constants.UserEmail}
												aria-describedby="inputGroupPrepend"
												required
												value={userName}
												className="rounded-end shadow-none no-exclamation"
												onChange={(e) => onChangeUserDetails(e, "Username")}
											/>
											<Form.Control.Feedback type="invalid">
												This field is required.
											</Form.Control.Feedback>
										</div>
									</Form.Group>
									<Form.Group md="4" controlId="validationCustomPassword">
										<div className="input-group mb-3" >
											<span className="input-group-text" id="basic-addon1">
												<Glyphicon glyph='lock' />
											</span>
											<Form.Control
  											type={showPassword ? "text" : "password"}
												placeholder="Password"
												aria-describedby="inputGroupPrepend"
												required
												className="rounded-end shadow-none no-exclamation"
												value={password}
												onChange={(e) => onChangeUserDetails(e, "Password")}
											/>
											<span className="input-group-text eye-icon-span rounded-end" onClick={toggleShowPassword}>
												{showPassword ? <EyeOutlined className='eye-icon' /> : <EyeInvisibleOutlined className='eye-icon' />}
											</span>
											<Form.Control.Feedback type="invalid">
												This field is required.
											</Form.Control.Feedback>
										</div>
									</Form.Group>
									<div className="text-end mb-3 mx-2">
										<span onClick={getUserData} className="text-decoration-none text-capitalize fs-6 fw-medium forgot-link">{constants.ForgotPsd}</span>
									</div>
								</div>
								<div className="d-grid gap-2 px-0 px-md-3">
									<button className="btn btn-primary text-capitalize" type="submit">{constants.Login}</button>
								</div>
							</div>
						</Form>
					</Tab>
					<Tab eventKey="staff-login" title={<span className="text-uppercase">Staff</span>}>
						<Form noValidate onSubmit={handleLogin} validated={validated}>
							<div className="card-body pt-0">
								<div className="px-0 px-md-4 pt-3">
									<Form.Group md="4" controlId="validationCustomUsername">
										<div className="input-group mb-3">
											<span className="input-group-text" id="basic-addon1">
												<Glyphicon glyph='user' />
											</span>
											<Form.Control
												type="text"
												placeholder={constants.UserEmail}
												aria-describedby="inputGroupPrepend"
												required
												value={userName}
												className="rounded-end shadow-none no-exclamation"
												onChange={(e) => onChangeUserDetails(e, "Username")}
											/>
											<Form.Control.Feedback type="invalid">
												This field is required.
											</Form.Control.Feedback>
										</div>
									</Form.Group>
									<Form.Group md="4" controlId="validationCustomPassword">
										<div className="input-group mb-3" >
											<span className="input-group-text" id="basic-addon1">
												<Glyphicon glyph='lock' />
											</span>
											<Form.Control
  											type={showPassword ? "text" : "password"}
												placeholder="Password"
												aria-describedby="inputGroupPrepend"
												required
												className="rounded-end shadow-none no-exclamation"
												value={password}
												onChange={(e) => onChangeUserDetails(e, "Password")}
											/>
											<span className="input-group-text eye-icon-span rounded-end" onClick={toggleShowPassword}>
												{showPassword ? <EyeOutlined className='eye-icon' /> : <EyeInvisibleOutlined className='eye-icon' />}
											</span>
											<Form.Control.Feedback type="invalid">
												This field is required.
											</Form.Control.Feedback>
										</div>
									</Form.Group>
									<div className="text-end mb-3 mx-2">
										<span onClick={getUserData} className="text-decoration-none text-capitalize fs-6 fw-medium forgot-link">{constants.ForgotPsd}</span>
									</div>
								</div>
								<div className="d-grid gap-2 px-0 px-md-3">
									<button className="btn btn-primary text-capitalize" type="submit">{constants.Login}</button>
								</div>
							</div>
						</Form>
					</Tab>
				</Tabs>
			</div>
		</div>
	);
};
export default Login;
