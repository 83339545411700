import React, { useContext, useState } from "react";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
	WarningFilled,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
	Layout,
	Button,
	Avatar,
	Space,
	Card,
	Flex,
	Popover,
	Badge,
	Row,
	Col,
	List,
	Typography,
	message
} from "antd";
import { UserContext } from "../../../Context";
import constants from "../../../Constants/constants";
import { getCookie, COOKIE, deleteCookie } from "../../../Services/Cookie";
import { Link, useNavigate } from "react-router-dom";
import { fetchApi } from "../../../Services/Api";
import { badgeNotification, notificationHandler } from "../../../GlobalFunctions/GlobalFunctions";
import StudentMentorRequest from "../StudentMentorRequest";
const { Header } = Layout;

const Headers = ({ countData, updateNotificationData }) => {
	const navigate = useNavigate();
	// const profileImg = sessionStorage.getItem("profile_image") ? sessionStorage.getItem("profile_image") : localStorage.getItem("profile_image");
	const { collapsed, profileImage } = useContext(UserContext);
	const [visible, setVisible] = useState(false);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [mentorRequestModalOpen, setMentorRequestModalOpen] = useState(false);
	const [notificationId, setNotificationId] = useState(null);
	const userRoleId = getCookie(COOKIE.Role);
	const studentID = getCookie(COOKIE.UserId);
  const isStudent = userRoleId === '0'
	const username = `${getCookie(COOKIE.FirstName)} ${getCookie(COOKIE.LastName)}`
	const { Paragraph } = Typography;
	const cookiesToDelete = [
		COOKIE.UserId,
		COOKIE.UserName,
		COOKIE.FirstName,
		COOKIE.MiddleName,
		COOKIE.LastName,
		COOKIE.Token,
		COOKIE.ProfileImg,
		COOKIE.Role,
		COOKIE.FacultyIdFromEdit,
		COOKIE.IsAdvisor,
		COOKIE.Role,
		COOKIE.IsActive,
	];
	const logOut = () => {
		const payload = { is_logged_in: 0 };
		fetchApi(`/logout/${getCookie(COOKIE.UserId)}`, "POST", payload)
			.then((res) => {
				// console.log(res);
			})
			.catch((error) => { });
		cookiesToDelete.forEach((record) => deleteCookie(record, "/"));
		sessionStorage.clear();
		localStorage.clear();
		window.location.href = constants.BaseUrl + "/student-login";
	};

	const showProfile = () => {
		if(profileImage) return <Avatar icon={<img src={profileImage} alt="profile" />} />
		else return <Avatar icon={<UserOutlined />} />
	}

	const navigateToModule = (destination) => {
		navigate(`/${destination}`, {
			state: { editrecord: getCookie(COOKIE.UserId), isProfile: true },
		});
		setIsPopoverOpen(false);
	};

	const profileMenu = (
		<div className="profile-main">
			<Card className="profileCard">
				{showProfile()}
				<br></br>
				<span className="profile-name">{username}</span>
			</Card>
			<Flex gap={"small"} horizontal className="profile-menu-footer">
				<Button
					className="bgGreen500"
					onClick={() => navigateToModule("student-profile")}
				>
					{constants.Profile}
				</Button>
				<Button onClick={() => navigateToModule("student-change-password")}>
					{constants.ChangePassword}
				</Button>
				<Button type="primary" onClick={logOut} danger>
					{constants.SignOut}
				</Button>
			</Flex>
		</div>
	);

	const handleNotificationClick = (notif) => {
		notificationHandler(
			notif,
			navigate,
			{ 
				setNotificationId, 
				setMentorRequestModalOpen, 
				isStudent,
			}
		)
		setVisible(false);
	}

	const renderItemHeader = (item) => {
		const showWarning = (notif) => {
			if(notif?.priority === "1") return <WarningFilled className="error-icon" />
			else if(notif?.priority === "2") return <WarningFilled className="input-info" />
			else return <WarningFilled className="partial-error" />
		}

		return (
			<List.Item>
				<Row>
					<Col span={24}>
						<Space className="!items-start">
							{showWarning(item)}
							<div className="partial-message">
								{item.notification_url ? (
									<span
										onClick={() => handleNotificationClick(item)}
										className="cursor-pointer"
									>
										{item.notification_text_details}
									</span>
								) : (
									<Link to='/student-notifications' className="cursor-pointer">
										<Paragraph
											ellipsis={{
												rows: item?.notification_text_details.length > 20 ? 2 : 1,
											}}
											className="!mb-1"
											style={{ color: '#1677ff' }}
										>
											{item?.notification_text_details}
										</Paragraph>
									</Link>
								)}
							</div>
						</Space>
					</Col>
				</Row>
			</List.Item>
		);
	};
	const loadMoreFunction = () => (
		<div className="view-button">
			<Button type="link" onClick={() => setVisible(false)}>
				<Link to="/student-notifications">{constants.ViewMore}</Link>
			</Button>
		</div>
	);

	const handleSave = (value) => { 
    const payload = {
      student_id: studentID,
      value: value,
      notification_id: notificationId,
    };
    fetchApi("/student/save-mentor-request-status", "POST", payload)
      .then((res) => {
        if (res?.code === "200") {
          updateNotificationData();
					setMentorRequestModalOpen(false)
          message.success(constants.ThankYouResponse);
        } else message.error(constants.ErrorMessage);
      }).catch(() => {
        message.error(constants.ErrorMessage);
      });
  };

	return (
		<Header className="main-header">
			<Button
				type="text"
				icon={
					collapsed.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
				}
				onClick={collapsed.toggleCollapsed}
				className="text-white menu-btn !invisible md:!visible"
			/>
			<Space>
				{countData?.length > 0 ? (
					<Popover
						overlayClassName="notification-popover"
						content={
							<List
								size="large"
								className="list-size"
								bordered
								dataSource={countData?.slice(0, 5)}
								renderItem={renderItemHeader}
								loadMore={loadMoreFunction()}
							/>
						}
						trigger="click"
						open={visible}
						onOpenChange={setVisible}
					>
						{badgeNotification(countData)}
					</Popover>
				) : badgeNotification(countData)}
				<Popover
					content={profileMenu}
					trigger="click"
					overlayClassName="profile-popover"
					open={isPopoverOpen}
					onOpenChange={setIsPopoverOpen}
				>
					<Button type="text" className="text-white menu-btn">
						<Space>
							{showProfile()}
							<span className="text-white">{username}</span>
						</Space>
					</Button>
				</Popover>
			</Space>
			{
				mentorRequestModalOpen && 
				<StudentMentorRequest
					open={mentorRequestModalOpen}
					closeModal={() => setMentorRequestModalOpen(false)}
					handleSave={(e) => handleSave(e)}
				/>
			}
		</Header>
	);
};

export default Headers;
