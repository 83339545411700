import React, { useState,useEffect } from 'react';
import { Input, Select, Button, Form, Row, Col, DatePicker, Switch, Checkbox, Modal, message } from 'antd';
import constants from '../../../Constants/constants';
import dayjs from 'dayjs';
import { fetchStaffApi } from '../../../Services/Api';
import { checkEmptySpace, closeModal, removeExtraSpaces } from '../../../GlobalFunctions/GlobalFunctions';
import { COOKIE, getCookie } from '../../../Services/Cookie';

const AddAndEditSurveys = ({isAddOrEdit,setShowAddModal,surveyTypes,clearKeys,isHiddenSurvey,setShowEditModal,selectedRecord,showAddModal,showEditModal,continueSave,setContinueSave,setNewAddedRecord,selectedSurveyTypeSendTo}) => {
  const [form] = Form.useForm();
  const [showCourses, setShowCourses] = useState(false);
	const [visibilityValue, setVisibilityValue] = useState(false);
  const [disabledSurveyName, setDisabledSurveyName] = useState(false);
  const [surveyType, setSurveyType] = useState('');
	const [courseOrInstructorType,setCourseOrInstructorType] = useState('');
	const [courseName, setCourseName] = useState('');
	const [allCourses,setAllCourses] = useState([]);
	const [surveyName, setSurveyName] = useState('');
	const [showVisibleActive, setShowVisibleActive] = useState(false);
	const [buttonClicked, setButtonClicked] = useState(null);
	const [loading,setLoading] = useState(false);
	const [courseLoading,setCourseLoading] = useState(false);
	const [refresh,setRefresh] = useState(false);
	const [selectedRecipients,setSelectedRecipients] = useState([]);
	const [criterias,setCriterias] = useState(selectedRecord?.criterias);
	const [sendAutoOrDate,setSendAutoOrDate] = useState(false);
	const [onceAYearDate,setOnceAYearDate] = useState(null);
	const [,setTwiceAYearDate] = useState(null);
	const [filteredRecipients,setFilteredRecipients] = useState([]);
	const [displaySendAuto,setDisplaySendAuto] = useState(false);
	const [disabledCriterias,setDisabledCriterias] = useState(false);
	const [selectedCriterias,setSelectedCriterias] = useState(null);

  useEffect(()=>{
	let selectedSurvey = surveyTypes.filter((each) => (each?.id === selectedRecord?.survey_type ? each.content : ''))
	setSurveyType(selectedSurvey[0]?.content)
  if(isAddOrEdit==="edit"){
    form.setFieldsValue({
      survey_name: selectedRecord?.survey_name,
      actual_course: (surveyType === 'Courses' || surveyType === 'Instructor') ? selectedRecord?.course_id : null,
      survey_type: selectedRecord?.survey_type,
      once_a_year: selectedRecord?.start_date !== null?dayjs(selectedRecord?.start_date):'',
      twice_a_year: selectedRecord?.end_date !== null?dayjs(selectedRecord?.end_date) :'',
      student_status: selectedRecord?.is_active === "1" ? setVisibilityValue(true) : setVisibilityValue(false),
    });
		if(selectedRecord?.start_date){
			setOnceAYearDate(selectedRecord?.start_date)
		}
		if(surveyType !== 'Courses' && surveyType !== 'Instructor'&&surveyType !== 'Mentors'){
			if(selectedRecord?.is_send_automatic==="1"){
				setSendAutoOrDate(true);
			}else{
				setSendAutoOrDate(false);
			}
			if(selectedRecord?.recipients?.length>0){
				form.setFieldsValue({recipients:selectedRecord?.recipients})
				setSelectedRecipients(selectedRecord?.recipients);
			}
			updateRecipientsList(selectedSurveyTypeSendTo,constants.RecipientTypes);
			setDisplaySendAuto(true);
		}else{
			setDisplaySendAuto(false);
		}
  }else{
		setDisplaySendAuto(false);
    form.setFieldsValue({
      survey_name:null,
      actual_course: null,
    });
  }
	if (isAddOrEdit === "edit" && (surveyType === 'Courses' || surveyType === 'Instructor')){
		setShowCourses(true);
		setDisabledSurveyName(true);
		if (surveyType === 'Courses'){
			setCourseOrInstructorType('Courses');
		}else if (surveyType === 'Instructor'){
			setCourseOrInstructorType('Instructor');
		}
		setRefresh(!refresh);
	}
	},[surveyType]);
	useEffect(()=>{
		if(isAddOrEdit==="edit"){
			if(selectedRecord?.recipients.length>0)getCriteriasByRecipients(selectedRecord?.recipients);
			if(selectedRecord?.criteria?.length>0){
				setSelectedCriterias(parseInt(...selectedRecord?.criteria))
			}else{
				setSelectedCriterias(null);
			}
		}
	},[])
useEffect(()=>{
  if (isAddOrEdit === "edit" && (surveyType === 'Courses' || surveyType === 'Instructor')){
    getCourses(surveyType === 'Courses' ? 'courseType' : surveyType === 'Instructor' ?'instructorType':'');
  }
}, [surveyType,isAddOrEdit])
	const getCourseName =(value,option)=>{
		setSurveyName(option?.children.split('-')[0]);
		setCourseName(option?.children.split('-')[0]);
		form.setFieldsValue({
			survey_name: `${option?.children.split('-')[0]} ${courseOrInstructorType === 'Courses' ? 'Learning Outcomes' : courseOrInstructorType === 'Instructor'?'Instructor':''}`,
		});
		setRefresh(!refresh);
	}
  const getCourses = (type, isEdit)=>{
		setCourseLoading(true);
		let payload = null;
		payload = {
			type: type === 'courseType' ?  2 : 3,
			value: type === 'courseType' ?  0 : 3,
			discontinue: 0,
			is_hidden: isHiddenSurvey === true ? 1 : 0 ? 1 : 0,
			is_filter:1
		}
		fetchStaffApi(`/get-filtered-courses`, 'POST', payload).then((res) => {
			if(res?.data){
				setCourseLoading(false);
				setAllCourses(res?.data);
				const courseExists = res?.data.some(course => course.id === selectedRecord?.course_id);
				if(!courseExists){
					form.setFieldsValue({actual_course: (surveyType === 'Courses' || surveyType === 'Instructor') ? selectedRecord?.course_name : null})
				}
				if(isEdit==="edit"){
					form.setFieldsValue({actual_course: null})
				}
			}else{
				setCourseLoading(false);
				setAllCourses([]);
			}
			setCourseLoading(false);
		}).catch(() => {
			setCourseLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
  const getCourseType = (value,option) => {
		form.setFieldsValue({
			actual_course: null,
			survey_name:null,
			recipients:[],
		});
		setSelectedRecipients([]);
		setSelectedCriterias(null);
		setCriterias([]);

		option?.children === 'Courses' || option?.children === 'Instructor' ? setShowCourses(true) : setShowCourses(false);
		option?.children === 'Courses' || option?.children === 'Instructor' ? setDisabledSurveyName(true) : setDisabledSurveyName(false);
		if(option?.children === 'Courses'){
			setCourseOrInstructorType('Courses');
			setFilteredRecipients([]);
			setDisplaySendAuto(false)
		} else if(option?.children === 'Instructor'){
			setCourseOrInstructorType('Instructor');
			setFilteredRecipients([]);
			setDisplaySendAuto(false)
		}else{
			if(option?.children !== 'Mentors'){
				setDisplaySendAuto(true)
			}else{
				setDisplaySendAuto(false)
			};
			updateRecipientsList(option?.option?.survey_send_to,constants.RecipientTypes);
			setCourseOrInstructorType('Others');
			form.setFieldsValue({
				survey_name: null,
			});
		}
		setRefresh(!refresh);		
		return option?.children === 'Courses' ? getCourses('courseType',"edit") : option?.children === 'Instructor' ? getCourses('instructorType',"edit") : ''
	}
	const updateRecipientsList = (surveySendTo,recipientTypes) =>{
		if(surveySendTo?.length>0){
			let filteredRecipientTypes = [];
			if (surveySendTo?.includes("0") && surveySendTo?.includes("1")) {
				filteredRecipientTypes = recipientTypes; // Show all if both 0 and 1 are present
			} else if (surveySendTo?.length === 1 && surveySendTo[0] === "0") {
				filteredRecipientTypes = recipientTypes.filter(type => parseInt(type.id) >= 1 && parseInt(type.id) <= 6);
			} else if (surveySendTo?.length === 1 && surveySendTo[0] === "1") {
				filteredRecipientTypes = recipientTypes.filter(type => parseInt(type.id) >= 7 && parseInt(type.id) <= 11);
			} else {
				filteredRecipientTypes = []; // Default case to show all if no specific filter is applied
			}
			setFilteredRecipients(filteredRecipientTypes);
		}else{
			setFilteredRecipients([]);
		}
	}
  const getVisibilityValue = (checked) => {
		if(parseInt(selectedRecord?.is_active) === 0 && //IF SURVEY IS INACTIVE
			checked && //IF TRYING TO MAKE SURVEY ACTIVE
			parseInt(selectedRecord?.delete_check) === 1 &&  //IF SURVEY HAS NO QUESTIONS
			isAddOrEdit === "edit" //IF IT IS EDIT POPUP
		) {
			message.error(constants.NotAllowSurveyActive)
			return
		}
		setVisibilityValue(checked);
		if(!checked){
			setSendAutoOrDate(false)
			setOnceAYearDate(null);
			setTwiceAYearDate(null);
			form.setFieldsValue({
				once_a_year:null,
				twice_a_year:null
			})
		}
	}
  const validateToDate = (_, value) => {
		const startDate = form.getFieldValue('once_a_year');
		if (startDate && value && dayjs(value).isSame(startDate)) {
			const errorPromise = Promise.reject(new Error("Date cannot be the same as Once a year."));
			return errorPromise;
		}
		Promise.resolve().then(() => {

		});
		return Promise.resolve();
	};
  const submitSurveyDetails = (values)=>{
		setLoading(true);
		let payload = null;
		payload = {
			survey_name: courseName?`${surveyName}${courseOrInstructorType === 'Courses' ? 'Learning Outcomes' : courseOrInstructorType === 'Instructor' ? 'Instructor' : ''}` :removeExtraSpaces(values?.survey_name),
			survey_type: values?.survey_type,
			is_active: isAddOrEdit === "add"?0:visibilityValue?1:0,
			is_send_automatic:sendAutoOrDate?1:0,
			admin_id: getCookie(COOKIE.UserId),
			recipients:selectedRecipients,
		 }
		 if(values?.once_a_year){
			payload.start_date=values?.once_a_year.format('YYYY-MM-DD')
		 }
		 if(values?.twice_a_year){
			payload.end_date=values?.twice_a_year.format('YYYY-MM-DD')
		 }
		 if(selectedCriterias){
			payload.criteria = [selectedCriterias];
		 }
     if(values?.actual_course){
			let convertToInt = parseInt(values?.actual_course);
			if(isNaN(convertToInt)){
				payload.course_id=selectedRecord?.course_id;
			}else{
				payload.course_id=values?.actual_course;
			}
     }
     let url = isAddOrEdit === "add"? 'add-survey' : `edit-survey/${selectedRecord?.id}`;
     let method = isAddOrEdit === "add" ?'POST':'PUT';
		fetchStaffApi(`/${url}`, `${method}`, payload).then((res) => {
			if(res?.code === '200'){
				closeModal(isAddOrEdit === "add" ? setShowAddModal : setShowEditModal);
        clearKeys("success");
				if(isAddOrEdit==="add"){
					setNewAddedRecord(res.data);
				}
				if (buttonClicked === 'save2') {
					setContinueSave(2);
				}
				setLoading(false);
				message.success(res?.message);
			}else if (res?.code === 400 && res?.message === 'Duplicate survey name'){
				form.setFields([
					{
						name: "survey_name",
						errors: [res?.message || "An error occurred"],
					},
				]);
				setLoading(false);
			}
			setLoading(false);
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	const handleClick = (buttonType) => {
    setButtonClicked(buttonType);
  };
	const handleDateChange = (date,type) => {
    if (date) {
      // Extract only day and month, ignoring the year
      const formattedDate = dayjs(date).format(constants.DateFormat);
			type==="once"?setOnceAYearDate(formattedDate):setTwiceAYearDate(formattedDate);
    }else{
			type==="once"?setOnceAYearDate(null):setTwiceAYearDate(null);
		}
  };
	const disabledDate =(current)=>{
		return current && current.year() !== dayjs().year();
	}
	const handleChangeRecipients = (e)=>{
		setSelectedRecipients(e);
		setSelectedCriterias(null);
		setCriterias([]);
		if(e?.length>0){getCriteriasByRecipients(e);}
	}
	const handleSelectDeselectRecipients = (type)=>{
		if(type==="select"){
			let recipientsId = filteredRecipients?.map((item)=>(item?.id));
			setSelectedRecipients(recipientsId);
			form.setFieldsValue({recipients:recipientsId})
			setSelectedCriterias(null);
			setCriterias([]);
			getCriteriasByRecipients(recipientsId);
		}else{
			setSelectedRecipients([]);
			form.setFieldsValue({recipients:[]})
			setSelectedCriterias(null);
			setCriterias([]);
		}
	}
	const getCriteriasByRecipients = (ids) =>{
		let payload = {id:ids};
		setDisabledCriterias(true);
		fetchStaffApi(`/get-criteria-by-recipient`, 'POST', payload).then((res) => {
			if(res?.data?.length>0){
				setCriterias(res?.data);
			}else{
				setCriterias([]);
			}
			setDisabledCriterias(false);
		}).catch(() => {
			setCriterias([]);
			message.error(constants.ErrorMessage);
			setDisabledCriterias(false);
		});
	}
	const handleChangeCriterias = (e) =>{
		if(e){setSelectedCriterias(e)}
		else{setSelectedCriterias(null)}
	}
	const handleAutomaticSurveyChange = (e)=>{
		setSendAutoOrDate(e.target.checked)
		if(!e.target.checked){
			setOnceAYearDate(null);
			setTwiceAYearDate(null);
			form.setFieldsValue({
				once_a_year:null,
				twice_a_year:null
			})
		}
	}
	return (
		<Modal
		  open={isAddOrEdit==="add"?showAddModal:showEditModal}
		  onCancel={() => { closeModal(isAddOrEdit === "add" ? setShowAddModal : setShowEditModal) }}
		  title={isAddOrEdit==="add"?constants.AddSurvey:`${constants.EditSurvey} - ${selectedRecord?.survey_name}`}
		  className="!top-5"
		  footer={null}
		  width={700}
		>
      <Form 
		    form={form}
				id="myForm"
		    layout={'vertical'} 
		    onFinish={(values)=>submitSurveyDetails(values)}
		  >
			  <Row gutter={[24]}>
				  <Col span={12}>
					  <Form.Item
						  rules={constants.requiredFieldRules}
						  label='Survey Type'
						  name='survey_type'
						  className='!mb-2.5'
					  >
						  <Select
							  placeholder='Survey Type'
							  maxTagCount='responsive'
							  className='w-100'
							  onChange={getCourseType}
							  allowClear
						  >
							  {surveyTypes.map((option, index) => (
							  	<Select.Option key={index} value={option.id} option={option}>
							  		{option.content}
							  	</Select.Option>
							  ))}
						  </Select>
					  </Form.Item>
				  </Col>
				  <Col span={12}>
				  	<Form.Item
				  		rules={[{
								required: true, 
								message: constants.RequiredField,
								whitespace: true,
							}]}
				  		label='Survey Name'
				  		name='survey_name'
				  		className='!mb-2.5'
				  	>
				  		<Input
								className='w-100' 
								disabled={disabledSurveyName} 
								placeholder='Survey Name' 
								onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('survey_name'))}
							/>
				  	</Form.Item>
				  </Col>
			  </Row>
			  {
			  	showCourses &&
			  	<Row>
			  		<Col span={24}>
			  			<Form.Item
			  				rules={constants.requiredFieldRules}
			  				label='Course'
			  				name='actual_course'
			  				className='!mb-2.5'
			  			>
			  				<Select showSearch={true} optionFilterProp="children" placeholder='Course' maxTagCount='responsive' onChange={getCourseName} disabled={courseLoading}>
			  					{allCourses.map((option, index) => (
			  						<Select.Option key={option?.id} value={option?.id}>
			  							{`${option?.course_code} - ${option?.course_name}`}
			  						</Select.Option>
			  					))}
			  				</Select>
			  			</Form.Item>
			  		</Col>
			  	</Row>
			  }
				{displaySendAuto?
					<>
			  	<Row gutter={[24]}>
			  		<Col span={12}>
			  			<Form.Item
			  				label='Recipients'
			  				className='!mb-2.5'
								name='recipients'
								rules={constants.requiredFieldRules}
			  			>
								<Select
									optionFilterProp="children" // Filters options based on label search
									mode="multiple" 
									showSearch
									placeholder='Recipients' 
									value={selectedRecipients} 
									onChange={handleChangeRecipients}
									dropdownRender={(menu) => (
										<div className='w-full'>
											<Button
												type="text"
												className='w-50 border border-1 !important'
												onClick={()=>{handleSelectDeselectRecipients("select")}}
											>
												Select All
											</Button>
											<Button
												type="text"
												className='w-50 border border-1 !important'
												onClick={()=>{handleSelectDeselectRecipients("deselect")}}
											>
												Deselect All
											</Button>
											{menu}
										</div>
									)}
								>
									{filteredRecipients?.map((item)=>(
										<Select.Option key={item?.id} value={item?.id}>{item?.label}</Select.Option>
									))}
								</Select>
							</Form.Item>
			  		</Col>
			  		<Col span={12}>
			  			<Form.Item
			  				label='Criteria'
			  				className='!mb-2.5'
			  			>
								<Select allowClear placeholder='Criteria' value={selectedCriterias} onChange={handleChangeCriterias} disabled={disabledCriterias}>
									{criterias?.map((item)=>(
										<Select.Option key={item?.id} value={item?.id}>{item?.label}</Select.Option>
									))}
								</Select>
			  			</Form.Item>
			  		</Col>
			  	</Row>
					<Row gutter={[24]}>
						<Col span={12}>
							<Form.Item
			  				label=''
			  				name='sendauto'
			  				className='!mb-2.5 !mt-3'
			  			>
								<Checkbox onChange={handleAutomaticSurveyChange} defaultChecked={sendAutoOrDate} checked={sendAutoOrDate}>{constants.SendAutoText}</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					{sendAutoOrDate?
						<Row gutter={[24]}>
							<Col span={12}>
								<Form.Item
									label='Once a year'
									name='once_a_year'
									className='!mb-2.5'
									rules={[{
										required: sendAutoOrDate, 
										message: constants.RequiredField,
									}]}
								>
									<DatePicker
										picker="date" 
										format="MM/DD"
										className='w-100' 
										onChange={(e)=>{handleDateChange(e,"once")}} 
										disabledDate={disabledDate} />
								</Form.Item>
							</Col>
							{onceAYearDate&&
								<Col span={12}>
									<Form.Item
										label='Twice a year'
										name='twice_a_year'
										className='!mb-2.5'
										rules={[
											{ validator: validateToDate },
										]}
									>
										<DatePicker 
											picker="date" 
											format="MM/DD" 
											className='w-100'  
											onChange={(e)=>{handleDateChange(e,"twice")}} 
											disabledDate={disabledDate} />
									</Form.Item>
								</Col>}
							</Row>
					:""}
					</>
				:""}
        <Form.Item
        	label='Status'
          name='student_status'
          className='!mb-2.5'
        >
          <Switch 
						checkedChildren={constants.Active} 
						unCheckedChildren={constants.InActive} 
						style={{ width: '120px' }} 
						onChange={getVisibilityValue} 
						checked={visibilityValue} 
						className=''
					/>
        </Form.Item>
			  <div className="flex justify-end mt-2">
			  	<Button type="primary" className='mb-3' onClick={() => { closeModal(isAddOrEdit === "add" ? setShowAddModal : setShowEditModal); clearKeys()}} danger>{constants.Cancel}</Button>
			  	{isAddOrEdit==="edit"?
						selectedRecord?.is_active === "1"&&!visibilityValue?<Button onClick={()=>setShowVisibleActive(true)} type="primary" className='ms-2 mb-3'>{constants.Save}</Button>:
						<Button htmlType='submit' disabled={loading} onClick={() => handleClick('save1')} type="primary" className='ms-2 mb-3'>{constants.Save}</Button>
						:<Button htmlType='submit' disabled={loading} onClick={() => handleClick('save1')} type="primary" className='ms-2 mb-3'>{constants.Save}</Button>
					}
			  	{isAddOrEdit==="add"&&<Button disabled={loading} htmlType='submit' className='ms-2 mb-3 bgGreen500' onClick={() => handleClick('save2')}>{constants.SaveAndContinue}</Button>}
			  </div>
				{showVisibleActive&&
					<Modal 
						title={constants.Confirm}
						open={showVisibleActive}
						onCancel={()=>setShowVisibleActive(false)}
						footer={false}
					>
						<>
							<div className='text-sm mb-2 font-bold'>{constants.ActiveSurveyText}</div>
							<div className="mt-3 flex justify-end">
								<Button type='primary' danger onClick={()=>setShowVisibleActive(false)}>Cancel</Button>
								<Button type='primary' disabled={loading} onClick={() => handleClick('save1')} htmlType='submit' form='myForm' className='ms-2'>Save</Button>
							</div>
						</>
					</Modal>}
      </Form>
		</Modal>
	)
}
export default AddAndEditSurveys
