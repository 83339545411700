import React, { useContext, useState } from 'react';
import {
	DashboardOutlined, CloseCircleOutlined, FileTextOutlined, BellFilled, ProfileOutlined, MenuOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { Layout, Menu, Badge, Space, Drawer, Button } from 'antd';
import { UserContext } from '../../../Context';
import { Link } from "react-router-dom";
import LogoLg from '../../../Assets/header-logo4.png';
import LogoSm from '../../../Assets/header-logo6.png';
import constants from '../../../Constants/constants';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidemenu = ({ count }) => {
	const collapsed = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	return (
		<>
			{/* Button for Mobile Menu */}
			<Button
				className="md:hidden absolute top-2 left-3.5 border border-solid border-white text-white"
				type="text"
				icon={<MenuOutlined />}
				onClick={showDrawer}
			>
				Menu
			</Button>
			<Drawer
				title={<img src={LogoLg} width={'80%'} alt='logo' />}
				placement="left"
				width={320}
				onClose={onClose}
				open={open}
				closeIcon={false}
				extra={<Button type='text' icon={<CloseCircleOutlined className="text-red-300 !text-2xl" onClick={onClose} />} />}
				className="md:hidden mobile-menu"
			>
				<Menu
					theme="dark"
					mode="inline"
					defaultSelectedKeys={['1']}
				>
					<Menu.Item key="1" icon={<DashboardOutlined className='!block' />}>
						<Link to="/student-dashboard">
							{constants.Dashboard}
						</Link>
					</Menu.Item>
					<SubMenu icon={<FontAwesomeIcon icon={faCircleUser} />}
						key="sub1"
						title="Advising"
					>
						<Menu.Item key="4"><Link to="/student-advisor-signup">{constants.AdvisorSignup}</Link></Menu.Item>
						<Menu.Item key="7"><Link to="/student-checklist">{constants.Checklist}</Link></Menu.Item>
						<Menu.Item key="9"><Link to="/plan-of-study">{constants.PlanOfStudyAdvisement}</Link></Menu.Item>
						<Menu.Item key="5"><Link to="/student-courses">{constants.Courses}</Link></Menu.Item>
						<Menu.Item key="6"><Link to="/semester-courses">{constants.SemesterCourses}</Link></Menu.Item>
					</SubMenu>
					<Menu.Item key="8" icon={<FileTextOutlined />}>
						<Link to="/student-notes">
							Notes
						</Link>
					</Menu.Item>
					<Menu.Item key="all-surveys" icon={<ProfileOutlined />}>
						<Link to="/all-surveys">
							{constants.Surveys}
						</Link>
					</Menu.Item>
					<Menu.Item key="22" icon={<BellFilled />}> <Space>
						<Link to="/student-notifications" state={{ user: "student" }}>
							Notifications
						</Link>
						{count > 0 && <Badge className="badge-style" count={count} size="small" showZero={true} />}
					</Space>

					</Menu.Item>

				</Menu>
			</Drawer>
			<Sider trigger={null} collapsible collapsed={collapsed.collapsed} width={230} className="hidden md:block">
				<div className={` ${collapsed.collapsed ? '' : 'fixed w-[230px]'}`}>
					<div className='logo-area flex items-center justify-center'>
						<img src={collapsed.collapsed ? LogoSm : LogoLg} width={collapsed.collapsed ? '60%' : '80%'} alt='logo' />
					</div>
					<div className='overflow-y-auto h-[calc(100vh-50px)]'>
						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={['1']}
						>
							<Menu.Item key="1" icon={<DashboardOutlined className='!block' />}>
								<Link to="/student-dashboard">
									{constants.Dashboard}
								</Link>
							</Menu.Item>
							<SubMenu icon={<FontAwesomeIcon icon={faCircleUser} />}
								key="sub1"
								title="Advising"
							>
								<Menu.Item key="4"><Link to="/student-advisor-signup">{constants.AdvisorSignup}</Link></Menu.Item>
								<Menu.Item key="7"><Link to="/student-checklist">{constants.Checklist}</Link></Menu.Item>
								<Menu.Item key="9"><Link to="/plan-of-study">{constants.PlanOfStudyAdvisement}</Link></Menu.Item>
								<Menu.Item key="5"><Link to="/student-courses">{constants.Courses}</Link></Menu.Item>
								<Menu.Item key="6"><Link to="/semester-courses">{constants.SemesterCourses}</Link></Menu.Item>
							</SubMenu>
							<Menu.Item key="8" icon={<FileTextOutlined />}>
								<Link to="/student-notes">
									Notes
								</Link>
							</Menu.Item>
							<Menu.Item key="all-surveys" icon={<ProfileOutlined />}>
								<Link to="/all-surveys">
									{constants.Surveys}
								</Link>
							</Menu.Item>
							<Menu.Item key="22" icon={<BellFilled />}> <Space>
								<Link to="/student-notifications" state={{ user: "student" }}>
									Notifications
								</Link>
								{count > 0 && <Badge className="badge-style" count={count} size="small" showZero={true} />}
							</Space>
							</Menu.Item>
						</Menu>
					</div>
				</div>
			</Sider>
		</>
	);
};

export default Sidemenu;
