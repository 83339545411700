import React, { useEffect, useRef, useState } from "react";
import constants from "../../Constants/constants";
import { Button, Checkbox, Col, Flex, Form, Input, Modal, Row, Space, Table, message, Spin } from "antd";
import { fetchStaffApi } from "../../Services/Api";
import { CustomSortIcon, HighlightedText, HighlightedHTML, getNextAndPreviousButtons, performSearch, removeExtraSpaces, showTotalItems, checkEmptySpace, handleValidateTextEditor, sortTwoNumbers, handleNotificationViewed } from "../../GlobalFunctions/GlobalFunctions";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { Editor } from "@tinymce/tinymce-react";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { useForm } from "antd/es/form/Form";
import AdvisementDoc from "./AdvisementDoc";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

const StudentRelatedNotes = (props) => {
	let { student_id, student_name } = props
	const [form] = useForm();
	const editorRef = useRef(null);
	const confidentialEditorRef = useRef(null);
	const [, updateNotificationData] = useOutletContext();
	const [listData, setListData] = useState([]);
	const [data, setData] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [isConfidential, setIsConfidential] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [rowData, setRowData] = useState(null);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [facultyDetails, setFacultyDetails] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [popupLoading, setPopupLoading] = useState(false);
	const loginId = getCookie(COOKIE.UserId);
	const role = getCookie(COOKIE.Role);
	const navigate = useNavigate()
	const location = useLocation()
	let { sem_id, year_in_program } = location?.state

	useEffect(() => {
		studentList();
	}, [student_id]);

	const studentList = () => {
		setLoading(true);
		let payload = { studentid: student_id };
		fetchStaffApi(`/get-student-note`, "POST", payload).then((res) => {
			if (res?.code === "200") {
				const modifiedResponse = res?.data?.student_notes?.map((item) => ({
					...item,
					signText:item?.student_sign?"Yes":"No",
					dateUpdated:item?.dt_created?dayjs(item?.dt_created).format(constants.DateFormat):""
				}));
				const facultyDetails = res?.data?.faculty_details || [];
	
				setListData(modifiedResponse);
				setFilteredData(modifiedResponse);
				setFacultyDetails(facultyDetails?.length > 0 ? facultyDetails[0] : null);
			} else {
				setListData([]);
				setFilteredData([]);
				setFacultyDetails(null);
			}
			setLoading(false);
		});
	};

	const getStudentData = (action) => {
		fetchStaffApi(`/get-student-note-id/${rowData?.id}`, "GET").then(
			(res) => {
				if (res?.code === "200") {
					if(!res?.data?.student_notes) {
						message.error(constants.ErrorMessage);
						return
					} 
					const result = res?.data?.student_notes?.[0];
					setData(result);
					form.setFieldsValue({
						advisor: result?.faculty,
						student: result?.student,
						note: result?.note?.replace(/<[^>]+>|&nbsp;/g, ""),
						confidentiality_note: result?.confidentiality_note?.replace(/<[^>]+>|&nbsp;/g, "")
					});
					setIsConfidential(!!result?.confidentiality_note);
					modalActions(action, true)
					//CALL SET VIEW NOTIFICATIONS API AND THEN CLEAR NOTIFICATION			
					let notificationObj = res?.data?.notification
					if(notificationObj && !notificationObj?.dt_viewed) {
						handleNotificationViewed(notificationObj?.notification_id, updateNotificationData)
						return
					}
				} else {
					setData(null);
					setIsConfidential(false);
				}
			}
		).catch((err) => {
			console.log(err);
		});
	};

	const modalActions = (action, flag) => {
		if(action === 'view') setIsModalOpen(flag);
		else if(action === 'edit') setIsEditModalOpen(flag);
	}

	const columns = [
		{
			title: `${constants.DateUpdated}`,
			dataIndex: "dt_created",
			key: "dt_created",
			sorter: (a, b) => sortTwoNumbers(a,b,"dt_created",true),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => (
				<HighlightedText text={record?.dateUpdated} highlight={searchValue}/>
			),
			showSorterTooltip: { show: true, placement: "bottom" },
		},
		{
			title: `${constants.Notes}`,
			dataIndex: "note",
			key: "note",
			render: (note, record) => {
				return record?.confidentiality_note ? (
					<>
						<Row gutter={[0, 0]}>
							<Col span={24}>
								<HighlightedHTML 
									html={removeExtraSpaces(note)}
									highlight={searchValue}
								/>
							</Col>
							<Col span={24} className="font-semibold italic">
								<HighlightedText
									text={constants.ConfidentialNoteAdded}
									highlight={searchValue}
								/>
							</Col>
						</Row>
					</>
				) : (
					<HighlightedHTML html={removeExtraSpaces(note)} highlight={searchValue} />
				);
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: "bottom" },
		},
		{
			title: `${constants.AdvisorESign}`,
			dataIndex: "faculty",
			key: "faculty",
			sorter: {
				compare: (a, b) => a?.faculty.localeCompare(b?.faculty),
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (faculty) => (
				<HighlightedText text={faculty} highlight={searchValue} />
			),
			showSorterTooltip: { show: true, placement: "bottom" },
		},
		{
			title: `${constants.StudentEsign}`,
			key: "student_sign",
			render: (_,record) =>(
				<HighlightedText text={record?.signText} highlight={searchValue} />
			),
			sorter: (a, b) => a?.signText === b?.signText ? 0 : a?.signText ? -1 : 1,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: "bottom" },
		},
	];

	const handleModalActions = (type, flag) => {
		if (type === "edit") {
			setIsEditModalOpen(flag);
			setIsConfidential(flag);
			if(flag === false) form.resetFields();
		} else if (type === "delete") {
			setIsDeleteModalOpen(flag);
		} else {
			setIsModalOpen(flag);
		}
	};

	const handleCheckbox = (e) => {
		setIsConfidential(e.target.checked);
		if(!e.target.checked) form.setFieldsValue({ 
			confidentiality_note: '',
			editor_text_confi: '',
		})
	};

	const handleEditorChange = (content, editField, hiddenField) => {
		form.setFieldsValue({ 
			[editField]: content || '',
			[hiddenField]: content || '',
		})
	};

	const rowSelection = {
		type: "checkbox",
		hideSelectAll: true,
		onChange: (selectedRowKeys, selectedRows) => {
			if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = selectedRows.slice(-1);
			}
			setSelectedRowKeys(selectedRowKeys);
			setRowData(selectedRows[0] || null);
		},
		selectedRowKeys: selectedRowKeys
	};

	const onRowClick = (record) => {
		const key = record.id;
		let newSelectedRowKeys = [...selectedRowKeys];

		if (newSelectedRowKeys.includes(key)) {
			newSelectedRowKeys = [];
		} else {
			newSelectedRowKeys = [key];
		}

		setSelectedRowKeys(newSelectedRowKeys);
		setRowData(newSelectedRowKeys.length > 0 ? record : null);
	};

	const onSearch = (event) => {
		const search = event.target.value;
		const fieldNames = [
			"dateUpdated",
			"note_plain_text",
			"faculty",
			"signText",
			"student",
		];
		const searchedValue = performSearch(search, filteredData, fieldNames);
		setListData(searchedValue);
		setSearchValue(search);
	};

	const handleSaveRecord = (value) => {
		setPopupLoading(true)
		const sign = value?.sign

		let note = ''
		if(isConfidential) {
			note = form.getFieldValue('note')?.level?.content || value?.note
		}

		const noteContent = note || value?.editor_text_note
		const confidentialityNoteContent = isConfidential ? value?.editor_text_confi : ''
		const payload = {
			student_id: data?.student_id,
			faculty_id: loginId,
			note: noteContent ? removeExtraSpaces(noteContent) : '',
			faculty_sign: sign,
			is_editable: 1,
			advisor_id: data?.id,
		};
		if (confidentialityNoteContent)
			payload.confidentiality_note = removeExtraSpaces(confidentialityNoteContent);

		fetchStaffApi(`/edit-student-note/${rowData?.id}`, "PUT", payload).then((res) => {
			if(res?.code === "200") {
				message.success(constants.SaveMessage);
				studentList();
			} else {
				message.error(res?.message);
			}
			setPopupLoading(false);
			handleModalActions('edit', false)
		}).catch(() => {
			setPopupLoading(false);
			message.error(constants.ErrorMessage);
		});
	};

	const showAddModal = () => {
		setIsAddModalOpen(true);
	};

	const handleAddCancel = () => {
		setIsAddModalOpen(false);
	};

	const showDeleteModal = () => {
		if(role === "2" && (rowData?.faculty_id !== loginId )){
			message.error(constants.PermissionForDelete)
		}else{
			setIsDeleteModalOpen(true);
		}
	};

	const handleDelete = () => {
		setPopupLoading(true)
		let payload = {
			faculty_id: loginId,
			note_id: rowData?.id
		}
		fetchStaffApi(`/delete-advisory-note`, "POST", payload)
			.then((res) => {
				if (res.code === "200") {
					message.success(res?.message);
					setIsDeleteModalOpen(false);
					studentList();
					setRowData(null);
				} else {
					message.error(res?.message);
					setIsDeleteModalOpen(true);
				}
				setPopupLoading(false)
			}).catch((err) => {
				setPopupLoading(false)
			})
	};

	const redirectToConfidentialNotes = () => {
		navigate(
			'/confidential-notes',
			{ 
				state: { 
					student_id: student_id,
					from: constants.AdvisingNotes,
					from_route: "/student-information",
					tabkey: "advising-notes",
					tab_title: constants.AdvisingNotes,
					student_name: student_name,
					sem_id: sem_id,
					year_in_program: year_in_program,
				}
			}
		)
	}

	return (
		<Spin tip={constants.PleaseWait} spinning={loading}>
			<div>
				<div className="pt-2">
					<Row>
						<Col span={12}>
							<Space wrap>
								<Button className="text-capitalize btn-info"
									onClick={() => {
										if (facultyDetails?.id === null || facultyDetails?.id === undefined) {
											message.error(constants.FacultyNotAssigned, [7]);
											return
										}
										showAddModal();
									}}
									disabled={rowData !== null}
									>{constants.Add}</Button>
								<Button
									className=" text-capitalize btn-info"
									onClick={() => {
										if (rowData?.student_sign !== null) {
											message.error(constants.EditNotAllowed, [7]);
											return
										}
										getStudentData('edit');
									}}
									disabled={rowData === null}
								>
									{constants.Edit}
								</Button>
								<Button
									className="text-capitalize btn-info"
									onClick={() => getStudentData('view')}
									disabled={rowData === null}
								>
									{constants.View}
								</Button>
								<Button
									className="text-capitalize btn-info"
									onClick={showDeleteModal}
									disabled={rowData === null}
								>
									{constants.Delete}
								</Button>
								<Button
									className="text-capitalize btn-info"
									onClick={redirectToConfidentialNotes}
								>
									{constants.ConfidentialNotes}
								</Button>
							</Space>
						</Col>
						<Col span={12} className="grid justify-items-end">
							<Input
								className="w-48"
								placeholder={constants.Search}
								onChange={onSearch}
								value={searchValue}
							/>
						</Col>
					</Row>
				</div>
				<div className="pt-2">
					<Table
						dataSource={listData}
						columns={columns}
						className="records-table overflow-auto data_table"
						rowSelectedBg="#e6f4ff"
						bordered
						size="small"
						rowKey={(record) => record.id}
						rowSelection={{
							...rowSelection,
						}}
						onRow={(record) => ({
							onClick: () => onRowClick(record),
						})}
						scroll={{ x: 768, y: "calc(100vh - 350px)" }}
						pagination={{
							pageSize: constants.TablePageSize,
							showTotal: (total, range) =>
								showTotalItems(total, range, listData),
							itemRender: getNextAndPreviousButtons,
						}}
						searchableProps={{ fuzzySearch: true }}
					/>
				</div>
				<div>
					<Modal
						title={constants.ViewNotes}
						className="records-popup"
						footer={null}
						open={isModalOpen}
						onCancel={() => handleModalActions("view", false)}
					>
						<div className="adjust-padding">
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<div className="student-notes-css">{constants.Advisor}</div>
									<Input value={data?.faculty || ""} readOnly />
								</Col>
								<Col span={12}>
									<div className="student-notes-css">{constants.Student}</div>
									<Input value={data?.student || ""} readOnly />
								</Col>
							</Row>
							{data?.confidentiality_note ? (
								<>
									<div className="student-notes-css">
										{constants.Note}
									</div>
									<div className="border border-slate-500 rounded-lg p-2">
										<span dangerouslySetInnerHTML={{ __html: data?.note }} />
									</div>
									<div className="student-notes-css">
										{constants.ConfidentialNote}
									</div>
									<div className="border border-slate-500 rounded-lg p-2">
										<span dangerouslySetInnerHTML={{ __html: data?.confidentiality_note }} />
									</div>
								</>
							) : (
								<Row gutter={[8, 8]}>
									<Col span={24}>
										<div className="student-notes-css">{constants.Note}</div>
										<div className="border border-slate-500 rounded-lg p-2"><span dangerouslySetInnerHTML={{ __html: data?.note }} /></div>
									</Col>
								</Row>
							)}
							<Row gutter={[8, 8]}>
								{data?.student_sign ? (
									<>
										<Col span={12}>
											<div className="student-notes-css">
												{constants.AdvisorESign}
											</div>
											<div>
													<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
														{data?.faculty_sign || ""}
													</div>
												<div>
													{constants.SignedOn}{" "}
													{dayjs(data?.faculty_sign_dt).format(
														constants.DateFormat
													) || ""}
												</div>
											</div>
										</Col>
										<Col span={12}>
											<div className="student-notes-css">
												{constants.StudentEsign}
											</div>
											<div>
													<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
														{data?.student_sign || ""}
													</div>
												<div>
													{constants.SignedOn}{" "}
													{dayjs(data?.student_sign_dt).format(
														constants.DateFormat
													) || ""}
												</div>
											</div>
										</Col>
									</>
								) : (
									<Col span={12}>
										<div className="student-notes-css">
											{constants.AdvisorESign}
										</div>
										<div>
												<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
													{data?.faculty_sign || ""}
												</div>
											<div>
												{constants.SignedOn}{" "}
												{dayjs(data?.faculty_sign_dt).format(
													constants.DateFormat
												) || ""}
											</div>
										</div>
									</Col>
								)}
							</Row>
						</div>
					</Modal>
					<Modal
						title={constants.EditAdvisingNotes}
						footer={null}
						open={isEditModalOpen}
						onCancel={() => handleModalActions("edit", false)}
						width={600}
						className='!top-5'
						destroyOnClose={true}
					>
						<div className="form-padding">
							<Form
								layout="vertical"
								onFinish={handleSaveRecord}
								form={form}
							>
								<Row gutter={24}>
									<Col span={12}>
										<Form.Item name="advisor" label={`${constants.Advisor}/${constants.Admin}`}>
											<Input readOnly value={data?.faculty} />
										</Form.Item>
									</Col>

									<Col span={12}>
										<Form.Item name="student" label={constants.Student}>
											<Input readOnly value={data?.student} />
										</Form.Item>
									</Col>

									<Col span={24}>
										<Form.Item
											name="note"
											label={constants.Note}
											rules={[
												{
													required: isConfidential === false,
													message: ' ',
												},
												{
													validator: (rule, value, validator) => {
														// Only trigger validation if the condition is met
														if (isConfidential === false) {
															return handleValidateTextEditor(rule, value, validator, 'editor_text_note', form);
														}
														// If the condition is not met, return a resolved Promise to bypass validation
														return Promise.resolve();
													},
												},
											]}
										>
											<Editor
												textareaName="body"
												tinymceScriptSrc={constants.TextEditorSrcPath}
												onInit={(evt, editor) => (editorRef.current = editor)}
												init={{
													menubar: false,
													branding: false,
													plugins: constants.TinyMcePlugins,
													toolbar: constants.TinyMceToolbar,
													content_style: constants.TinyMceContentStyle,
													statusbar: false,
													toolbar_mode: 'sliding',
													height: 200
												}}
												onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text_note'))}
												onEditorChange={(e) => handleEditorChange(e, 'note', 'editor_text_note')}
											/>
										</Form.Item>
										{isConfidential === false &&
										<Form.Item 
											name='editor_text_note'
											hidden>
										</Form.Item>}
									</Col>
									<Col span={24}>
										<div className="flex-container">
											<Checkbox
												checked={isConfidential}
												onChange={handleCheckbox}
											/>
											<p class="font-bold pt-3 pl-2">
												{constants.ConfidentialNotes}
											</p>
										</div>
									</Col>
									{isConfidential && (
										<Col span={24}>
											<Form.Item
												name="confidentiality_note"
												rules={[
													{
														required: isConfidential,
														message: ' ',
													},
													{ validator: (rule, value, validator) => handleValidateTextEditor(rule, value, validator, 'editor_text_confi', form) },
												]}
											>
												<Editor
													textareaName="body"
													tinymceScriptSrc={constants.TextEditorSrcPath}
													onInit={(evt, editor) =>
														(confidentialEditorRef.current = editor)
													}
													init={{
														readonly: true,
														statusbar: false,
														menubar: false,
														height: 200,
														branding: false,
														plugins: constants.TinyMcePlugins,
														toolbar: constants.TinyMceToolbar,
														content_style: constants.TinyMceContentStyle,
														toolbar_mode: 'sliding'
													}}
													onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text_confi'))}
													onEditorChange={(e) => handleEditorChange(e, 'confidentiality_note', 'editor_text_confi')}
												/>
											</Form.Item>
											<Form.Item 
												name='editor_text_confi'
												hidden>
											</Form.Item>
										</Col>
									)}
									<Col span={24}>
										<Form.Item
											name="sign"
											label={constants.AdvisorESign}
											rules={[
												{
													required: true,
													message: constants.FieldRequired,
												},
											]}
										>
											<TextArea 
												maxLength={25} 
												autoSize={{ maxRows: 1 }} 
												placeholder={constants.AdvisorESign} 
												className="w-1/2" 
											/>
										</Form.Item>
									</Col>
								</Row>
								<Flex
									gap="small"
									align="flex-end"
									className="action-button-container"
								>
									<Flex gap="small" wrap="wrap">
										<Button
											type="primary"
											className="action-btn"
											danger
											onClick={() => handleModalActions("edit", false)}
										>
											{constants.Cancel}
										</Button>
										<Button
											type="primary"
											className="action-btn"
											htmlType="submit"
											disabled={popupLoading}
										>
											{constants.Save}
										</Button>
									</Flex>
								</Flex>
							</Form>
						</div>
					</Modal>
					{
						isAddModalOpen && (
							<AdvisementDoc
								isAddModalOpen={isAddModalOpen}
								isModalOpen={isAddModalOpen}
								handleCancel={handleAddCancel}
								selectedRecord={{
									student_id: student_id,
									faculty_id: facultyDetails?.id,
									student: student_name,
									faculty: facultyDetails?.faculty_name
								}}
								studentList={studentList}
							/>
						)
					}
					{isDeleteModalOpen && (
						<Modal
							title={constants.DeleteNote}
							footer={null}
							cancelText={constants.Close}
							open={isDeleteModalOpen}
							onCancel={() => handleModalActions("delete", false)}
							className='!top-5'
						>
							<strong className="text-base">{constants.DeleteNoteConfirmationMessage}</strong>
							<Flex justify='flex-end'>
								<Space>
									<Form.Item >
										<Button
											type="primary"
											size="middle"
											className="text-capitalize"
											danger
											onClick={() => handleModalActions("delete", true)}
										>
											{constants.Close}
										</Button>
									</Form.Item >
									<Form.Item >
										<Button
											type="primary"
											size="middle"
											className="text-capitalize"
											onClick={handleDelete}
											disabled={popupLoading}
											loading={popupLoading}
										>
											{constants.Delete}
										</Button>
									</Form.Item>
								</Space>
							</Flex>
						</Modal>
					)
					}
				</div>
			</div>
		</Spin>
	);
};

export default StudentRelatedNotes;
